import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useParams, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { RootState } from 'StoreModel';
import { SidebarItem, SidebarList, SidebarContainer } from '../styled-components/sidebar';
import { Separator, PrimaryButton } from '../styled-components/common';
import { find } from 'ramda';
import { Input, Loader, Dimmer } from 'semantic-ui-react';
import { updateProjectLogoAsync, fetchProjectMembersByCircleAsync } from '../../redux/actions/project';
import Team, { LinkStyled2 } from './team';
import { Flag } from '../../redux/types/enums';
import { Member, Circle, Project, Community, EventAttendance, EventAttendee } from 'redux/types/account';
import { DEFAULT_CIRCLE } from '../../redux/types/default-types-values';
import SchedulerMultipleUserModal from '../common/scheduler-multiple-user-modal/scheduler-multiple-user-modal';
import { generateEventAttendanceFromEntityCircles } from 'util/calendar';
import { isCoverLimitExceeded, scrollLayoutToTop } from 'util/utils';
import { ReactComponent as FeedIcon } from 'assets/sidebar-icons/home.svg';
import { ReactComponent as BusinessModelIcon } from 'assets/sidebar-icons/rocket-launch.svg';
import { ReactComponent as DiscussionsIcon } from 'assets/sidebar-icons/comment-alt.svg';
import { ReactComponent as MembersIcon } from 'assets/sidebar-icons/users-alt.svg';
import { ReactComponent as ResourcesIcon } from 'assets/sidebar-icons/folders.svg';
import { ReactComponent as RequestsIcon } from 'assets/sidebar-icons/comments-question-check.svg';
import { ReactComponent as SettingsIcon } from 'assets/sidebar-icons/settings.svg';
import { ReactComponent as KPIsIcon } from 'assets/sidebar-icons/kpi.svg';
import { StyledLogo } from 'components/community/sidebar';
import { StyledButton } from 'primitives/Button/style';
import { toast } from 'components/common/toast';

const mapStateToProps = (state: RootState) => ({
  projects: state.account.projects.list,
  selectedCommunity: state.account.selectedCommunity,
  bearer: state.account.session.session.bearer,
  currentUser: state.account.details.user,
  isUpdatingLogo: state.loading.updateProjectLogoFlag,
  isFetchingProjectMembersByCircle: state.loading.fetchProjectMembersByCircleFlag,
  literals: state.literals,
});

const mapDispatchToProps = {
  uploadLogo: updateProjectLogoAsync.request,
  fetchProjectMembersByCircle: fetchProjectMembersByCircleAsync.request,
  resetFetchProjectMembersByCircleFlag: fetchProjectMembersByCircleAsync.cancel,
};

type dispatchType = typeof mapDispatchToProps;
interface Props extends ReturnType<typeof mapStateToProps>, dispatchType {
  shrinked?: boolean;
}

const ProjectSidebar: React.FC<Props> = ({
  projects,
  bearer,
  isUpdatingLogo,
  uploadLogo,
  currentUser,
  selectedCommunity,
  literals,
  shrinked,
}) => {
  const { projectId } = useParams<{ projectId: string }>();
  const ICON_PROPS = {
    width: '1.3em',
    height: '1.3em',
    fill: 'rgb(55,96,146)',
  };
  const pId = parseInt(projectId as string);
  const project = useMemo(() => find((project: Project) => project.id === pId, projects), [projects]);

  const community: Community | null = useMemo(() => selectedCommunity, [selectedCommunity]);
  const [isEventModalOpen, setIsEventModalOpen] = useState<boolean>(false);
  const [isTeamMemberSchedulingEvent, setIsTeamMemberSchedulingEvent] = useState<boolean>(false);
  const [teamMemberAttendants, setTeamMemberAttendants] = useState<EventAttendance>({});
  const teamCircle: Circle = (project && project.circles?.find((c: Circle) => c.name === 'Team')) || DEFAULT_CIRCLE;
  const projectTeamAttendees: EventAttendee[] =
    (project &&
      project.members
        ?.filter((m: Member) => m.circle.id === teamCircle?.id && m.id !== currentUser.id)
        .map(
          (m: Member): EventAttendee => ({
            id: m.id,
            name: m.name,
            email: m.email,
            photoUrl: m.photo,
          }),
        )) ||
    [];

  const guestMemberAttendance = {
    [`${project?.name || ''} team`]: {
      count: projectTeamAttendees.length,
      users: projectTeamAttendees,
    },
  };

  useEffect(() => {
    if (project && project?.circles) {
      const attendance = generateEventAttendanceFromEntityCircles(project);
      setTeamMemberAttendants(attendance);
    }
  }, [project?.circles]);

  function dataURItoFile(dataURI: string) {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = 'image/png';
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new File([new Blob([ab], { type: mimeString })], 'logo.png', { type: mimeString });
  }

  const handleLogoUpload = (e: any) => {
    if (e && project) {
      e.preventDefault();
      if (e && e.target && e.target.files.length > 0) {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);

        if (isCoverLimitExceeded(file.size)) {
          // this will help the onChange event to be triggered even if the same file is selected
          if (e.target?.value) e.target.value = null;
          toast(literals.user_settings_profile_picture_size_limit, { type: 'warning' });
          return;
        }

        reader.onload = (onLoadEvent: any) => {
          if (onLoadEvent && onLoadEvent.target && onLoadEvent.target.result) {
            const img = new Image();
            img.src = window.URL.createObjectURL(file);
            img.onload = () => {
              const canvas = document.createElement('canvas');
              const context = canvas.getContext('2d');
              const longestEdge = Math.max(img.width, img.height);
              canvas.width = longestEdge;
              canvas.height = longestEdge;
              if (context) {
                context.fillStyle = 'transparent';
                context.fillRect(0, 0, canvas.width, canvas.height);
                context.drawImage(img, (longestEdge - img.width) / 2, (longestEdge - img.height) / 2);

                const dataUrl = canvas.toDataURL('image/png');
                const newFile = dataURItoFile(dataUrl);

                uploadLogo({ project, bearer, logo: newFile });
              }
            };
          }
        };
        reader.onerror = onErrorEvent => {
          if (onErrorEvent && onErrorEvent.target && onErrorEvent.target.error) {
            if (onErrorEvent.target.error.name === 'NotReadableError') {
              alert(literals.apply_to_team_project_service_on_project_applt_to_team_error);
            }
          }
        };
      }
    }
  };

  if (!project) {
    return null;
  }

  const toggleEventModal = () => {
    setIsEventModalOpen(!isEventModalOpen);
  };

  return (
    <SidebarContainer>
      {selectedCommunity && (
        <SidebarList>
          <StyledButton
            style={{
              width: shrinked ? '1.5em' : '10em',
              fontSize: '1em',
              padding: shrinked ? '0em' : '0.4em 0em',
              marginLeft: '0',
              height: shrinked ? '2em' : 'auto',
              marginBottom: '0.5em',
            }}
            as={Link}
            to={`/community/${community?.id}/projects`}
            onClickCapture={scrollLayoutToTop}
          >
            <span style={{ fontSize: '0.7em', fontWeight: 'bold' }}>
              {`<< `}
              {(!shrinked && literals.sidebar_back_to_community) || ''}
            </span>
          </StyledButton>
        </SidebarList>
      )}
      <LogoContainer canEdit={project.canEditProjectInfo ? project.canEditProjectInfo : false}>
        {!shrinked && (
          <ChangeCover
            as="label"
            htmlFor="logo"
            title={literals.project_edit_change_cover_button}
            className="change-project-cover"
          >
            {literals.project_edit_change_logo_button}
          </ChangeCover>
        )}
        <UploadLogoInput
          disabled={!project.canEditProjectInfo || isUpdatingLogo === Flag.Request}
          type="file"
          id="logo"
          onChange={handleLogoUpload}
          name="logo"
          accept="image/*"
        />
        <Dimmer inverted active={isUpdatingLogo === Flag.Request}>
          <Loader />
        </Dimmer>
        <StyledLogo loading="eager" $shrinked={shrinked} src={project.logo} />
      </LogoContainer>
      <SidebarList>
        <Separator />
        <SidebarItem onClickCapture={scrollLayoutToTop} to={`/project/${projectId}`} hasNotifications={false}>
          <FeedIcon aria-label={literals.menu_home} id={`${literals.menu_home}_${projectId}`} {...ICON_PROPS} />
          {(!shrinked && literals.menu_home) || ''}
        </SidebarItem>
        <SidebarItem
          onClickCapture={scrollLayoutToTop}
          to={`/project/${projectId}/business-model`}
          hasNotifications={false}
        >
          <BusinessModelIcon
            aria-label={literals.project_ctrl_initializer_menu_option_business_model}
            {...ICON_PROPS}
            id={`${literals.project_business_model_ctrl_title}_${projectId}`}
          />
          {!shrinked && literals.primitive_program}
        </SidebarItem>
        {
          <SidebarItem
            onClickCapture={scrollLayoutToTop}
            to={`/project/${projectId}/requests`}
            hasNotifications={false}
          >
            <RequestsIcon
              aria-label={literals.sidebar_option_requests}
              id={`${literals.sidebar_option_requests}_${projectId}`}
              {...ICON_PROPS}
            />
            {(!shrinked && literals.sidebar_options_coaching) || ''}
          </SidebarItem>
        }
        {((project.kpiDefinitions && project.kpiDefinitions.length > 0 && project.isProjectTeamMember) ||
          project.isCommunityAdmin ||
          project.isProjectAdmin) && (
          <SidebarItem onClickCapture={scrollLayoutToTop} to={`/project/${projectId}/kpis`} hasNotifications={false}>
            <KPIsIcon aria-label={literals.menu_kpis} id={`${literals.menu_kpis}_${projectId}`} {...ICON_PROPS} />
            {(!shrinked && literals.menu_kpis) || ''}
          </SidebarItem>
        )}

        <SidebarItem
          onClickCapture={scrollLayoutToTop}
          to={`/project/${projectId}/discussions`}
          hasNotifications={false}
        >
          <DiscussionsIcon
            aria-label={literals.project_ctrl_initializer_menu_option_discussions}
            id={`${literals.project_discussion_ctrl_title}_${projectId}`}
            {...ICON_PROPS}
          />
          {(!shrinked && literals.project_ctrl_initializer_menu_option_discussions) || ''}
        </SidebarItem>
        <SidebarItem onClickCapture={scrollLayoutToTop} to={`/project/${projectId}/resources`} hasNotifications={false}>
          <ResourcesIcon
            aria-label={literals.project_ctrl_initializer_menu_option_resources}
            id={`${literals.community_menu_resources_option}_${projectId}`}
            {...ICON_PROPS}
          />
          {(!shrinked && literals.project_ctrl_initializer_menu_option_resources) || ''}
        </SidebarItem>
        <SidebarItem onClickCapture={scrollLayoutToTop} to={`/project/${projectId}/network`} hasNotifications={false}>
          <MembersIcon
            aria-label={literals.project_ctrl_initializer_menu_option_network}
            id={`${literals.community_menu_members_option}_${projectId}`}
            {...ICON_PROPS}
          />
          {(!shrinked && literals.project_ctrl_initializer_menu_option_network) || ''}
        </SidebarItem>
        <Separator />
        {shrinked && project?.canEditProjectInfo && project.isMember && (
          <SidebarItem
            onClickCapture={scrollLayoutToTop}
            to={`/project/${projectId}/settings/general`}
            hasNotifications={false}
          >
            <SettingsIcon
              aria-label={literals.project_menu_settings}
              id={`${literals.project_menu_settings}_${projectId}`}
              {...ICON_PROPS}
            />
          </SidebarItem>
        )}
        {!shrinked && <Team />}
        {!shrinked &&
          !location.pathname.includes('request') &&
          (project.canEditProjectInfo ? (
            <LinkStyled2
              onClick={() => {
                setIsTeamMemberSchedulingEvent(true);
              }}
            >
              {literals.events_schedule_a_meeting_button}
            </LinkStyled2>
          ) : (
            <LinkStyled2
              onClick={() => {
                setIsTeamMemberSchedulingEvent(true);
              }}
            >
              {literals.events_schedule_a_meeting_button}
            </LinkStyled2>
          ))}
      </SidebarList>
      <SchedulerMultipleUserModal
        showModal={isTeamMemberSchedulingEvent}
        onClose={() => setIsTeamMemberSchedulingEvent(!isTeamMemberSchedulingEvent)}
        project={project}
        bearer={bearer}
        communityId={210}
        titlePlaceholder={`${literals.schedule_meeting_modal_title} ${currentUser.name} / ${project.name}`}
      />
    </SidebarContainer>
  );
};

const ChangeCover = styled(PrimaryButton)`
  &&& {
    position: absolute;
    font-size: 0.5em;
    line-height: 1em;
    font-weight: 600;
    display: block;
    width: 12em;
    height: 2.4em;
    top: 2em;
    right: 4px;
    z-index: 10;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    margin: 0;
  }
`;

const LogoContainer = styled.div<{ canEdit: boolean }>`
  &&& {
    position: relative;
    display: flex;
    & .change-project-cover {
      display: none;
    }
    &:hover {
      .change-project-cover {
        display: ${({ canEdit }) => (canEdit ? `block` : `none`)};
        cursor: pointer;
      }
    }
    .loader {
      position: relative;
    }
    .dimmer {
      width: 10em;
      height: 10em;
    }
  }
`;

const UploadLogoInput = styled(Input)`
  &&& {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
`;

export default connect(mapStateToProps, mapDispatchToProps)(ProjectSidebar);
