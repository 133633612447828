export enum MethodologyActionTypes {
  CREATE_SECTION = 'CREATE_SECTION',
  DELETE_SECTION = 'DELETE_SECTION',
  REORDER_SECTION = 'REORDER_SECTION',
  EDIT_SECTION = 'EDIT_SECTION',
  CREATE_ASSIGNMENT = 'CREATE_ASSIGNMENT',
  DELETE_ASSIGNMENT = 'DELETE_ASSIGNMENT',
  REORDER_ASSIGNMENT = 'REORDER_ASSIGNMENT',
  EDIT_ASSIGNMENT = 'EDIT_ASSIGNMENT',
}

export type MethodologyActionCreateSection = {
  id: number;
  name: string;
  order: number;
  logo: string;
  type: MethodologyActionTypes.CREATE_SECTION;
};

export type MethodologyActionDeleteSection = {
  order: number;
  type: MethodologyActionTypes.DELETE_SECTION;
};

export type MethodologyActionReorderSection = {
  oldOrder: number;
  newOrder: number;
  type: MethodologyActionTypes.REORDER_SECTION;
};

export type MethodologyActionEditSection = {
  order: number;
  name: string;
  logo: string;
  type: MethodologyActionTypes.EDIT_SECTION;
};

export type MethodologyActionCreateAssignment = {
  id: number;
  name: string;
  sectionOrder: number;
  order: number;
  details: string;
  template: string;
  description: string;
  isHillary: boolean;
  type: MethodologyActionTypes.CREATE_ASSIGNMENT;
};

export type MethodologyActionDeleteAssignment = {
  sectionOrder: number;
  order: number;
  type: MethodologyActionTypes.DELETE_ASSIGNMENT;
};

export type MethodologyActionReorderAssignment = {
  oldSectionOrder: number;
  oldOrder: number;
  newSectionOrder: number;
  newOrder: number;
  type: MethodologyActionTypes.REORDER_ASSIGNMENT;
};

export type MethodologyActionEditAssignment = {
  order: number;
  sectionOrder: number;
  name: string;
  description: string;
  details: string;
  template: string;
  type: MethodologyActionTypes.EDIT_ASSIGNMENT;
  subType: 'name' | 'description' | 'details' | 'template';
};

export type MethodologyAction =
  | MethodologyActionCreateSection
  | MethodologyActionDeleteSection
  | MethodologyActionReorderSection
  | MethodologyActionEditSection
  | MethodologyActionCreateAssignment
  | MethodologyActionDeleteAssignment
  | MethodologyActionReorderAssignment
  | MethodologyActionEditAssignment;

export type MethodologyUndoAction = {
  doAction: MethodologyAction;
  undoAction: MethodologyAction;
};
