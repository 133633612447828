import React, { useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'StoreModel';
import styled from 'styled-components';
import { AnswerRow, FormQuestion, FormQuestionAnswer, FormQuestionOption, Literals } from 'redux/types/account';
import { Dropdown } from 'components/common/dropdown';
import { FormQuestionAnswerType } from 'redux/types/enums';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { StyledDatePicker } from 'components/common/request-schedule-meeting';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { parseFileName } from 'util/application-manager';
import { Logo } from 'components/common/logo';
import { Property } from 'csstype';
import { getAllowedFileTypes, isFileSizeLimitExceeded } from 'util/utils';
import { StyledButton } from 'primitives/Button/style';
import LongTextEditor from './long-text-editor';
import '../../stylesheets/ck-editor.css';
import { MandatoryInputLabel, Title, StyledInput, DeleteIcon } from 'components/styled-components/common';
import { clearSelectedFile, selectFile } from 'redux/actions/file-upload';
import { SelectedFile } from 'redux/types/file-upload';
import { toast } from 'components/common/toast';
import { BlueNoBackgroundButton } from 'components/notifications/invitation-response';
import { Paper, TableContainer, Table, TableCell, TableRow, TableHead, TableBody } from '@material-ui/core';
import InnerHtmlWrapper from 'components/editor/InnerHtmlWrapper';
import { LineWeight } from '@material-ui/icons';

interface QuestionComponentProps extends dispatchType {
  question: FormQuestion;
  answer: FormQuestionAnswer[];
  setAnswer: (answer: FormQuestionAnswer[]) => void;
  viewMode?: boolean;
  placeholder?: string;
  handleSelectedFile?: (
    question: FormQuestion,
    answer: FormQuestionAnswer[],
    file: File,
    cb: (file: File) => Promise<string>,
  ) => void;
  isFilesUploading: boolean;
}

const StyledTableRow = styled(TableRow)`
  :hover {
    .delete-icon {
      display: block !important;
    }
  }
`;

const StyledTableCell = styled(TableCell)`
  border-bottom: none !important;
  padding: 16px 16px 0 0 !important;
`;

const StyledDropdown = styled(Dropdown)`
  font-size: 0.9em;

  &&& {
    width: unset;
    border-radius: 0.2em;
    text-transform: uppercase;
  }
`;

const Container = styled.div<{ viewMode?: boolean }>`
  padding-top: 0.5em;
  ${({ viewMode }) => viewMode && `margin-bottom: 1em; `}
  display:flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 0.4em;
`;

export const AnswerDiv = styled.div`
  line-height: 1.2em;
  img {
    max-width: 100%;
    border-radius: 10px;
    box-shadow: 1px 1px 6px 1px #00000036;
  }
`;

const HiddenInput = styled.input<{ mt?: Property.MarginTop }>`
  opacity: 0;
  position: absolute;
  margin-top: ${props => props.mt ?? '-1rem'};
  z-index: -1;
`;

const FileContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: flex-end;
  gap: 0.8em;
`;

const CustomStyledInput = styled(StyledInput)`
  font-size: 0.9em;

  color: ${({ theme }) => theme.colors.grey.dark100};
  ::placeholder {
    color: ${({ theme }) => theme.colors.grey.dark100};
  }
  :focus {
    font-size: 0.9em;
  }
`;

const buildAnswers = (question: FormQuestion, answers: FormQuestionAnswer[], props?: Record<string, unknown>) => {
  const answer = answers?.length ? answers[0] : {};
  return [
    Object.assign(
      { id: 0, formId: 0, answer: '', optionAnswerOrder: 0, optionRowAnswerOrder: 0, formQuestionId: question.id },
      answer,
      props,
    ),
  ];
};

const getFirstAnswer = (answers: FormQuestionAnswer[]) =>
  answers.length && !Array.isArray(answers[0].answer) ? answers[0].answer : '';

const CURRENCIES = ['EUR', 'USD', 'GBP', 'EGP'].map(currency => currency.toUpperCase());
const AccountMoneyAnswer: React.FC<QuestionComponentProps> = props => {
  const { setAnswer, answer, question, viewMode } = props;
  const [addedCurrencies, setAddedCurrencies] = useState<string[]>([]);
  const numericValue = parseInt(getFirstAnswer(answer));
  const numericValueLength = numericValue?.toString()?.length;
  const currencyValue = getFirstAnswer(answer).substr(numericValueLength || 0) || CURRENCIES[0];
  const handleSetAnswer = (numericValue: number, currencyValue: string) => {
    setAnswer(buildAnswers(question, answer, { answer: `${numericValue}${currencyValue}` }));
  };

  return viewMode ? (
    <span>{getFirstAnswer(answer)}</span>
  ) : (
    <Row style={{ alignItems: 'stretch' }}>
      <StyledInput
        value={numericValue}
        onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
          handleSetAnswer(+evt.target.value, currencyValue || 'EUR')
        }
        style={{ maxWidth: '10em' }}
        type="number"
      />
      <StyledDropdown
        search
        allowAdditions
        additionLabel="Add "
        additionPosition="top"
        style={{ marginLeft: '0.2em' }}
        value={currencyValue}
        onChange={(evt: React.SyntheticEvent, data: any) => handleSetAnswer(numericValue || 0, data.value)}
        onAddItem={(evt: React.SyntheticEvent, data: any) => {
          const value = data.value.toUpperCase();
          if (CURRENCIES.indexOf(value) > -1) return;
          if (addedCurrencies.indexOf(value) > -1) return;
          setAddedCurrencies([...addedCurrencies, value]);
          handleSetAnswer(numericValue || 0, value);
        }}
        options={[...CURRENCIES, ...addedCurrencies].map((currency: string) => ({
          key: currency,
          value: currency,
          text: currency,
        }))}
      />
    </Row>
  );
};

const ScaleOption = styled.div<{ viewMode?: boolean; checked?: boolean }>`
  display: flex;
  flex-direction: column;
  min-width: 3.5em;
  align-items: center;
  & > *:hover {
    ${({ viewMode }) => !viewMode && `cursor: pointer;`}
  }

  ${({ checked, theme }) =>
    checked &&
    `& label {
      font-weight: bold;
      color: ${theme.colors.blue.normal};  
    }
  `}
`;

const ScaleAnswer: React.FC<QuestionComponentProps> = props => {
  const { question, answer, setAnswer, viewMode } = props;
  const handleOptionClick = (optionAnswerOrder: number) =>
    setAnswer(buildAnswers(question, answer, { optionAnswerOrder }));

  return (
    <div>
      {!!question.questionOptions?.length && (
        <Row>
          <div>{question.questionOptions[0].questionOption}</div>
          {question.questionOptions?.map((option: FormQuestionOption) => {
            const checked = !!answer.length && answer[0].optionAnswerOrder === option.questionOptionOrder;
            return (
              <ScaleOption key={option.questionOptionOrder.toString()} viewMode={viewMode} checked={checked}>
                <label onClick={() => handleOptionClick(option.questionOptionOrder)}>
                  {option.questionOptionOrder}
                </label>
                {!viewMode && (
                  <input
                    type="radio"
                    id={`scale-${question.id}-${option.questionOptionOrder}`}
                    name={`scale-${question.id}`}
                    checked={checked}
                    onChange={() => handleOptionClick(option.questionOptionOrder)}
                  />
                )}
              </ScaleOption>
            );
          })}
          <div>{question.questionOptions[question.questionOptions.length - 1].questionOption}</div>
        </Row>
      )}
    </div>
  );
};

const CheckboxAnswer: React.FC<QuestionComponentProps> = props => {
  const { question, answer, setAnswer, viewMode } = props;
  const [noneSelected, setNoneSelected] = useState<boolean>(!props.answer.length);

  const onChange = (index: number) => {
    const answers = answer.find((ans: FormQuestionAnswer) => ans.optionAnswerOrder === index)
      ? answer.filter((ans: FormQuestionAnswer) => ans.optionAnswerOrder !== index)
      : [
          ...answer,
          {
            id: 0,
            formId: 0,
            formQuestionId: question.id,
            answer: '',
            optionAnswerOrder: index,
            optionRowAnswerOrder: 0,
          },
        ];
    setAnswer(answers);
    setNoneSelected(!answers.length);
  };

  return (
    <div>
      {question.questionOptions?.map((option: FormQuestionOption, index: number) => {
        const id = `checkbox-${question.id}-${index}`;
        const checked = !!answer.find((ans: FormQuestionAnswer) => ans.optionAnswerOrder === index);
        return (
          <Row style={viewMode ? { lineHeight: '1.5em' } : {}} key={index}>
            {!viewMode && <input type="checkbox" id={id} checked={checked} onChange={() => onChange(index)} />}
            {(!viewMode || checked) && <label htmlFor={id}>{option.questionOption}</label>}
          </Row>
        );
      })}
      {question.mandatoryAnswer && noneSelected && <HiddenInput type="radio" name={question.title} tabIndex={-1} />}
    </div>
  );
};

const RadioAnswer: React.FC<QuestionComponentProps> = props => {
  const { question, answer, setAnswer, viewMode } = props;
  return (
    <div>
      {question.questionOptions?.map((option: FormQuestionOption, index: number) => {
        const id = `radio-${question.id}-${index}`;
        const checked = !!answer.length && answer[0].optionAnswerOrder === index;
        return (
          <Row style={viewMode ? { lineHeight: '1.5em' } : {}} key={index}>
            {!viewMode && (
              <input
                id={id}
                type="radio"
                name={question.title}
                disabled={viewMode}
                checked={checked}
                onClick={() => checked && setAnswer([])}
                onChange={() => setAnswer(buildAnswers(question, answer, { optionAnswerOrder: index }))}
              />
            )}
            {(!viewMode || checked) && <label htmlFor={id}>{option.questionOption}</label>}
          </Row>
        );
      })}
    </div>
  );
};

const DropdownAnswer: React.FC<QuestionComponentProps> = props => {
  const { question, placeholder, answer, setAnswer } = props;
  // set default value to -1, since 0 is actually first option.
  const firstAnswerOrder = answer.length ? answer[0].optionAnswerOrder : -1;
  const ans = question.questionOptions?.find(option => firstAnswerOrder === option.questionOptionOrder);

  if (props.viewMode) {
    return <span>{ans ? ans.questionOption : '-'}</span>;
  }

  return (
    <div>
      <Dropdown
        placeholder={placeholder}
        value={firstAnswerOrder}
        onChange={(evt: any, data: { value: number }) =>
          setAnswer(buildAnswers(question, answer, { optionAnswerOrder: data.value }))
        }
        options={(question.questionOptions || []).map((option: FormQuestionOption) => ({
          text: option.questionOption,
          value: option.questionOptionOrder,
          key: option.questionOptionOrder,
        }))}
      />
    </div>
  );
};

const TableAnswer: React.FC<QuestionComponentProps & { literals: Literals }> = props => {
  const { question, answer, setAnswer, viewMode, literals } = props;
  const headers: FormQuestionOption[] = question.questionOptions || [];

  useEffect(() => {
    if (answer.length === 0) {
      const newRow = headers.map(header => {
        return {
          id: 0,
          formId: 0,
          formQuestionId: question.id,
          answer: '',
          optionAnswerOrder: header.questionOptionOrder,
          optionRowAnswerOrder: 0,
        };
      });
      setAnswer(newRow);
    }
  }, [answer]);

  const handleAddRow = (e: MouseEvent) => {
    e.preventDefault();
    const newRow = headers.map(header => {
      return {
        id: 0,
        formId: 0,
        formQuestionId: question.id,
        answer: '',
        optionAnswerOrder: header.questionOptionOrder,
        optionRowAnswerOrder: answer.length / headers.length,
      };
    });
    setAnswer([...answer, ...newRow]);
  };

  const handleRemoveRow = (e: MouseEvent, optionRowAnswerOrder: number) => {
    e.preventDefault();
    const updatedAnswer = answer
      .filter(ans => ans.optionRowAnswerOrder !== optionRowAnswerOrder)
      .map(ans => {
        if (ans.optionRowAnswerOrder > optionRowAnswerOrder)
          return { ...ans, optionRowAnswerOrder: ans.optionRowAnswerOrder - 1 };
        return ans;
      });
    setAnswer(updatedAnswer);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, cell: FormQuestionAnswer) => {
    e.preventDefault();
    const { value } = e.target;
    const updatedAnswer = answer.map((ans: FormQuestionAnswer) => {
      if (ans.optionAnswerOrder === cell.optionAnswerOrder && ans.optionRowAnswerOrder === cell.optionRowAnswerOrder) {
        return { ...ans, answer: value };
      }
      return ans;
    });
    setAnswer(updatedAnswer);
  };

  const getTableRows = () => {
    const rows: FormQuestionAnswer[][] = [];
    for (let i = 0; i < answer.length / headers.length; i++) {
      const rowData = [];
      for (let j = 0; j < headers.length; j++) {
        const cell = answer.find(ans => ans.optionAnswerOrder === j && ans.optionRowAnswerOrder === i);
        if (cell) {
          rowData.push(cell);
        } else {
          rowData.push({
            id: 0,
            formId: 0,
            formQuestionId: question.id,
            answer: '',
            optionAnswerOrder: j,
            optionRowAnswerOrder: i,
          });
        }
      }
      rows.push(rowData);
    }
    return rows;
  };

  return (
    <TableContainer component={Paper} elevation={0}>
      <Table aria-label="table-question">
        <TableHead>
          <TableRow>
            {headers?.map((option: FormQuestionOption, index: number) => (
              <StyledTableCell style={{ textTransform: 'capitalize', fontFamily: 'inherit !important' }} key={index}>
                {option.questionOption}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {getTableRows().map((row, rowIndex) => (
            <StyledTableRow key={rowIndex}>
              {row.map((cell, cellIndex) => (
                <StyledTableCell key={cellIndex}>
                  <StyledInput disabled={viewMode} value={cell.answer} onChange={e => handleInputChange(e, cell)} />
                </StyledTableCell>
              ))}
              <StyledTableCell style={{ minWidth: '50px' }}>
                {!viewMode && row[0].optionRowAnswerOrder !== 0 && (
                  <DeleteIcon
                    className="delete-icon"
                    name="trash alternate outline"
                    onClick={(e: MouseEvent) => handleRemoveRow(e, row[0].optionRowAnswerOrder)}
                  />
                )}
              </StyledTableCell>
            </StyledTableRow>
          ))}
          {!viewMode && (
            <TableRow>
              <StyledTableCell colSpan={headers.length}>
                <BlueNoBackgroundButton onClick={handleAddRow}>{literals.table_add_new_row}</BlueNoBackgroundButton>
              </StyledTableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const CalendarAnswer: React.FC<QuestionComponentProps & { literals: Literals }> = props => {
  const { answer, setAnswer, question, literals } = props;
  const value = getFirstAnswer(answer);
  return props.viewMode ? (
    <span>{answer.length ? moment(answer[0].answer as string).format('DD/MM/YYYY') : ''}</span>
  ) : (
    <div>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <StyledDatePicker
          style={{ marginTop: '0em' }}
          autoOk
          margin="normal"
          id="startDate"
          label={literals.event_edit_start_date_label && ''}
          format="DD/MM/YYYY"
          value={value || null}
          variant="inline"
          onChange={(date): void => setAnswer(buildAnswers(question, answer, { answer: moment(date).toISOString() }))}
        />
      </MuiPickersUtilsProvider>
      {question.mandatoryAnswer && (
        <div>
          <HiddenInput name={question.title} value={value} tabIndex={-1} mt="-2.5rem" />
        </div>
      )}
    </div>
  );
};

type ErrorInfo = {
  errorMessage: string;
  isError: boolean;
};

interface FileAnswerProps extends QuestionComponentProps, dispatchType {
  bearer: string;
  literals: Literals;
  imageAnswer?: boolean;
  handleSelectedFile?: (
    question: FormQuestion,
    answer: FormQuestionAnswer[],
    file: File,
    cb: (file: File) => Promise<string>,
  ) => void;
  selectedFiles: SelectedFile[];
}
const FileAnswer: React.FC<FileAnswerProps> = props => {
  const { answer, question, literals, selectFile, setAnswer, isFilesUploading, selectedFiles } = props;
  const fileRef = useRef<HTMLInputElement>(null);
  const filePath = getFirstAnswer(answer);
  const pathSplit = filePath?.split('/') || '';
  let fileName = parseFileName(filePath);
  if (fileName.length <= 4) fileName = pathSplit[pathSplit.length - 1];

  if (props.viewMode) {
    return filePath ? (
      <a href={filePath} target="_blank" rel="noopener noreferrer">
        {fileName}
      </a>
    ) : (
      <span>-</span>
    );
  }

  const handleOnChange = (e: any) => {
    if (e.target.files[0]) {
      if (isFileSizeLimitExceeded(e.target.files[0].size)) {
        // this will help the onChange event to be triggered even if the same file is selected
        if (e.target?.value) e.target.value = '';
        toast(literals.upload_resource_limit_exceed_message.replace('{0}', '100'), { type: 'warning' });
        return;
      }
      selectFile({ file: e.target.files[0], question });
      setAnswer(buildAnswers(question, answer, { answer: e.target.files[0].name }));
    }
  };

  const handleUpload = () => {
    if (fileRef) fileRef.current?.click();
  };

  if (props.imageAnswer) {
    return (
      <div>
        <Row style={{ alignItems: 'flex-end' }}>
          <Logo src={filePath} />
          <StyledButton loading={isFilesUploading && fileRef.current?.value} type="button" onClick={handleUpload}>
            {literals.resource_upload_upload}
          </StyledButton>
        </Row>
        <HiddenInput
          ref={fileRef}
          id={`file-${question.id}`}
          type="file"
          accept={getAllowedFileTypes()}
          onChange={e => handleOnChange(e)}
          tabIndex={-1}
        />
      </div>
    );
  }

  return (
    <FileContainer>
      {filePath &&
        (filePath.includes('/Images/Uploads/') ? (
          <a href={filePath} target="_blank" rel="noopener noreferrer">
            {fileName}
          </a>
        ) : (
          <p>{filePath}</p>
        ))}
      <StyledButton loading={isFilesUploading && fileRef.current?.value} type="button" onClick={handleUpload}>
        {literals.resource_upload_upload}
      </StyledButton>
      <HiddenInput
        ref={fileRef}
        id={`file-${question.id}`}
        type="file"
        onChange={e => handleOnChange(e)}
        tabIndex={-1}
        accept={getAllowedFileTypes()}
      />
    </FileContainer>
  );
};

const RenderQuestionType: React.FC<
  QuestionComponentProps & { bearer: string; literals: Literals; selectedFiles: SelectedFile[] }
> = props => {
  const { literals, question, answer, setAnswer, bearer, viewMode, handleSelectedFile, ...rest } = props;
  const value = getFirstAnswer(answer);
  const setAnswerSingleTextField = (value: string) => setAnswer(buildAnswers(question, answer, { answer: value }));

  switch (question.answerType) {
    case FormQuestionAnswerType.ShortTextAnswer:
      return viewMode ? (
        <AnswerDiv {...rest} dangerouslySetInnerHTML={{ __html: value }} />
      ) : (
        <CustomStyledInput
          {...rest}
          value={value}
          onChange={(evt: React.ChangeEvent<HTMLInputElement>) => setAnswerSingleTextField(evt.target.value)}
          placeholder={literals.form_answer_placeholder}
        />
      );

    case FormQuestionAnswerType.LongTextAnswer:
      return viewMode ? (
        <AnswerDiv {...rest} className="ck-content" dangerouslySetInnerHTML={{ __html: value }} />
      ) : (
        <>
          <LongTextEditor
            {...rest}
            style={{ backgroundColor: '#f1f5f9' }}
            type="long-text-answer"
            value={value}
            setDescription={(textAnswer: string) => setAnswerSingleTextField(textAnswer)}
            placeholder={literals.form_answer_placeholder}
            placeholderStyle={{
              backgroundColor: '#F1F5F9',
              padding: '0.5em 0.7em',
              lineHeight: '1.3rem',
              border: 0,
              fontSize: '0.9em',
            }}
          />
          {question.mandatoryAnswer && <HiddenInput name={question.title} value={value} mt="-2rem" tabIndex={-1} />}
        </>
      );

    case FormQuestionAnswerType.CheckBoxChoiceAnswer:
      return <CheckboxAnswer {...rest} viewMode={viewMode} answer={answer} setAnswer={setAnswer} question={question} />;

    case FormQuestionAnswerType.RadioChoiceAnswer:
      return <RadioAnswer {...rest} viewMode={viewMode} answer={answer} setAnswer={setAnswer} question={question} />;

    case FormQuestionAnswerType.DropDownChoiceAnswer:
      return (
        <DropdownAnswer
          {...rest}
          viewMode={viewMode}
          answer={answer}
          setAnswer={setAnswer}
          question={question}
          placeholder={literals.form_select_placeholder}
        />
      );

    case FormQuestionAnswerType.MultipleChoiceAnswer:
      return <CheckboxAnswer {...rest} viewMode={viewMode} answer={answer} setAnswer={setAnswer} question={question} />;

    case FormQuestionAnswerType.AccountMoneyAnswer:
      return viewMode ? (
        <div {...rest} dangerouslySetInnerHTML={{ __html: value }} />
      ) : (
        <AccountMoneyAnswer {...rest} viewMode={viewMode} answer={answer} setAnswer={setAnswer} question={question} />
      );

    case FormQuestionAnswerType.FileAnswer:
      return (
        <FileAnswer
          {...rest}
          literals={literals}
          viewMode={viewMode}
          bearer={bearer}
          answer={answer}
          setAnswer={setAnswer}
          question={question}
          handleSelectedFile={handleSelectedFile}
        />
      );

    case FormQuestionAnswerType.ProjectLogoAnswer:
    case FormQuestionAnswerType.ProjectCoverAnswer:
      return (
        <FileAnswer
          {...rest}
          literals={literals}
          viewMode={viewMode}
          bearer={bearer}
          answer={answer}
          setAnswer={setAnswer}
          question={question}
          imageAnswer
          handleSelectedFile={handleSelectedFile}
        />
      );

    case FormQuestionAnswerType.CalendarAnswer:
      return (
        <CalendarAnswer
          {...rest}
          viewMode={viewMode}
          literals={literals}
          answer={answer}
          setAnswer={setAnswer}
          question={question}
        />
      );

    case FormQuestionAnswerType.ScaleAnswer:
      return <ScaleAnswer {...rest} viewMode={viewMode} setAnswer={setAnswer} answer={answer} question={question} />;
    case FormQuestionAnswerType.CountryAnswer:
      return (
        <DropdownAnswer
          {...rest}
          viewMode={viewMode}
          answer={answer}
          setAnswer={setAnswer}
          question={question}
          placeholder={literals.form_select_placeholder}
        />
      );
    case FormQuestionAnswerType.TableAnswer:
      return (
        <TableAnswer
          {...rest}
          viewMode={viewMode}
          setAnswer={setAnswer}
          literals={literals}
          answer={answer}
          question={question}
        />
      );
    default:
      return <span>{literals.form_type_not_supported}</span>;
  }
};

const mapStateToProps = (state: RootState) => ({
  literals: state.literals,
  bearer: state.account.session.session.bearer,
  selectedFiles: state.selectedFiles,
});

const mapDispatchToProps = {
  selectFile: selectFile,
  clearSelectedFile: clearSelectedFile,
};

type dispatchType = typeof mapDispatchToProps;

interface Props extends ReturnType<typeof mapStateToProps>, dispatchType {
  question: FormQuestion;
  answer: FormQuestionAnswer[];
  setAnswer: (answer: FormQuestionAnswer[]) => void;
  viewMode?: boolean;
  handleSelectedFile?: (
    question: FormQuestion,
    answer: FormQuestionAnswer[],
    file: File,
    cb: (file: File) => Promise<string>,
  ) => void;
  isMissing: boolean;
  titleStyles?: React.CSSProperties;
  isFilesUploading: boolean;
}
const ViewQuestion: React.FC<Props> = props => {
  const {
    question,
    literals,
    answer,
    setAnswer,
    bearer,
    viewMode,
    handleSelectedFile,
    isMissing,
    titleStyles,
    ...rest
  } = props;

  return (
    <Container viewMode={viewMode}>
      <Title
        style={titleStyles || {}}
        viewMode={viewMode}
        id={`question-${question.id}`}
        required={question.mandatoryAnswer}
      >
        {question.title}
      </Title>
      {!viewMode && question.description && (
        <InnerHtmlWrapper
          style={{
            LineWeight: '1.2em',
            img: { maxWidth: '100%', borderRadius: '10px', boxShadow: '1px 1px 6px 1px #00000036' },
          }}
          html={question.description}
        />
      )}
      <RenderQuestionType
        answer={answer}
        setAnswer={setAnswer}
        question={question}
        literals={literals}
        bearer={bearer}
        viewMode={viewMode}
        handleSelectedFile={handleSelectedFile}
        {...rest}
      />
      {isMissing && <MandatoryInputLabel>{literals.forms_mandatory_answer}</MandatoryInputLabel>}
    </Container>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewQuestion);
