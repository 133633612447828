import { Form } from 'redux/types/account';
import { getAxiosInstance } from './helper';
import { AxiosResponse } from 'axios';
import { _mapResponseToForm } from './form';

const ai = getAxiosInstance();

export function saveProjectForm(
  bearer: string,
  communityId: number,
  form: Form,
): Promise<{ communityId: number; form: Form }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'PUT',
      url: `/api/Community/${communityId}/ProjectForm`,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      data: {
        ...form,
      },
    })
      .then((response: AxiosResponse) => {
        resolve({
          communityId,
          form: _mapResponseToForm(response.data),
        });
      })
      .catch(err => reject(err));
  });
}

export function fetchProjectForm(bearer: string, communityId: number): Promise<{ communityId: number; form: Form }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: `/api/Community/${communityId}/ProjectForm`,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        resolve({
          communityId,
          form: _mapResponseToForm(response.data),
        });
      })
      .catch(err => reject(err));
  });
}

export default {
  saveProjectForm,
  fetchProjectForm,
};
