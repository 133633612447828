import { createGlobalStyle, DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
  font: ` -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif`,
  colors: {
    blue: {
      dark: '#0C4DF0',
      normal: 'rgb(55,96,146)',
      light: '#ECF2FF',
      veryLight: '#F1F5F9',
      lightGrey: '#EBF1FF',
      darkGrey: '#DCE6F2',
    },
    lightBlue: {
      normal: '#00B0F0',
    },
    orange: {
      normal: '#FD5A3C',
      dark: '#DE350B',
      veryLight: '#FD5A3C21',
      light: '#FEAC29',
    },
    yellow: {
      normal: '#FFC000',
      light: '#FFFFCC',
      dark: '#CA6937',
    },
    green: {
      normal: '#3AB27E',
      light: '#74DC9D',
      secondLight: '#87B23A',
      dark: '#00875A',
    },
    black: '#302E37',
    grey: {
      light: '#D9DEEEAA',
      secondLight: '#D9DEEE',
      dark: 'rgb(64, 64, 64)',
      medium: 'rgba(54, 69, 91, 0.6)',
      secondMedium: '#BFBFBF',
      thirdMedium: 'rgb(127, 127, 127)',
      dark100: '#575757',
      lighter: '#EEEEEE',
    },
    white: '#FFF',
    purple: {
      normal: '#3A52B2',
      light: '#B2A0C7',
    },
    red: {
      normal: '#FF0000',
      light: '#b26261',
    },
  },
  sizes: {
    mobileWidth: '600px',
  },
};

export const chartColors = [
  theme.colors.green.normal,
  theme.colors.blue.normal,
  theme.colors.orange.normal,
  '#f93c82',
  '#1ebaed',
  '#465b92',
  '#f2c94c',
  '#6fcf97',
  '#219653',
];

const GlobalStyle = createGlobalStyle`
  body {
    font-weight: normal;
    font-style: normal;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    padding: 0;
    margin: 0;
    line-height: 24px;

    div.dropdown > input {
      padding-left: 0.5em !important;
      height: 100%;
      color: ${props => props.theme.colors.grey.dark};
      font-weight: bold;
      font-size: 0.9em;
    }

    /* 4K */
    @media (max-width: 3840px) {
      font-size: 36px;
    }

    /* 3K - QHD+ */
    @media (max-width: 3200px) {
      font-size: 34px;
    }

    /* MS SURFACE */
    @media (max-width: 3000px) {
      font-size: 32px;
    }

    /* APPLE RETINA */
    @media (max-width: 2880px) {
      font-size: 30px;
    }

    /* 2K - QHD */
    @media (max-width: 2560px) {
      font-size: 20px;
    }

    /* HD+ */
    @media (max-width: 1600px) {
      font-size: 17px;
    }

    /* HD */
    @media (max-width: 1366px) {
      font-size: 15px;
    }

    /* IPAD PRO */
    @media (max-width: 1112px) {
      font-size: 13px;
    }

    /* IPAD */
    @media (max-width: 1024px) {
      font-size: 12px;
    }

    /* WORST CASE SCENARIO */
    @media (max-width: 860px) {
      font-size: 10px;
    }

    @media (max-width: 734px) {
      font-size: 9px;
    }
  }

  h1 {
    font-size: 2em;
    margin-top: 1em;
    text-align: left;
  }

  h2 {
    font-size: 1.5em;
    margin-top: 1em;
    text-align: left;
  }

  h3 {
    font-size: 1.125em;
    margin-top: 1em;
    text-align: left;
  }

  h4 {
      font-size: 1em;
      margin-top: 1em;
      text-align: left;
  }

  a {
    text-decoration: none;
  }

  p {
    margin-top: 1em;
    text-align: left;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  }

  .ck-custom-toolbar-item {
    position: relative !important;
    width: 30px !important;
    height: 30px !important;
    text-align: center !important;
    line-height: 30px !important;
    vertical-align: middle !important;
    border-radius: 2px !important;
  }

  .ck-custom-toolbar-item:hover {
    background-color: #e6e6e6 !important;
  }

  .ck-custom-toolbar-tooltip {
    display: none;
    background-color: #333333 !important;
    font-size: 0.9em !important;
    line-height: 1.5 !important;
    color: white !important;
    padding: 0.3em 0.5em 0.25em 0.5em !important;
    border-radius: 2px !important;
    position: absolute !important;
    top: calc(100% + 0.5em) !important;
    left: 50% !important;
    transform: translate(-50%, 0);
    opacity: 0 !important;
    transition: opacity 0s ease-in-out 0s !important;
  }

  .ck-custom-toolbar-tooltip .tooltip-arrow {
    width: 1em !important;
    height: 1em !important;
    background-color: #333333 !important;
    position: absolute !important;
    transform: translate(-50%, -50%) rotate(45deg);
    left: 50% !important;
    z-index: -1;
  }

  .ck-custom-toolbar-item:hover + .ck-custom-toolbar-tooltip {
    display: block;
    visibility: visible;
    opacity: 1 !important;
    transition: opacity 0.2s ease-in-out 0.2s !important;
  }

  .ck-custom-toolbar-item * {
    font-family: Icons !important;
    position: relative !important;

  }

  .ck-emoji__actions, .ck-emoji__actions {
    display: flex !important;
    flex-wrap: wrap;
    max-width: 33em;
    max-height: 12em;
    overflow: auto;
  }

  .ck-body > .ck-balloon-panel_visible:nth-child(1) {
    z-index: calc(var(--ck-z-modal) + 1);
  }

  @-webkit-keyframes highlight-fade {
    0% {
      border-color: white
    }
    25% {
      border-color: red
    }
    50% {
      border-color: white
    }
    75% {
      border-color: red
    }
    100% {
      border-color: white
    }
  }

  @-moz-keyframes highlight-fade {
    0% {
      border-color: white
    }
    25% {
      border-color: red
    }
    50% {
      border-color: white
    }
    75% {
      border-color: red
    }
    100% {
      border-color: white
    }
  }

  @-o-keyframes highlight-fade {
    0% {
      border-color: white
    }
    25% {
      border-color: red
    }
    50% {
      border-color: white
    }
    75% {
      border-color: red
    }
    100% {
      border-color: white
    }
  }

  @keyframes highlight-fade {
    0% {
      border-color: white
    }
    25% {
      border-color: red
    }
    50% {
      border-color: white
    }
    75% {
      border-color: red
    }
    100% {
      border-color: white
    }
  }

  figure.table td, figure.table th {
    border: 1px solid black;
    border-collapse: collapse;
    min-width: 4em !important;
  }

  figure.table table {
    border: 1px solid black;
    margin: 0;
    border-collapse: collapse;
    min-width: 100%;
  }

  figure.table {
    margin: 0;
    width: 100%;
    max-height: 80vh;
    display: block !important;
    overflow: auto;
  }

  #cky-consent-toggler{
    top: unset!important;
    bottom: 0!important;
  }

  #feedback-button {
    transform: scale(0.7) translateY(9px) !important;
  }

  .ck-content {
    position: relative;
    flex: 1;
    width: 100%;
    text-align: left;
  }
  
  .ck-content-right {
    direction: rtl!important;
    text-align: right!important;
  }

  .ck-content-left li, .ck-content-left p, .ck-content-left h3, .ck-content-left span {
    text-align: left!important;
  }

  .ck-content-right li, .ck-content-right p, .ck-content-right h3, .ck-content-right span {
    text-align: right!important;
  }

  .ck-content .ck-editor__editable_inline {
    padding: 0;
  }

  .ck-content-small > .ck-editor__editable_inline {
    font-size: .9em;
    line-height: 1.5em;
  }

  .ck-content-padding > .ck-editor__editable_inline {
    padding-left: 9px;
    padding-right: 9px;
  }

  .ck-content img {
    max-width: 100%;
  }

  .ck-content iframe {
    width: 100%;
    max-height: 30em;
  }

  .ck-content span.html-object-embed {
      width: 100%;
  }

  .ck-content figure.table .min7emImportant {
      min-width: 7em !important;
  }

  .ck-content figure.table .min11emImportant {
      min-width: 11em !important;
  }

  .ck-content figure.table .min15emImportant {
      min-width: 15em !important;
  }

  .ck-content figure.table .min20emImportant {
      min-width: 20em !important;
  }

  .ck-content ol, .ck-content ul {
      margin-top: 0.5em;
      margin-bottom: 0.5em;
  }

  .ck-content .youtube-iframe-wrapper, .ck-content .ted-iframe-wrapper {
    position: relative;
    width: 100%;
    padding-top: 56%;
  }

  .ck-content .youtube-iframe-wrapper>iframe, .ck-content .ted-iframe-wrapper>iframe {
    width: 98%;
    height: 100%;
    left: 1%;
    top: 0px;
    position: absolute;
  }

  .ck-content span.html-object-embed {
      padding-top: 56%;
  }

  .ck-editor__editable span.html-object-embed {
      padding-top: 0%;
  }

  .nested-dropdown-fix .menu.visible .menu.visible {
    min-height: 10em !important;
  }
`;

export { theme, GlobalStyle };
