import {
  TabEntityCircle,
  CommunityEntityTab,
  CommunityTab,
  Member,
  Sorting,
  CommunityEntityTabFilterAnswer,
  CommunityEntityTabFilters,
} from 'redux/types/account';
import { createAsyncAction } from 'typesafe-actions';
import { AxiosError } from 'axios';
import { EmailInvitationDto } from 'ApiModels';

export const saveTabAsync = createAsyncAction(
  'SAVE_TAB_REQUEST',
  'SAVE_TAB_SUCCESS',
  'SAVE_TAB_FAILURE',
  'SAVE_TAB_IDLE',
)<{ bearer: string; tab: CommunityTab }, { communityId: number; tab: CommunityTab }, string, null>();

export const fetchCommunityTabsAsync = createAsyncAction(
  'FETCH_COMMUNITY_TABS_REQUEST',
  'FETCH_COMMUNITY_TABS_SUCCESS',
  'FETCH_COMMUNITY_TABS_FAILURE',
  'FETCH_COMMUNITY_TABS_IDLE',
)<{ bearer: string; communityId: number }, { communityId: number; tabs: CommunityTab[] }, string, null>();

export const deleteTabAsync = createAsyncAction(
  'DELETE_TAB_REQUEST',
  'DELETE_TAB_SUCCESS',
  'DELETE_TAB_FAILURE',
  'DELETE_TAB_IDLE',
)<{ bearer: string; communityId: number; tabId: number }, { communityId: number; tabId: number }, string, null>();

export const fetchTabByIdAsync = createAsyncAction(
  'FETCH_TAB_BY_ID_REQUEST',
  'FETCH_TAB_BY_ID_SUCCESS',
  'FETCH_TAB_BY_ID_FAILURE',
  'FETCH_TAB_BY_ID_IDLE',
)<{ bearer: string; communityId: number; tabId: number }, { communityId: number; tab: CommunityTab }, string, null>();

export const fetchTabEntitiesByIdAsync = createAsyncAction(
  'FETCH_TAB_ENTITIES_BY_ID_REQUEST',
  'FETCH_TAB_ENTITIES_BY_ID_SUCCESS',
  'FETCH_TAB_ENTITIES_BY_ID_FAILURE',
  'FETCH_TAB_ENTITIES_BY_ID_IDLE',
)<
  {
    bearer: string;
    communityId: number;
    tabId: number;
    skip: number;
    take: number;
    sorting: Sorting;
    concept: string;
    entitiesFilter: Partial<CommunityEntityTabFilterAnswer>;
  },
  {
    communityId: number;
    tabId: number;
    entities: CommunityEntityTab[];
    count: number;
    entitiesFilter: CommunityEntityTabFilters;
    skip: number;
  },
  string,
  null
>();

export const followOrUnFollowEntityAsync = createAsyncAction(
  'FOLLOW_OR_UN_FOLLOW_REQUEST',
  'FOLLOW_OR_UN_FOLLOW_SUCCESS',
  'FOLLOW_OR_UN_FOLLOW_FAILURE',
  'FOLLOW_OR_UN_FOLLOW_IDLE',
)<
  { bearer: string; communityId: number; tabId: number; entityId: number; isFollowing: boolean },
  { communityId: number; tabId: number; entityId: number; isFollowing: boolean },
  string,
  null
>();

export const inviteTabEntityMembersAsync = createAsyncAction(
  'INVITE_TABENTITY_MEMBERS_REQUEST',
  'INVITE_TABENTITY_MEMBERS_SUCCESS',
  'INVITE_TABENTITY_MEMBERS_FAILURE',
  'INVITE_TABENTITY_MEMBERS_IDLE',
)<{ bearer: string; data: EmailInvitationDto[] }, (EmailInvitationDto | AxiosError)[], string, null>();

export const fetchCommunityTabEntityCirclesAsync = createAsyncAction(
  'FETCH_COMMUNITY_TAB_CIRCLES_REQUEST',
  'FETCH_COMMUNITY_TAB_CIRCLES_SUCCESS',
  'FETCH_COMMUNITY_TAB_CIRCLES_FAILURE',
  'FETCH_COMMUNITY_TAB_CIRCLES_IDLE',
)<
  { bearer: string; communityId: number; tabEntityId: number },
  { communityId: number; tabEntityId: number; circles: TabEntityCircle[] },
  string,
  null
>();

export const fetchCommunityTabEntityCircleMembersAsync = createAsyncAction(
  'FETCH_COMMUNITY_CIRCLE_MEMBERS_REQUEST',
  'FETCH_COMMUNITY_CIRCLE_MEMBERS_SUCCESS',
  'FETCH_COMMUNITY_CIRCLE_MEMBERS_FAILURE',
  'FETCH_COMMUNITY_CIRCLE_MEMBERS_IDLE',
)<
  { bearer: string; communityId: number; tabEntityId: number; tabEntityCircleId: number; skip: number; take: number },
  {
    members: Member[];
    communityId: number;
    tabEntityCircleId: number | undefined;
    tabEntityId: number;
    skip: number | undefined;
    take: number | undefined;
  },
  string,
  null
>();

export const saveTabEntityCircleAsync = createAsyncAction(
  'FETCH_TAB_ENTITY_CIRCLE_REQUEST',
  'FETCH_TAB_ENTITY_CIRCLE_SUCCESS',
  'FETCH_TAB_ENTITY_CIRCLE_FAILURE',
  'FETCH_TAB_ENTITY_CIRCLE_IDLE',
)<
  { bearer: string; communityId: number; circle: TabEntityCircle; id: number },
  { communityId: number; circle: TabEntityCircle },
  string,
  null
>();

export const deleteTabEntityCircleAsync = createAsyncAction(
  'DELETE_TAB_ENTITY_CIRCLE_REQUEST',
  'DELETE_TAB_ENTITY_CIRCLE_SUCCESS',
  'DELETE_TAB_ENTITY_CIRCLE_FAILURE',
  'DELETE_TAB_ENTITY_CIRCLE_IDLE',
)<
  { bearer: string; communityId: number; id: number; circleId: number },
  { communityId: number; id: number },
  string,
  null
>();

export const editTabEntityCircleAsync = createAsyncAction(
  'EDIT_TAB_ENTITY_CIRCLE_REQUEST',
  'EDIT_TAB_ENTITY_CIRCLE_SUCCESS',
  'EDIT_TAB_ENTITY_CIRCLE_FAILURE',
  'EDIT_TAB_ENTITY_CIRCLE_IDLE',
)<
  { bearer: string; communityId: number; id: number; circleId: string; userId: string },
  { communityId: number; circleId: number; userId: number },
  string,
  null
>();

export const removeUserFromTabEntityAsync = createAsyncAction(
  'REMOVE_USER_FROM_TAB_ENTITY_REQUEST',
  'REMOVE_USER_FROM_TAB_ENTITY_SUCCESS',
  'REMOVE_USER_FROM_TAB_ENTITY_FAILURE',
  'REMOVE_USER_FROM_TAB_ENTITY_IDLE',
)<
  { bearer: string; userId: number; id: number; communityId: number },
  { communityId: number; userId: number; id: number },
  string,
  null
>();

export default {
  saveTabAsync,
  fetchCommunityTabsAsync,
  deleteTabAsync,
  fetchTabByIdAsync,
  fetchTabEntitiesByIdAsync,
  followOrUnFollowEntityAsync,
  inviteTabEntityMembersAsync,
  fetchCommunityTabEntityCirclesAsync,
  fetchCommunityTabEntityCircleMembersAsync,
  saveTabEntityCircleAsync,
  deleteTabEntityCircleAsync,
};
