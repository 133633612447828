/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, FC, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';
import { useForm } from 'react-hook-form';
import { NavigateAction, View, momentLocalizer } from 'react-big-calendar';
import { RootState } from 'StoreModel';
import CloseIcon from '@material-ui/icons/Close';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import SubjectIcon from '@material-ui/icons/Subject';
import GroupIcon from '@material-ui/icons/Group';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import VoiceChatIcon from '@material-ui/icons/VoiceChat';
import EventIcon from '@material-ui/icons/Event';
import { uniqBy, prop } from 'ramda';
import { Checkbox } from '@material-ui/core';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { MuiPickersUtilsProvider, TimePicker, DatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import momentTimezone from 'moment-timezone';

import {
  fetchMultipleMeetingSettingsAsync,
  updateSelectedUsers,
  updateUserMultipleMeetingSettings,
} from 'redux/actions/user-calendar';
import {
  upsertCommunityCalendarEventAsync,
  fetchCommunityCalendarEventDetailsAsync,
  deleteCommunityCalendarEventAsync,
} from 'redux/actions/community';
import { CalendarEvent, Circle, Community, Member, Project, User, MeetingOriginType } from 'redux/types/account';
import CalendarScheduler from '../calendar-scheduler/calendar-scheduler';
import AutocompleteComponent from './components/autocomplete';
import SelectedUser from './components/selected-user';
import { MultipleMeetingSettings, UserWeeklyAvailabilityUser, YearMonthUser } from 'services/types/user-calendar';
import { CalendarViewType, CustomEvent } from '../calendar-scheduler/types';
import {
  createWeeklyEventsWithAvailabilityLocalized,
  getUserAvailabilitiesEvents,
  getUserFixedAvailabilitiesEvents,
  getMonthsForCurrentWeek,
  timesToMeetingObject,
} from 'util/calendar';
import { Option } from './types';
import CircleDropDown from './components/dropdown';
import { PrimaryButton, SecondaryButton, SelectedUsersListContainer } from 'components/styled-components/common';
import { Flag, MeetingProvider } from 'redux/types/enums';
import { toast } from '../toast';
import { getUserEventsByMonth } from 'services/api/calendar';
import { UserEvent } from 'redux/types/calendar';
import Editor from 'components/editor';

type dispatchType = typeof mapDispatchToProps;

interface SchedulerMultipleUserModalProps extends ReturnType<typeof mapStateToProps>, dispatchType {
  bearer: string;
  onClose: () => void;
  project?: Project | Community;
  showModal: boolean;
  communityId: number;
  titlePlaceholder: string;
  initialEvent?: CalendarEvent | null;
}

interface FormDataProps {
  description: string;
  meetingName: string;
  location: string;
  createLink: boolean;
}

interface EventSession {
  startDate: string;
  startTime: string;
  endDate: string;
  endTime: string;
}

const mapStateToProps = (state: RootState) => ({
  multipleMeetingSettings: state.userCalendar.multipleMeetingSettings,
  currentUser: state.account.details.user,
  selectedUsers: state.userCalendar.selectedUsers,
  literals: state.literals,
  isUpsertingCommunityCalendarEvent: state.loading.upsertCommunityCalendarEventFlag,
  community: state.account.selectedCommunity,
  deleteEventFlag: state.loading.deleteCommunityCalendarEventFlag,
});

const mapDispatchToProps = {
  fetchMultipleMeetingSettings: fetchMultipleMeetingSettingsAsync.request,
  upsertCalendarEvent: upsertCommunityCalendarEventAsync.request,
  updateSelectedUsers: updateSelectedUsers,
  updateUserMultipleMeetingSettings: updateUserMultipleMeetingSettings,
  clearUpsertCalendarEventFlag: upsertCommunityCalendarEventAsync.cancel,
  fetchCalendarEventDetails: fetchCommunityCalendarEventDetailsAsync.request,
  deleteCalendarEvent: deleteCommunityCalendarEventAsync.request,
  clearDeleteCalendarState: deleteCommunityCalendarEventAsync.cancel,
};

const modalSteps = {
  FIRST_STEP: 'firstStep',
  SECOND_STEP: 'secondStep',
  THIRD_STEP: 'thirdStep',
};

enum Duration {
  DURATION_30 = 30,
  DURATION_45 = 45,
  DURATION_60 = 60,
}

const SchedulerMultipleUserModal: FC<SchedulerMultipleUserModalProps> = ({
  bearer,
  currentUser,
  fetchMultipleMeetingSettings,
  onClose,
  project,
  showModal,
  upsertCalendarEvent,
  communityId,
  updateSelectedUsers,
  updateUserMultipleMeetingSettings,
  multipleMeetingSettings,
  selectedUsers,
  literals,
  isUpsertingCommunityCalendarEvent,
  clearUpsertCalendarEventFlag,
  titlePlaceholder,
  initialEvent,
  fetchCalendarEventDetails,
  community,
  deleteCalendarEvent,
  deleteEventFlag,
  clearDeleteCalendarState,
}) => {
  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
  } = useForm<FormDataProps>({
    defaultValues: {
      description: initialEvent?.description,
      meetingName: initialEvent?.summary || titlePlaceholder,
      location: initialEvent?.location || '',
      createLink: false,
    },
  });
  const [modalStep, setModalStep] = useState<string>(initialEvent ? modalSteps.THIRD_STEP : modalSteps.FIRST_STEP);
  const [usersColorMap, setUsersColorMap] = useState<Record<number, string>>({});
  const [usersAvailability, setUsersAvailability] = useState<MultipleMeetingSettings[]>([]);
  const [userWeeklyAvailabilities, setUsersWeeklyAvailabilities] = useState<UserWeeklyAvailabilityUser[]>([]);
  const [currentUserTimeZone, setCurrentUserTimeZone] = useState<string>(momentTimezone.tz.guess());
  const [duration, setDuration] = useState<number>(Duration.DURATION_60);
  const [fixedTimeSlotsEvents, setFixedTimeSlotsEvents] = useState<CustomEvent[]>([]);
  const [fetchedUserMonths, setFetchedUserMonths] = useState<YearMonthUser[]>([]);
  const [scheduledUserEvents, setScheduledUserEvents] = useState<CustomEvent[]>([]);
  const [allEvents, setAllEvents] = useState<CustomEvent[]>([]);
  const [currentDate, setCurrentDate] = useState<Date>(new Date());
  const [eventSession, setEventSession] = useState<EventSession>({
    startDate: moment().format('YYYY-MM-DD'),
    startTime: moment().format('HH:mm'),
    endDate: moment().format('YYYY-MM-DD'),
    endTime: moment().format('HH:mm'),
  });
  const [dateIsSet, setDateIsSet] = useState<boolean>(false);
  const [description, setDescription] = useState<string>(initialEvent?.description || '');

  const startDateTime = `${eventSession.startDate}T${eventSession.startTime}`;
  const endDateTime = `${eventSession.endDate}T${eventSession.endTime}`;

  const hasMeetingProvider: boolean = community?.hasMeetingProvider;
  // Email
  const meetingProviderType: MeetingProvider = community?.meetingProviderType;

  // Updating the localizer to have Monday as the first day of the week
  // Explanation https://github.com/jquense/react-big-calendar/issues/1661
  moment.updateLocale('en', {
    week: {
      dow: 1,
      doy: 4,
    },
  });
  moment.tz.setDefault(currentUserTimeZone);
  const localizer = momentLocalizer(moment);

  // Reset moment locale to the default browser one when the component unmounts
  // so it doesn't interfere with other components using moment
  useEffect(() => {
    return () => {
      moment.tz.setDefault(momentTimezone.tz.guess());
    };
  }, []);

  const defaultStartOfDay = momentTimezone.tz(currentUserTimeZone);
  const defaultEndOfDay = momentTimezone.tz(currentUserTimeZone);
  defaultStartOfDay.set({ hour: 7, minute: 0, second: 0, millisecond: 0 });
  defaultEndOfDay.set({ hour: 22, minute: 0, second: 0, millisecond: 0 });

  const getMemberCircleName = (id: number) => {
    const matchingCircle = project?.circles?.find((circle: Circle) => circle?.id === id);
    return matchingCircle ? matchingCircle.name : null;
  };

  const autoCompleteData: Option[] | any =
    project?.members
      ?.map((item: Member) => {
        if (item.id === currentUser?.id) {
          return {
            ...item,
            circle: getMemberCircleName(item.circle?.id),
            name: `(${literals.calendar_meeting_you}) ${item.name}`,
          };
        }

        return {
          ...item,
          circle: getMemberCircleName(item.circle?.id),
        };
      })
      .sort((member, _) => {
        if (member.id === currentUser?.id) return -1;
        return 1;
      }) || [];

  const circles = project?.circles?.filter((circle: Circle) => {
    const currentUserCircle =
      project?.members?.find((member: Member) => member?.id === currentUser?.id)?.circle?.id || 0;

    if (circle?.userCount && circle?.userCount >= 1) {
      if (circle?.userCount === 1 && circle?.id === currentUserCircle) {
        return null;
      } else {
        return circle;
      }
    } else {
      return null;
    }
  });

  useEffect(() => {
    if (initialEvent?.id) {
      fetchCalendarEventDetails({
        bearer,
        eventId: initialEvent.id,
        communityId: communityId,
      });
    }
  }, []);

  useEffect(() => {
    if (initialEvent?.id && initialEvent?.attendeeUsers?.length) {
      setModalStep(modalSteps.THIRD_STEP);

      setEventSession({
        ...eventSession,
        ...timesToMeetingObject(
          initialEvent.startDate,
          initialEvent.startTime,
          initialEvent.endDate,
          initialEvent.endTime,
          currentUserTimeZone,
        ),
      });
      setDateIsSet(true);

      if (!selectedUsers?.length) {
        // Filtering out users that might not be part of the community/project anymore
        const initialUsers: Option[] = initialEvent.attendeeUsers
          .filter(attendee => {
            const user = project?.members?.find(projectMember => projectMember.id === attendee.id) as Member;
            if (user) return true;
            return false;
          })
          .map(attendee => {
            const user = project?.members?.find(projectMember => projectMember.id === attendee.id) as Member;
            return {
              id: user?.id || attendee.id,
              name: user?.name || attendee.name,
              circle: user?.circle || null,
              photo: user?.photo || attendee.photoUrl,
            } as Option;
          });
        initialUsers.sort((member, _) => {
          if (member.id === currentUser?.id) return -1;
          return 1;
        });

        updateSelectedUsers({ selectedUsers: initialUsers });
      }
    }
  }, [initialEvent, currentUserTimeZone]);

  const currentUserWithColor = {
    ...currentUser,
    color: usersColorMap[currentUser.id],
  };

  useEffect(() => {
    // A week can span over 2 months, so get the months we need to fetch events for
    const monthsToShow = getMonthsForCurrentWeek(currentDate);
    const userIds = selectedUsers ? selectedUsers.map((user: any) => user.id) : [];
    // Creating a list with all the user ids and months that need to be shown
    const userMonthsToShow: YearMonthUser[] = userIds.flatMap((id: number) => {
      return monthsToShow.map(month => ({
        year: month.year,
        month: month.month,
        userId: id,
      }));
    });

    // Don't fetch data that we fetched already
    const userMonthsToFetch = userMonthsToShow.filter(({ year, month, userId }) => {
      return !fetchedUserMonths.find(userMonth => {
        return userMonth.month === month && userMonth.year === year && userMonth.userId === userId;
      });
    });

    const eventsFetchTasks = userMonthsToFetch.map(({ userId, year, month }) =>
      getUserEventsByMonth(bearer, userId, month, year),
    );

    // Fetch all the user events needed
    Promise.allSettled(eventsFetchTasks).then(results => {
      results
        .filter(result => result.status === 'fulfilled')
        .forEach(result => {
          // @ts-ignore Outdated TypeScript version doesn't have the correct type for Promise.allSettled
          const { userEvents }: { userEvents: UserEvent[] } = result.value;
          const customEvents = (userEvents || []).map(({ id, start, end }) => {
            return {
              eventId: id,
              start: new Date(start),
              end: new Date(end),
            };
          });

          setScheduledUserEvents(scheduled => [...scheduled, ...customEvents]);
        });
    });
    setFetchedUserMonths(fetchedUserMonths => [...fetchedUserMonths, ...userMonthsToFetch]);
  }, [currentDate, currentUser, selectedUsers]);

  useEffect(() => {
    if (isUpsertingCommunityCalendarEvent === Flag.Success) {
      toast(literals.calendar_event_saved_successfully, { type: 'success' });
    }
    if (isUpsertingCommunityCalendarEvent === Flag.Failure) {
      toast(literals.event_edit_error_message, { type: 'error' });
    }
    clearUpsertCalendarEventFlag(null);
  }, [isUpsertingCommunityCalendarEvent]);

  useEffect(() => {
    if (deleteEventFlag === Flag.Success) {
      onCloseModal();
      toast(literals.delete_calendar_event_confirmation, { type: 'success' });
    }

    if (deleteEventFlag === Flag.Failure) {
      toast(literals.delete_calendar_event_failure, { type: 'error' });
      onCloseModal();
    }
  }, [deleteEventFlag]);

  useEffect(() => {
    if (project && project?.members && showModal) {
      let membersId = project?.members?.map((member: Member) => member?.id);

      if (!membersId.includes(currentUser?.id)) {
        membersId = [...membersId, currentUser?.id];
      }

      fetchMultipleMeetingSettings({ bearer: bearer, usersId: membersId });
    }
  }, [showModal, project]);

  useEffect(() => {
    if (!selectedUsers?.length) {
      setModalStep(modalSteps.FIRST_STEP);
    } else if (!initialEvent) {
      setModalStep(modalSteps.SECOND_STEP);
    }
  }, [showModal, selectedUsers]);

  useEffect(() => {
    if (selectedUsers && selectedUsers.length > 0 && multipleMeetingSettings) {
      const selectedUserIds = selectedUsers?.map((user: User) => user?.id);
      const selectedUsersData = multipleMeetingSettings
        .filter((user: MultipleMeetingSettings) => selectedUserIds?.includes(user?.userId))
        .map((user: MultipleMeetingSettings) => {
          const isCurrentUser = user?.userId === currentUser?.id;

          return {
            ...user,
            color: isCurrentUser
              ? '#FFC000'
              : selectedUsers.find((selectedUser: User) => selectedUser?.id === user?.userId)?.color,
          };
        });

      setUsersAvailability(selectedUsersData);
    }
  }, [selectedUsers, multipleMeetingSettings]);

  useEffect(() => {
    const currentUserAvailability = usersAvailability.find(availability => {
      return availability.userId === currentUser.id;
    });

    const userSetTimeZone = currentUserAvailability?.userMeetingPreference?.timeZone;

    if (userSetTimeZone && Boolean(momentTimezone.tz.zone(userSetTimeZone as string))) {
      setCurrentUserTimeZone(userSetTimeZone);
    }

    const weeklyEvents: UserWeeklyAvailabilityUser[] = getUserAvailabilitiesEvents(usersAvailability);

    setUsersWeeklyAvailabilities(weeklyEvents);
    setFixedTimeSlotsEvents(getUserFixedAvailabilitiesEvents(usersAvailability));
  }, [usersAvailability, duration, currentUserTimeZone]);

  useEffect(() => {
    const selectedUsersIds = selectedUsers ? selectedUsers.map(({ id }: { id: number }) => id) : [];

    if (userWeeklyAvailabilities && fixedTimeSlotsEvents && scheduledUserEvents && currentUserTimeZone) {
      const weeklyEvents = createWeeklyEventsWithAvailabilityLocalized(
        currentDate,
        duration,
        userWeeklyAvailabilities,
        fixedTimeSlotsEvents,
        scheduledUserEvents,
        selectedUsersIds,
        currentUserTimeZone,
        literals.calendar_meeting_slot_available,
        currentUser,
      );
      setAllEvents([...weeklyEvents]);
    }
  }, [currentDate, userWeeklyAvailabilities, duration, fixedTimeSlotsEvents, scheduledUserEvents, currentUserTimeZone]);

  const onCalendarNavigate = (newDate: Date, view: View, action: NavigateAction) => {
    setCurrentDate(newDate);
  };

  const handleRemoveSelectedUser = (optionId: number) => {
    if (selectedUsers?.length === 2) {
      const newSelectedOption = selectedUsers?.filter(
        (selectedUser: Option) => selectedUser?.id !== optionId && selectedUser?.id !== currentUser?.id,
      );
      updateSelectedUsers({
        selectedUsers: newSelectedOption,
      });

      setUsersAvailability([...newSelectedOption]);
    } else {
      const newSelectedOption = selectedUsers?.filter((selectedUser: Option) => selectedUser?.id !== optionId);
      updateSelectedUsers({
        selectedUsers: newSelectedOption,
      });

      setUsersAvailability([...newSelectedOption]);
    }
  };

  const handleEditParticipant = () => {
    setModalStep(modalSteps.SECOND_STEP);
  };

  const handleSelectEvent = (event: CustomEvent | null) => {
    setModalStep(modalSteps.THIRD_STEP);

    const startDate = moment(event?.start).format('YYYY-MM-DD');
    const startTime = moment(event?.start).format('HH:mm');
    const endDate = moment(event?.end).format('YYYY-MM-DD');
    const endTime = moment(event?.end).format('HH:mm');

    setEventSession({
      ...eventSession,
      startDate: startDate,
      startTime: startTime,
      endDate: endDate,
      endTime: endTime,
    });
    setDateIsSet(true);
  };

  const handleDeleteEvent = () => {
    if (initialEvent) {
      deleteCalendarEvent({
        bearer,
        communityId,
        eventId: initialEvent?.id,
      });
    }
  };

  const handleSelect = (guests: Option[]) => {
    if (!selectedUsers) {
      selectedUsers = [];
    }
    const usersRemoved = selectedUsers?.filter(
      (selectedUser: Option) => !guests?.some(guest => guest.id === selectedUser.id),
    );
    usersRemoved?.forEach((user: Option) => handleRemoveSelectedUser(user.id));
    const newUsers: Option[] = [];
    const currentUserRemoved = usersRemoved?.find((user: Option) => user.id === currentUser.id);

    guests?.forEach(guest => {
      const userWithColor = {
        ...guest,
        color: usersColorMap[guest.id],
      };

      newUsers.push(userWithColor);
    });

    newUsers.sort((member, _) => {
      if (member.id === currentUser?.id) return -1;
      return 1;
    });

    const uniqueUsers = uniqBy(prop('id'), newUsers);

    if (initialEvent || currentUserRemoved || selectedUsers?.length) {
      updateSelectedUsers({
        selectedUsers: [...uniqueUsers],
      });
    } else {
      updateSelectedUsers({
        selectedUsers: uniqBy(prop('id'), [currentUserWithColor, ...uniqueUsers]),
      });
    }
  };

  const handleSelectDropDown = (member: Member[]) => {
    const newUsers: Member[] = [];

    member?.forEach(member => {
      const userWithColor = {
        ...member,
        color: usersColorMap[member.id],
      };

      newUsers.push(userWithColor);
    });
    updateSelectedUsers({
      selectedUsers: [...newUsers],
    });
  };

  const handleDurationButtonClick = (duration: number) => {
    setDuration(duration);
  };

  const onCloseModal = () => {
    onClose();

    setDateIsSet(false);
    setModalStep(modalSteps.FIRST_STEP);
    setUsersAvailability([]);
    setCurrentDate(new Date());
    setDuration(Duration.DURATION_60);
    clearDeleteCalendarState(null);

    reset({
      description: '',
      meetingName: titlePlaceholder,
    });
    updateSelectedUsers({ selectedUsers: [] });
    updateUserMultipleMeetingSettings({ multipleMeetingSettings: [] });
  };

  const onSubmitModal = (data: FormDataProps): void => {
    const [startDate, startTime, endDate, endTime] = Object.values(eventSession);

    const formattedStartDate = moment(startDate).format('YYYY-MM-DD');
    const formattedEndDate = moment(endDate).format('YYYY-MM-DD');
    const formattedStartTime = moment(startTime, 'HH:mm').format('HH:mm:ss');
    const formattedEndTime = moment(endTime, 'HH:mm').format('HH:mm:ss');

    if (dateIsSet) {
      upsertCalendarEvent({
        bearer,
        communityId,
        eventToSave: {
          id: initialEvent?.id || '',
          summary: data.meetingName,
          description,
          startDate: formattedStartDate,
          startTime: formattedStartTime,
          endDate: formattedEndDate,
          endTime: formattedEndTime,
          isUserInAttendeeList: false,
          attendeeUsers: [...selectedUsers],
          location: data.location,
          createConference: data.createLink,
          meetingOriginType: MeetingOriginType.EventsCommunity,
        },
      });
    }

    onCloseModal();
  };

  const renderSelectedUsers = () => {
    const maxDisplayedUsers = 5;
    const displayedUsers = selectedUsers?.slice(0, maxDisplayedUsers) || [];
    const remainingUsersCount = (selectedUsers?.length || 0) - maxDisplayedUsers;

    return (
      <>
        {displayedUsers.map((option: Option) => (
          <SelectedUser
            key={option?.id}
            name={option?.id === currentUser?.id ? literals.calendar_meeting_you : option?.name}
            color={option?.color}
            avatar={option?.photo}
            isCurrentUser={option?.id === currentUser?.id}
            onRemoveUser={() => handleRemoveSelectedUser(option?.id)}
            allowRemovingCurrentUser={true}
          />
        ))}
        {remainingUsersCount > 0 && (
          <span>
            + {remainingUsersCount} {remainingUsersCount === 1 ? 'person' : 'people'}
          </span>
        )}
      </>
    );
  };

  if (!showModal) {
    return null;
  }

  const portalRoot = document.getElementById('portal');

  return portalRoot
    ? ReactDOM.createPortal(
        <Wrapper>
          <Main>
            <ModalContent>
              <Header>
                <TitleInputContainer>
                  <EditIcon />
                  <TitleInput placeholder="Add a title" type="text" {...register('meetingName', { required: true })} />
                  {errors.meetingName && <ErrorText>{literals.schedule_meeting_modal_required_filed}</ErrorText>}
                </TitleInputContainer>

                <ControlButtonsContainer>
                  {initialEvent && (
                    <DeleteEventButton onClick={handleDeleteEvent}>
                      <DeleteIcon />
                    </DeleteEventButton>
                  )}
                  <CloseButton onClick={onCloseModal}>
                    <CloseIcon />
                  </CloseButton>
                </ControlButtonsContainer>
              </Header>
              <CustomForm onSubmit={handleSubmit(onSubmitModal)}>
                <InputsContainer>
                  <InputContainer>
                    <TextAreaContainer>
                      <InputIcon>
                        <SubjectIcon />
                      </InputIcon>
                      <StandardAreaContainer>
                        <Editor content={description} placeholder="" withPadding setContent={setDescription} />
                      </StandardAreaContainer>
                    </TextAreaContainer>
                  </InputContainer>
                </InputsContainer>
                <AutocompleteDropDownContainer>
                  <InputContainerWithIcon>
                    <InputIcon>
                      <GroupIcon />
                    </InputIcon>
                    <AutocompleteComponent
                      userOptions={autoCompleteData}
                      onSelect={handleSelect}
                      selectedOptions={selectedUsers ?? []}
                      selectedUserText={literals.calendar_meeting_one_user_selected}
                      selectedUsersText={literals.calendar_meeting_users_selected}
                      placeholder={literals.calendar_meeting_users_placeholder}
                    />
                  </InputContainerWithIcon>
                  <CircleContainer>
                    <CircleDropDown
                      circles={circles}
                      members={project?.members}
                      currentUser={currentUserWithColor}
                      onSelect={handleSelectDropDown}
                      dropdownTitle={literals.calendar_meeting_show_circles}
                    />
                  </CircleContainer>
                </AutocompleteDropDownContainer>
                <SelectUsersContainer>
                  {selectedUsers?.length > 0 && (
                    <SelectedUsersListContainer>{renderSelectedUsers()}</SelectedUsersListContainer>
                  )}
                  {modalStep === modalSteps.FIRST_STEP && (
                    <NoteContainer>
                      <InputIcon>
                        <EventIcon />
                      </InputIcon>
                      <Note> {literals.calendar_meeting_select_timeslot} </Note>
                    </NoteContainer>
                  )}
                  {modalStep === modalSteps.SECOND_STEP && (
                    <>
                      <NoteContainer>
                        <InputIcon>
                          <EventIcon />
                        </InputIcon>
                        <Note> {literals.calendar_meeting_select_time_duration}</Note>
                      </NoteContainer>
                      <ButtonDurationContainer>
                        <DurationButton
                          onClick={() => handleDurationButtonClick(Duration.DURATION_30)}
                          isActive={duration === Duration.DURATION_30}
                        >
                          30 {literals.calendar_meeting_duration_minutes.toLowerCase()}
                        </DurationButton>
                        <DurationButton
                          onClick={() => handleDurationButtonClick(Duration.DURATION_45)}
                          isActive={duration === Duration.DURATION_45}
                        >
                          45 {literals.calendar_meeting_duration_minutes.toLowerCase()}
                        </DurationButton>
                        <DurationButton
                          onClick={() => handleDurationButtonClick(Duration.DURATION_60)}
                          isActive={duration === Duration.DURATION_60}
                        >
                          60 {literals.calendar_meeting_duration_minutes.toLowerCase()}
                        </DurationButton>
                      </ButtonDurationContainer>
                    </>
                  )}
                </SelectUsersContainer>
                {modalStep === modalSteps.FIRST_STEP && <Info> {literals.calendar_meeting_select_timeslot_note} </Info>}
                {modalStep === modalSteps.SECOND_STEP && (
                  <>
                    {selectedUsers?.length >= 1 && (
                      <>
                        <CalendarScheduler
                          events={allEvents}
                          isToolbarVisible={true}
                          defaultView={CalendarViewType.WEEK}
                          timeSlot={4}
                          timeIncrements={15}
                          minTime={defaultStartOfDay.toDate()}
                          maxTime={defaultEndOfDay.toDate()}
                          views={[CalendarViewType.WEEK]}
                          localizer={localizer}
                          onSelectEvent={handleSelectEvent}
                          onSelectSlot={handleSelectEvent}
                          userColorMap={usersColorMap}
                          isSelectable={selectedUsers?.length > 1}
                          currentUser={currentUser}
                          onNavigate={onCalendarNavigate}
                        />
                        <Legend>
                          <LegendItem active={true}>{literals.event_inside_office_hours}</LegendItem>
                          <LegendItem active={false}>{literals.event_outside_office_hours}</LegendItem>
                        </Legend>
                      </>
                    )}
                  </>
                )}
                {modalStep === modalSteps.THIRD_STEP && (
                  <SubmittedTimeContainer>
                    <EventIcon />
                    <SelectedDateContainer>
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <div>
                          <StyledDatePicker
                            autoOk={true}
                            margin="normal"
                            id="startDate"
                            label={literals.event_edit_start_date_label && ''}
                            format="DD/MM/YYYY"
                            value={startDateTime}
                            variant={'inline'}
                            onChange={(date: MaterialUiPickersDate): void => {
                              const endDateTime = moment(date?.toString()).add(duration, 'minutes');
                              setEventSession({
                                ...eventSession,
                                startDate: moment(date?.toString()).format('YYYY-MM-DD'),
                                endDate: endDateTime.format('YYYY-MM-DD'),
                                endTime: endDateTime.format('HH:mm'),
                              });
                            }}
                          />
                          <StyledTimePicker
                            autoOk={true}
                            margin="normal"
                            id="startTime"
                            format="HH:mm"
                            label={literals.event_edit_start_time_label && ''}
                            value={startDateTime}
                            variant={'inline'}
                            onChange={(date: MaterialUiPickersDate): void => {
                              const endDateTime = moment(date?.toString()).add(duration, 'minutes');
                              setEventSession({
                                ...eventSession,
                                startTime: moment(date?.toString()).format('HH:mm'),
                                endDate: endDateTime.format('YYYY-MM-DD'),
                                endTime: endDateTime.format('HH:mm'),
                              });
                            }}
                          />
                        </div>
                        <StyledTo>to</StyledTo>
                        <div>
                          <StyledDatePicker
                            autoOk={true}
                            margin="normal"
                            id="endDate"
                            label={literals.event_edit_end_date_label && ''}
                            format="DD/MM/YYYY"
                            value={endDateTime}
                            variant={'inline'}
                            onChange={(date: MaterialUiPickersDate): void => {
                              setEventSession({
                                ...eventSession,
                                endDate: moment(date?.toString()).format('YYYY-MM-DD'),
                              });
                            }}
                            minDate={startDateTime}
                          />
                          <StyledTimePicker
                            autoOk={true}
                            margin="normal"
                            id="endTime"
                            format="HH:mm"
                            label={literals.event_edit_end_time_label && ''}
                            value={endDateTime}
                            variant={'inline'}
                            onChange={(date: MaterialUiPickersDate): void => {
                              setEventSession({
                                ...eventSession,
                                endTime: moment(date?.toString()).format('HH:mm'),
                              });
                            }}
                          />
                        </div>
                      </MuiPickersUtilsProvider>
                    </SelectedDateContainer>
                    {selectedUsers?.length > 1 && (
                      <EditButton onClick={handleEditParticipant}>
                        {literals.calendar_meeting_edit_date_time}
                      </EditButton>
                    )}
                  </SubmittedTimeContainer>
                )}
                {!initialEvent && (
                  <HorizontalInputContainer>
                    <InputIcon>
                      <VoiceChatIcon />
                    </InputIcon>
                    <Checkbox
                      {...register('createLink', { required: false })}
                      color={'primary'}
                      style={{ marginLeft: '-12px' }}
                    />
                    <FormatedP>{literals.event_generate_meet_text}</FormatedP>
                  </HorizontalInputContainer>
                )}
                <InputsContainer>
                  <InputContainer>
                    <InputContainerWithIcon>
                      <InputIcon>
                        <LocationOnIcon />
                      </InputIcon>
                      <Input
                        placeholder={literals.calendar_meeting_location_placeholder}
                        type="text"
                        {...register('location')}
                      />
                    </InputContainerWithIcon>
                  </InputContainer>
                </InputsContainer>
                <FooterContainer>
                  <ButtonsContainer>
                    <SaveButton type="submit" disabled={!dateIsSet}>
                      {literals.community_edit_save_button}
                    </SaveButton>
                    <CancelButton onClick={onCloseModal}>{literals.confirm_cancel_button}</CancelButton>
                  </ButtonsContainer>
                </FooterContainer>
              </CustomForm>
            </ModalContent>
          </Main>
        </Wrapper>,
        portalRoot,
      )
    : null;
};

export default connect(mapStateToProps, mapDispatchToProps)(SchedulerMultipleUserModal);

const ErrorText = styled.div`
  color: #ffdbaa;
  font-size: 12px;
`;

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

const Main = styled.div`
  position: relative;
  width: 70%;
  max-height: min(max(80%, 760px), 100vh);
  min-width: 320px;
  min-height: 320px;
  max-width: 850px;
  background-color: white;
  padding: 40px 20px 20px 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  overflow: auto;
`;

export const ModalContent = styled.div`
  display: inline-block;
  align-items: center;
  justify-content: center;
  padding: 0 20px 0 20px;
  width: 100%;
  height: calc(100% - 10px);
`;

const CustomForm = styled.form`
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;

  width: 100%;
`;

const TitleInput = styled.input`
  &&& {
    width: 100% !important;
    border: 0 !important;
    font-weight: bold !important;
    font-size: 1.6em !important;
    padding-left: 1px !important;
    margin-left: 10px;
  }
  &:focus {
    // border: 0;
  }
`;

const ControlButtonsContainer = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  display: flex;
  align-items: center;
`;

const DeleteEventButton = styled.button`
  background: none;
`;

const TitleInputContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

const CloseButton = styled.button`
  background: none;
  font-weight: bold;
`;

const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 4px;
  margin-top: 20px;
`;

const Legend = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;

  padding: 8px 0;

  align-items: center;
`;

const LegendItem = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;

  ::before {
    content: '';
    width: 16px;
    height: 16px;
    background: ${({ active }) => (active ? '#ceedd8' : '#FFFFCC')};
    border-bottom: 1px solid ${({ active }) => (active ? '#91ba9d' : '#FFC000')};
    border-right: 1px solid ${({ active }) => (active ? '#91ba9d' : '#FFC000')};
  }
`;

const InputContainerWithIcon = styled.div`
  display: flex;
  align-items: center;
`;

const TextAreaContainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
`;

const StandardAreaContainer = styled.div.attrs({
  className: 'ck-content',
})`
  width: 100%;
  font-size: 14px;
  conlor: #575757;
  border: 1px solid grey;
`;

const Input = styled.input`
  padding: 5px;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colors.blue.veryLight};
  background-color: ${({ theme }) => theme.colors.blue.veryLight};
  color: #575757;
  font-size: 14px;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
`;

const TextArea = styled.textarea`
  padding: 5px;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colors.blue.veryLight};
  background-color: ${({ theme }) => theme.colors.blue.veryLight};
  color: #575757;
  font-size: 14px;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
`;

const InputIcon = styled.div`
  font-size: 12px;
  margin-right: 12px;
  margin-top: 10px;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 4;
`;
const HorizontalInputContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 5px 0;
`;

const NoteContainer = styled.div`
  display: flex;
  width: 100%;
`;

const Note = styled.div`
  color: black;
  margin-top: 10px;
`;

const SelectUsersContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Info = styled.p`
  font-size: 14px;
  margin-top: 0;
  padding-left: 32px;
  margin-bottom: 10px;
`;

const AutocompleteDropDownContainer = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 4px;
  flex-wrap: wrap;
  flex: 1;
  margin-bottom: 10px;
`;

const CircleContainer = styled.div`
  margin-top: 12px;
  margin-left: 12px;
`;

const SubmittedTimeContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  padding: 12px 0;
  gap: 12px;
`;

const SelectedDateContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1em;
`;

const FooterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 10px;
  width: 100%;
`;

const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 8px;
  align-items: center;
  justify-content: center;
  padding: 16px;
`;

const CancelButton = styled(SecondaryButton)`
  height: auto;
  padding: 0.1em 3.3em;
  width: 9em;
  border-radius: 10em;
  font-size: 12px;
  text-transform: uppercase;
`;

const SaveButton = styled(PrimaryButton)`
  height: auto;
  padding: 0.1em 3.3em;
  width: 9em;
  border-radius: 10em;
  font-size: 12px;
  text-transform: uppercase;
`;

const ButtonDurationContainer = styled.div`
  display: flex;
  padding: 12px 0 14px 0;
  gap: 12px;
`;

const EditButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14em;
  padding: 0.4em 2.3em;
  height: 36px;
  border-radius: 10em;
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.blue.normal};
  border: solid 2px ${({ theme }) => theme.colors.blue.normal};
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
`;

const StyledDatePicker = styled(DatePicker)`
  &&& {
    margin-right: 0.4em;
    input {
      width: 75px;
      background-color: ${({ theme }) => theme.colors.blue.veryLight};
      border: 0;
      @media (max-width: 870px) {
        font-size: 0.8em;
        width: 64px;
      }
    }
  }
`;

const StyledTimePicker = styled(TimePicker)`
  &&& {
    input {
      width: 40px;
      background-color: ${({ theme }) => theme.colors.blue.veryLight};
      border: 0;
      @media (max-width: 870px) {
        font-size: 0.8em;
        width: 31px;
      }
    }
  }
`;

const StyledTo = styled.div`
  align-self: center;
  margin: 0em 0.4em;
`;
const DurationButton = styled.div<{ isActive: boolean }>`
  align-items: center;
  justify-content: center;
  padding: 0.4em 2.3em;
  height: 36px;
  width: 155px;
  border-radius: 10em;
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.blue.dark};
  border: solid 2px ${({ theme }) => theme.colors.blue.dark};
  font-size: 12px;
  cursor: pointer;

  ${({ isActive, theme }) =>
    isActive &&
    css`
      background: ${theme.colors.blue.dark};
      color: ${theme.colors.white};
    `}
`;
const FormatedP = styled.p`
  margin: 0;
  font-size: 1.2rem;
`;
