import { Comment, Discussion, Circle } from 'redux/types/account';
import { map } from 'ramda';
import { AxiosResponse } from 'axios';

import { CommentType, ImageType } from '../../redux/types/enums';
import { uploadFile } from './file-upload';
import { sanitizeAssetUrl } from '../../util/assets';
import { getAxiosInstance } from './helper';
import { mentionParse } from '../../util/mention';

const ai = getAxiosInstance();

export function fetchDiscussionsTags(
  entityId: number,
  entityType: string,
  bearer: string,
): Promise<{ entityId: number; entityType: string; tags: string[] }> {
  let url: string;
  let params: any = {};
  if (entityType === 'project') {
    url = '/api/Tag/GetProjectDiscussionTagNameList';
    params = { projectId: entityId };
  } else {
    url = 'api/Tag/GetCommunityDiscussionTagNameList';
    params = { communityId: entityId };
  }
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url,
      params: {
        skip: 0,
        take: 0,
        ...params,
      },
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        resolve({
          entityId,
          entityType,
          tags: response.data,
        });
      })
      .catch(err => reject(err));
  });
}

export function saveDiscussionTags(
  bearer: string,
  discussionId: number,
  discussionType: string,
  communityId: number,
  tags: string[],
): Promise<{ communityId: number; discussionId: number; discussionType: string; tags: string[] }> {
  return new Promise((resolve, reject) => {
    const url = discussionType === 'project' ? '/api/Discussion/SaveTags' : '/api/CommunityDiscussion/SaveTags';
    const data =
      discussionType === 'project'
        ? { discussionId, discussionTags: tags }
        : { communityDiscussionId: discussionId, communityDiscussionTags: tags };
    ai({
      method: 'POST',
      url,
      data,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        resolve({
          communityId,
          discussionId,
          discussionType,
          tags,
        });
      })
      .catch(err => reject(err));
  });
}

export function fetchDiscussionPrivacyCircles(
  bearer: string,
  entityId: number,
  entityType: string,
  discussionId: number,
  discussionPrivacyCircles?: Circle[],
): Promise<{ entityId: number; entityType: string; circles: Circle[]; discussionId: number }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url:
        entityType === 'project'
          ? `/api/Privacy/GetCustomCirclesByDiscussion`
          : `/api/Privacy/GetCommunityDiscussionPrivacyCustomCircles`,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      params:
        entityType === 'project'
          ? { discussionId, projectId: entityId }
          : { communityDiscussionId: discussionId, communityId: entityId },
    })
      .then((response: AxiosResponse) => {
        const { data } = response;
        // community discussions have additional metadata in the request

        /* Don't remove discussionPrivacyCircles?.includes here,
         this is check old database values which were not saved properly */

        let privacyCircles: Circle[] = [];
        if (entityType === 'project')
          privacyCircles = data
            .map((circles: any) => ({
              privacyCircles: circles.privacyCircles
                .filter((circle: any) => circle.isActive || discussionPrivacyCircles?.includes(circle.id))
                .map((circle: any) => ({
                  ...circle,
                  name: circle.longName,
                  shortName: circle.name,
                })),
            }))
            .reduce((acc: Circle[], circles: any) => [...acc, ...circles.privacyCircles], []);
        else
          privacyCircles = data.privacyCircles
            .filter((circle: any) => circle.isActive)
            .map((circle: any) => ({
              ...circle,
              name: circle.longName,
            }));
        resolve({
          entityId,
          entityType,
          discussionId,
          circles: privacyCircles,
        });
      })
      .catch(err => reject(err));
  });
}

export function fetchCustomCirclesByDiscussion(
  entityId: number,
  entityType: string,
  bearer: string,
): Promise<{ entityId: number; entityType: string; circles: Circle[] }> {
  const params: {
    discussionId: number;
    projectId?: number;
    communityId?: number;
  } = {
    discussionId: 0,
  };
  if (entityType === 'project') params.projectId = entityId;
  else params.communityId = entityId;

  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url:
        entityType === 'project'
          ? '/api/Privacy/GetCustomCirclesByDiscussion'
          : '/api/Privacy/GetCommunityDiscussionPrivacyCustomCircles',
      params: params,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        const { data } = response;
        const auxCircles: any[] = [];
        if (entityType === 'project')
          data.forEach((c1: any) => {
            c1.privacyCircles.forEach((c2: any) => {
              auxCircles.push({ ...c2, typeName: c1.name, isProject: c1.isProject, isCommunity: c1.isCommunity });
            });
          });
        else
          data.privacyCircles.forEach((c: any) => {
            auxCircles.push({ ...c, typeName: data.name, isProject: data.isProject, isCommunity: data.isCommunity });
          });
        resolve({
          entityId,
          entityType,
          circles: map(c => {
            return {
              id: c.id,
              name: c.longName,
              shortName: c.name,
              admin: c.name === 'Admins',
              projectId: c.projectId,
              communityId: c.communityId,
              type: {
                name: c.typeName,
                isProject: c.isProject,
                isCommunity: c.isCommunity,
              },
            };
          }, auxCircles),
        });
      })
      .catch(err => reject(err));
  });
}

export const convertComments = (comments: any): Comment[] => {
  if (!comments) return [];
  return map(c1 => {
    return {
      id: c1.id,
      author: {
        id: c1.authorId,
        name: c1.authorName,
        photo: c1.authorPhoto,
        occupation: c1.authorOccupation || '',
      },
      date: c1.lastUpdated,
      content: mentionParse(c1.content),
      parentId: c1.parentId,
      likeCount: c1.likeCount,
      isLiked: c1.userLikesComment,
      comments: convertComments(c1.comments),
    };
  }, comments);
};

export function updateDiscussionIsArchived(
  bearer: string,
  discussion: Discussion,
  isArchived: boolean,
): Promise<{ discussion: Discussion }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: '/api/Discussion/Save',
      data: {
        id: discussion.id,
        image: discussion.image,
        content: discussion.content,
        deadline: discussion.deadline,
        discussionSkillCategories: discussion.discussionSkillCategories,
        discussionTags: discussion.discussionTags,
        isActive: discussion.isActive,
        isArchived,
        isLockedCommentDiscussions: discussion.isLockedCommentDiscussions,
        name: discussion.name,
        privacyLevel: discussion.privacyLevel,
        projectId: discussion.projectId,
      },
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then(() => {
        resolve({
          discussion: {
            ...discussion,
            isArchived,
          },
        });
      })
      .catch(err => {
        reject(err);
      });
  });
}

export function deleteDiscussion(
  bearer: string,
  discussionId: number,
  entityId: number,
  entityType: string,
): Promise<{ discussionId: number; entityId: number; entityType: string }> {
  let url: string;
  if (entityType === 'project') url = '/api/Discussion/Remove';
  else url = 'api/CommunityDiscussion/Remove';
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url,
      params: {
        id: discussionId,
      },
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then(() => {
        resolve({ discussionId, entityId, entityType });
      })
      .catch(err => {
        reject(err);
      });
  });
}

export async function createNewDiscussion(
  discussion: Discussion,
  bearer: string,
  privacyCircles: Circle[],
  cover: File | string,
  discussionSkillCategories: string[],
  circleTags: string[],
): Promise<Discussion> {
  let image: string = discussion.image;
  if (cover) {
    const coverResponse: { id: number; link: string } = await uploadFile(
      bearer,
      {
        file: cover,
        folder: 'discussion_image',
      },
      ImageType.DiscussionImage,
    );
    image = coverResponse.link;
  }
  let url: string;
  let discussionPrivacyCircles: any[];
  if (discussion.projectId && discussion.projectId !== 0) {
    url = '/api/Discussion/Save';
    discussionPrivacyCircles = privacyCircles.map((c: Circle) => c.id);
  } else {
    url = 'api/CommunityDiscussion/Save';
    discussionPrivacyCircles = privacyCircles.map((c: Circle) => ({
      projectId: 0,
      communityId: c.communityId,
      circleId: c.id,
    }));
  }
  const response = await ai({
    method: 'POST',
    url,
    data: {
      id: discussion.id || 0,
      projectId: discussion.projectId,
      communityId: discussion.communityId,
      image,
      name: discussion.name,
      content: discussion.content,
      deadline: 5,
      isActive: true,
      discussionTags: discussion.discussionTags,
      discussionSkillCategories,
      privacyLevel: discussion.privacyLevel,
      discussionPrivacyCircles: discussionPrivacyCircles,
      circleTags: circleTags,
    },
    headers: {
      Authorization: `Bearer ${bearer}`,
    },
  });
  const newDiscussions = response.data;

  return {
    id: newDiscussions.id,
    projectId: discussion.projectId,
    communityId: discussion.communityId,
    name: newDiscussions.name,
    content: newDiscussions.content,
    commentCount: newDiscussions.commentCount,
    image: newDiscussions.image,
    postDate: newDiscussions.postDate,
    lastUpdated: newDiscussions.lastUpdated,
    deadlineDate: newDiscussions.deadlineDate,
    deadline: newDiscussions.deadline,
    daysLeft: newDiscussions.daysLeft,
    paragraphId: newDiscussions.paragraphId,
    isActive: newDiscussions.isActive,
    userId: newDiscussions.userId,
    authorName: newDiscussions.authorName,
    authorPhoto: newDiscussions.authorPhoto,
    discussionTags: newDiscussions.discussionTags,
    discussionSkillCategories: newDiscussions.discussionSkillCategories,
    isRelatedWithMethodology: newDiscussions.isRelatedWithMethodology,
    isArchived: newDiscussions.isArchived,
    allowLockComments: newDiscussions.allowLockComments,
    isLockedCommentDiscussions: newDiscussions.isLockedCommentDiscussions,
    privacyLevel: newDiscussions.privacyLevel,
    privacyCircles: privacyCircles,
    comments: convertComments(newDiscussions.comments),
    isFollowing: newDiscussions.isFollowedByMe,
    communityMembersTags: newDiscussions.communityMembersTags,
  };
}

export function fetchDiscussion(
  discussionId: number,
  bearer: string,
  entityId: number,
  entityType: string,
): Promise<{
  entityId: number;
  entityType: string;
  discussion: Discussion;
  canEditDiscussions: boolean;
  canEditPrivacy: boolean;
}> {
  return new Promise((resolve, reject) => {
    let url: string;
    if (entityType === 'project') url = '/api/BusinessModelContent/GetDiscussionById';
    else url = 'api/CommunityDiscussion/GetDiscussionById';
    ai({
      method: 'GET',
      url,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      params: {
        id: discussionId,
      },
    })
      .then((response: AxiosResponse) => {
        const { data } = response;
        resolve({
          entityId,
          entityType,
          canEditDiscussions: data.canEditDiscussions,
          canEditPrivacy: data.canEditPrivacy,
          discussion: {
            id: data.id,
            projectId: data.projectId,
            communityId: data.communityId,
            name: data.name,
            content: mentionParse(data.content),
            commentCount: data.commentCount,
            image: sanitizeAssetUrl(data.image),
            postDate: data.postDate,
            lastUpdated: data.lastUpdated,
            deadlineDate: data.deadlineDate,
            deadline: data.deadline,
            daysLeft: data.daysLeft,
            paragraphId: data.paragraphId,
            isActive: data.isActive,
            userId: data.userId,
            authorName: data.authorName,
            authorPhoto: data.authorPhoto,
            discussionTags: data.discussionTags,
            discussionSkillCategories: data.discussionSkillCategories,
            isRelatedWithMethodology: data.isRelatedWithMethodology,
            readOnly: data.readOnly,
            isArchived: data.isArchived,
            allowLockComments: data.allowLockComments,
            isLockedCommentDiscussions: data.isLockedCommentDiscussions,
            privacyLevel: data.privacyLevel,
            comments: convertComments(data.comments),
            isFollowing: data.isFollowedByMe,
            followersCount: data.followersCount,
            discussionUserFollows: data.discussionUserFollows,
            discussionPrivacyCircles: data?.discussionPrivacyCircles,
            communityMembersTags: data.communityMembersTags,
            circleTags: data.circleTags,
          },
        });
      })
      .catch(err => reject(err));
  });
}

export function addCommentToDiscussion(
  bearer: string,
  userId: number,
  itemId: number,
  content: string,
  parentId: number,
  itemType: CommentType,
  entityId: number,
  entityType: string,
  discussionId: number,
): Promise<{ discussionId: number; entityId: number; entityType: string; comment: Comment }> {
  return new Promise((resolve, reject) => {
    let url: string;
    if (entityType === 'project') url = '/api/Comment/SaveDiscussionComment';
    else url = 'api/Comment/SaveCommunityDiscussionComment';
    const postData: any = {
      userId,
      content,
      parentId,
      discussionId: entityType === 'project' && itemId,
      communityDiscussionId: entityType === 'community' && itemId,
    };
    if (parentId === itemId) delete postData.parentId;
    ai({
      method: 'POST',
      url,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      data: postData,
    })
      .then((response: AxiosResponse) => {
        const { data } = response;
        const res = {
          entityId,
          entityType,
          discussionId,
          comment: {
            id: data.id,
            author: {
              id: data.authorId,
              name: data.authorName,
              photo: sanitizeAssetUrl(data.authorPhoto),
              occupation: data.authorOccupation || '',
            },
            date: data.lastUpdated,
            content: mentionParse(data.content),
            parentId: data.parentId,
            likeCount: data.likeCount,
            isLiked: data.userLikesComment,
            comments: [],
          },
        };
        resolve(res);
      })
      .catch(err => reject(err));
  });
}

export function fetchDiscussionTags(
  communityId: number,
  bearer: string,
): Promise<{ discussionTags: string[]; communityId: number }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/Tag/GetProjectDiscussionTagNameListByCommunity',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      params: {
        communityId,
      },
    })
      .then((response: AxiosResponse) => {
        const { data } = response;
        const res = {
          discussionTags: data,
          communityId,
        };
        resolve(res);
      })
      .catch(err => reject(err));
  });
}

export function getCountByDiscussionSkillCategory(
  projectId: number,
  discussionId: number,
  discussionSkillCategories: string[],
  bearer: string,
): Promise<number> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: '/api/UserSkill/GetCountByDiscussionSkillCategory',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      data: {
        projectId,
        discussionId,
        discussionSkillCategories,
      },
    })
      .then((response: AxiosResponse) => {
        const { data } = response;
        resolve(data);
      })
      .catch(err => reject(err));
  });
}

export function followDiscussion(
  bearer: string,
  entityType: string,
  entityId: number,
  discussionId: number,
): Promise<{ entityType: string; entityId: number; discussionId: number }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: entityType === 'community' ? '/api/CommunityDiscussion/Follow' : '/api/Discussion/Follow',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      params: {
        id: discussionId,
      },
    })
      .then((response: AxiosResponse) => {
        resolve({
          entityType,
          entityId,
          discussionId,
        });
      })
      .catch(err => reject(err));
  });
}

export function unfollowDiscussion(
  bearer: string,
  entityType: string,
  entityId: number,
  discussionId: number,
): Promise<{ entityType: string; entityId: number; discussionId: number }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: entityType === 'community' ? '/api/CommunityDiscussion/Unfollow' : '/api/Discussion/Unfollow',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      params: {
        id: discussionId,
      },
    })
      .then((response: AxiosResponse) => {
        resolve({
          entityType,
          entityId,
          discussionId,
        });
      })
      .catch(err => reject(err));
  });
}

export default {
  fetchDiscussionsTags,
  saveDiscussionTags,
  createNewDiscussion,
  fetchDiscussionPrivacyCircles,
  fetchCustomCirclesByDiscussion,
  updateDiscussionIsArchived,
  deleteDiscussion,
  fetchDiscussion,
  addCommentToDiscussion,
  fetchDiscussionTags,
  getCountByDiscussionSkillCategory,
  followDiscussion,
  unfollowDiscussion,
};
