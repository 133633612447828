import { combineReducers } from 'redux';

import { submitPendingFeedbackFlag, fetchRequestsPendingFeedbackFlag, saveCoachingSessionFlag } from './flags/request';
import {
  fetchCurrentUserFlag,
  fetchNotificationsFlag,
  markNotificationAsReadFlag,
  markAllNotificationsAsReadFlag,
  fetchMyStuffFlag,
  fetchNotificationSettingsFlag,
  saveNotificationSettingsFlag,
  toggleNotificationsFlag,
  saveProfileFlag,
  fetchSkillsFlag,
  reacceptTermsAndConditionsFlag,
  fetchCountriesFlag,
  fetchProjectCategoriesFlag,
  fetchSDGsFlag,
} from './account';

import {
  fetchDiscussionFlag,
  addCommentToDiscussionFlag,
  fetchDiscussionTagsFlag,
  saveDiscussionTagsFlag,
  fetchCommunityFeedFlag,
  addCommunityPostFlag,
  addCommentToFeedFlag,
  likeFeedPostFlag,
  unlikeFeedPostFlag,
  fetchMethodologyContentFlag,
  updateMethodologyAssignmentFlag,
  deleteMethodologySectionFlag,
  deleteMethodologyAssignmentFlag,
  reorderMethodologySectionsFlag,
  reorderMethodologyAssignmentsFlag,
  saveMethodologySectionFlag,
  saveMethodologyAssignmentFlag,
  fetchMethodologyPlanningFlag,
  createTasksFlag,
  updateTasksFlag,
  updateTasksOrderFlag,
  followUserFlag,
  unfollowUserFlag,
  uploadFileFlag,
  addCommentToBusinessModelParagraphFlag,
  fetchCommunitiesFlag,
  fetchCommunityDetailsFlag,
  fetchCommunityMembersFlag,
  fetchCommunityResourcesFlag,
  createResourceFlag,
  deleteResourceFlag,
  fetchCommunityDiscussionsFlag,
  fetchCommunityRequestsFlag,
  updateCommunityInfoFlag,
  fetchCommunityProjectsFlag,
  acceptCommunityProjectFlag,
  denyCommunityProjectFlag,
  fetchCommunityCirclesFlag,
  inviteCommunityMembersFlag,
  fetchResourceCirclesFlag,
  fetchResourcesTagsFlag,
  fetchCommunityMembersCountriesFlag,
  fetchCommunityMembersSkillsFlag,
  fetchCommunityMembersInterestsFlag,
  setAllowTasksFlag,
  fetchProjectsAwaitingFeedbackFlag,
  fetchCommunityProjectsCountriesFlag,
  fetchCommunityProjectsTagsFlag,
  fetchProjectsNextParagraphsFlag,
  saveCommunityProjectsTagsFlag,
  saveCommunityCircleFlag,
  saveCommunityCirclesFlag,
  deleteCommunityCircleFlag,
  editCommunityCircleFlag,
  removeUserFromCommunityFlag,
  fetchCommunityProgressTrackingFlag,
  searchCommunityProgressTrackingFlag,
  upsertKPIFlag,
  deleteKPIFlag,
  fetchKPIFlag,
  downloadKPIFlag,
  fetchCommunityCalendarEventsFlag,
  fetchCommunityCalendarEventDetailsFlag,
  deleteCommunityCalendarEventFlag,
  upsertCommunityCalendarEventFlag,
  removeCommunityInvitationFlag,
  respondCommunityJoinRequestFlag,
  deleteTasksFlag,
  fetchAllCommunityRequestsFlag,
  followDiscussionFlag,
  unfollowDiscussionFlag,
  setTaskUnlockRuleFlag,
  saveMethodologyDiffFlag,
  saveSectionsStagegateFlag,
  fetchCommunityMembersByCircleFlag,
  editTabEntityCircleFlag,
  fetchCommunityProjectFormFlag,
  updateCommunityProjectFormFlag,
  deleteCommunityReportFlag,
  deleteApplicationEntriesFlag,
  editCommunityTagsFlag,
  deleteCommunityTagsFlag,
  editCommunityProjectTagsFlag,
  deleteCommunityProjectTagsFlag,
  saveCommunityMembersTagsFlag,
  updateProgramNameFlag,
  deleteMethodologyFlag,
  updateApplicationEntryStatusFlag,
  getLiteralsFlag,
} from './flags/community-flags';

import {
  fetchCirclesByDiscussionsFlag,
  fetchDiscussionsTagsFlag,
  createNewDiscussionFlag,
  updateDiscussionIsArchivedFlag,
  fetchProjectFeedFlag,
  addProjectPostFlag,
  fetchProjectByIdFlag,
  fetchProjectOverviewFlag,
  fetchProjectMembersFlag,
  fetchProjectResourcesFlag,
  fetchProjectResourcesTagsFlag,
  fetchProjectDiscussionsFlag,
  fetchProjectCirclesFlag,
  inviteProjectMembersFlag,
  applyToTeamProjectFlag,
  createProjectFlag,
  updateProjectFlag,
  removeProjectFlag,
  updateProjectLogoFlag,
  updateProjectCoverFlag,
  saveProjectCircleFlag,
  saveProjectCirclesFlag,
  deleteProjectCircleFlag,
  editProjectCircleFlag,
  removeUserFromProjectFlag,
  followProjectFlag,
  unfollowProjectFlag,
  saveProjectDiscussionTagsFlag,
  fetchBusinessModelByProjectIdFlag,
  fetchProjectTasksFlag,
  saveProjectTasksFlag,
  fetchBusinessModelPrivacyCirclesFlag,
  fetchCommentsForBusinessModelParagraphFlag,
  fetchProjectProgressDetailsFlag,
  saveKpiValuesFlag,
  respondProjectJoinRequestFlag,
  removeProjectInvitationFlag,
  fetchPendingKpiFlag,
  deleteDiscussionFlag,
  addCommentToRequestFlag,
  fetchProjectRequestsFlag,
  saveRequestFlag,
  saveSectionStagegateLockedFlag,
  toggleApprovedAssignmentFlag,
  fetchProjectMembersByCircleFlag,
  fetchProjectKPIDefinitionFlag,
  upsertProjectKPIFlag,
  deleteProjectKPIFlag,
  fetchProjectRequestFeedbackListFlag,
  editProjectTagsFlag,
  deleteProjectTagsFlag,
  scheduleRequestSessionFlag,
} from './project';

import { fetchProjectSearchOverviewFlag } from './project-overview';

import { fetchRequestFlag, updateRequestFlag } from './request';

import { loginFlag } from './session';

import { fetchUserProfileFlag } from './users';

import {
  saveApplicationDefinitionFlag,
  fetchCommunityApplicationDefinitionsFlag,
  deleteApplicationDefinitionFlag,
  fetchApplicationEntryByIdFlag,
  saveApplicationEntryFlag,
  saveApplicationEntryTagsFlag,
  saveApplicationEntryScoresFlag,
  fetchApplicationEntryScoresFlag,
  fetchApplicationEntriesByDefinitionIdFlag,
  fetchEvaluationAnswersByApplicationEntryIdFlag,
  saveCommentToApplicationEntryFlag,
  fetchApplicationDefinitionOverviewByIdFlag,
} from './application-manager';

import { saveFormFlag, fetchCommunityFormsFlag, deleteFormFlag } from './form';

import {
  saveTabFlag,
  fetchCommunityTabsFlag,
  deleteTabFlag,
  fetchTabByIdFlag,
  fetchTabEntitiesByIdFlag,
  followOrUnFollowEntityFlag,
  inviteTabEntityMembersFlag,
  fetchTabEntityCommentsFlag,
  fetchCommunityTabEntityCirclesFlag,
  fetchCommunityTabEntityCircleMembersFlag,
} from './flags/community-tabs-flags';
import {
  fetchMeetingsSettingsFlag,
  getUserEventsByMonthFlag,
  saveMeetingsSettingsFlag,
  calendarLogInGoogleFlag,
  calendarLogInOutlookFlag,
  calendarLogOutGoogleFlag,
  calendarLogOutOutlookFlag,
  setUserCalendarGoogleFlag,
  setUserCalendarOutlookFlag,
  meetingProviderSyncFlag,
  meetingProviderUnSyncFlag,
} from './calendar';

const loadingReducer = combineReducers({
  meetingProviderSyncFlag,
  meetingProviderUnSyncFlag,
  addCommentToRequestFlag,
  calendarLogInGoogleFlag,
  calendarLogInOutlookFlag,
  calendarLogOutGoogleFlag,
  calendarLogOutOutlookFlag,
  setUserCalendarGoogleFlag,
  setUserCalendarOutlookFlag,
  fetchRequestFlag,
  updateRequestFlag,
  fetchProjectRequestFeedbackListFlag,
  fetchCurrentUserFlag,
  fetchNotificationsFlag,
  markNotificationAsReadFlag,
  markAllNotificationsAsReadFlag,
  fetchMyStuffFlag,
  deleteDiscussionFlag,
  fetchNotificationSettingsFlag,
  saveNotificationSettingsFlag,
  toggleNotificationsFlag,
  saveProfileFlag,
  fetchDiscussionFlag,
  addCommentToDiscussionFlag,
  fetchDiscussionTagsFlag,
  saveDiscussionTagsFlag,
  fetchCommunityFeedFlag,
  addCommunityPostFlag,
  addCommentToFeedFlag,
  likeFeedPostFlag,
  unlikeFeedPostFlag,
  fetchMethodologyContentFlag,
  updateMethodologyAssignmentFlag,
  deleteMethodologySectionFlag,
  deleteMethodologyAssignmentFlag,
  reorderMethodologySectionsFlag,
  reorderMethodologyAssignmentsFlag,
  saveMethodologySectionFlag,
  saveKpiValuesFlag,
  saveMethodologyAssignmentFlag,
  fetchMethodologyPlanningFlag,
  createTasksFlag,
  updateTasksFlag,
  updateTasksOrderFlag,
  followUserFlag,
  unfollowUserFlag,
  addCommentToBusinessModelParagraphFlag,
  fetchCommunitiesFlag,
  fetchCommunityDetailsFlag,
  fetchCommunityMembersFlag,
  fetchCommunityResourcesFlag,
  createResourceFlag,
  deleteResourceFlag,
  fetchCommunityDiscussionsFlag,
  fetchCommunityRequestsFlag,
  fetchAllCommunityRequestsFlag,
  fetchProjectRequestsFlag,
  updateCommunityInfoFlag,
  fetchCommunityProjectsFlag,
  acceptCommunityProjectFlag,
  denyCommunityProjectFlag,
  fetchCommunityCirclesFlag,
  inviteCommunityMembersFlag,
  fetchResourceCirclesFlag,
  fetchResourcesTagsFlag,
  fetchCommunityMembersCountriesFlag,
  fetchCommunityMembersSkillsFlag,
  fetchCommunityMembersInterestsFlag,
  setAllowTasksFlag,
  fetchProjectsAwaitingFeedbackFlag,
  fetchCommunityProjectsCountriesFlag,
  fetchCommunityProjectsTagsFlag,
  fetchProjectsNextParagraphsFlag,
  saveCommunityProjectsTagsFlag,
  saveCommunityCircleFlag,
  saveCommunityCirclesFlag,
  deleteCommunityCircleFlag,
  editCommunityCircleFlag,
  removeUserFromCommunityFlag,
  fetchCommunityProgressTrackingFlag,
  searchCommunityProgressTrackingFlag,
  uploadFileFlag,
  fetchCirclesByDiscussionsFlag,
  fetchDiscussionsTagsFlag,
  createNewDiscussionFlag,
  updateDiscussionIsArchivedFlag,
  fetchProjectFeedFlag,
  addProjectPostFlag,
  fetchProjectByIdFlag,
  fetchProjectOverviewFlag,
  fetchProjectSearchOverviewFlag,
  fetchProjectMembersFlag,
  fetchProjectResourcesFlag,
  fetchProjectResourcesTagsFlag,
  fetchProjectDiscussionsFlag,
  fetchProjectCirclesFlag,
  inviteProjectMembersFlag,
  applyToTeamProjectFlag,
  createProjectFlag,
  updateProjectFlag,
  removeProjectFlag,
  updateProjectLogoFlag,
  updateProjectCoverFlag,
  saveProjectCircleFlag,
  saveProjectCirclesFlag,
  deleteProjectCircleFlag,
  editProjectCircleFlag,
  removeUserFromProjectFlag,
  followProjectFlag,
  unfollowProjectFlag,
  saveProjectDiscussionTagsFlag,
  fetchBusinessModelByProjectIdFlag,
  fetchProjectTasksFlag,
  saveProjectTasksFlag,
  fetchBusinessModelPrivacyCirclesFlag,
  fetchCommentsForBusinessModelParagraphFlag,
  loginFlag,
  fetchUserProfileFlag,
  upsertKPIFlag,
  deleteKPIFlag,
  fetchKPIFlag,
  deleteProjectKPIFlag,
  downloadKPIFlag,
  fetchCommunityCalendarEventsFlag,
  fetchCommunityCalendarEventDetailsFlag,
  deleteCommunityCalendarEventFlag,
  upsertCommunityCalendarEventFlag,
  fetchProjectProgressDetailsFlag,
  fetchSkillsFlag,
  removeCommunityInvitationFlag,
  respondCommunityJoinRequestFlag,
  respondProjectJoinRequestFlag,
  removeProjectInvitationFlag,
  fetchPendingKpiFlag,
  deleteTasksFlag,
  followDiscussionFlag,
  unfollowDiscussionFlag,
  saveRequestFlag,
  setTaskUnlockRuleFlag,
  reacceptTermsAndConditionsFlag,
  fetchCountriesFlag,
  fetchProjectCategoriesFlag,
  fetchSDGsFlag,
  saveSectionsStagegateFlag,
  saveSectionStagegateLockedFlag,
  saveMethodologyDiffFlag,
  saveApplicationDefinitionFlag,
  fetchCommunityApplicationDefinitionsFlag,
  deleteApplicationDefinitionFlag,
  saveFormFlag,
  fetchCommunityFormsFlag,
  deleteFormFlag,
  fetchApplicationEntryByIdFlag,
  saveApplicationEntryFlag,
  saveApplicationEntryTagsFlag,
  saveApplicationEntryScoresFlag,
  fetchApplicationEntryScoresFlag,
  updateApplicationEntryStatusFlag,
  fetchApplicationEntriesByDefinitionIdFlag,
  fetchEvaluationAnswersByApplicationEntryIdFlag,
  saveCommentToApplicationEntryFlag,
  toggleApprovedAssignmentFlag,
  fetchCommunityMembersByCircleFlag,
  fetchProjectMembersByCircleFlag,
  saveTabFlag,
  fetchCommunityTabsFlag,
  deleteTabFlag,
  fetchTabByIdFlag,
  fetchTabEntitiesByIdFlag,
  followOrUnFollowEntityFlag,
  inviteTabEntityMembersFlag,
  fetchTabEntityCommentsFlag,
  fetchCommunityTabEntityCirclesFlag,
  fetchCommunityProjectFormFlag,
  updateCommunityProjectFormFlag,
  fetchCommunityTabEntityCircleMembersFlag,
  editTabEntityCircleFlag,
  submitPendingFeedbackFlag,
  fetchRequestsPendingFeedbackFlag,
  fetchProjectKPIDefinitionFlag,
  upsertProjectKPIFlag,
  deleteCommunityReportFlag,
  deleteApplicationEntriesFlag,
  fetchApplicationDefinitionOverviewByIdFlag,
  editCommunityTagsFlag,
  deleteCommunityTagsFlag,
  editProjectTagsFlag,
  deleteProjectTagsFlag,
  editCommunityProjectTagsFlag,
  deleteCommunityProjectTagsFlag,
  fetchMeetingsSettingsFlag,
  saveMeetingsSettingsFlag,
  getUserEventsByMonthFlag,
  saveCommunityMembersTagsFlag,
  saveCoachingSessionFlag,
  scheduleRequestSessionFlag,
  updateProgramNameFlag,
  deleteMethodologyFlag,
  getLiteralsFlag,
});

export default loadingReducer;
