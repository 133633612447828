import { MultipleMeetingSettings } from 'services/types/user-calendar';
import { getAxiosInstance } from './helper';

const ai = getAxiosInstance();

export function fetchMultipleMeetingSettings(
  bearer: string,
  usersId: number[],
): Promise<{ multipleMeetingSettings: MultipleMeetingSettings[] }> {
  const usersIdParamURL = usersId?.map((id: number) => `userIds=${id}`).join('&');

  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: `/api/UserCalendar/GetMultipleMeetingSettings?${usersIdParamURL}`,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then(response => {
        resolve({
          multipleMeetingSettings: response.data,
        });
      })
      .catch(err => reject(err));
  });
}

export default {
  fetchMultipleMeetingSettings,
};
