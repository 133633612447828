import { EmailInvitationDto, UserCircleInvitationDto } from 'ApiModels';
import { AxiosError, AxiosPromise, AxiosResponse } from 'axios';
import moment, { Moment } from 'moment';
import { filter, map } from 'ramda';
import {
  Circle,
  Comment,
  Discussion,
  Filtering,
  KPIList,
  KpiDefinition,
  KpiValue,
  Member,
  NewProject,
  Project,
  ProjectProgressDetails,
  Request,
  RequestFilteringSkill,
  Resource,
  SaveRequest,
  Sorting,
  Tag,
  TagType,
  SaveCoachingSession,
  CommunityRequestsFilters,
  InvitedAdvisor,
} from 'redux/types/account';
import {
  GlobalAllOptions,
  ImageType,
  PrivacyCircleType,
  RequestStatusEnum,
  Response,
  UserInvitationPolicy,
} from '../../redux/types/enums';
import { ResourceMeta } from '../../routes/project/new-request';
import { sanitizeAssetUrl } from '../../util/assets';
import { mentionParse } from '../../util/mention';
import { customSortArrayOfObjects, isNoFiltering } from '../../util/utils';
import { createResources, deleteResources, inviteCommunityMembers } from './community';
import { uploadFile } from './file-upload';
import { buildHeaders, getAxiosInstance, getResourceType, getSDGList } from './helper';

const ai = getAxiosInstance();

export function fetchProjectById(bearer: string, id: number): Promise<Project> {
  return new Promise((resolve, reject) => {
    const SDGs = getSDGList();
    ai({
      method: 'GET',
      url: '/api/Project/GetOverviewSummary',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      params: { id },
    })
      .then(response => {
        resolve({
          id: response.data.id,
          canEditPrivacy: false,
          name: response.data.name,
          description: response.data.description,
          logo: sanitizeAssetUrl(response.data.logoImage),
          cover: sanitizeAssetUrl(response.data.mainImageOrVideo),
          SDGs: response.data.projectSustainableDevelopmentGoals.map((g: any) => {
            const sdg = filter(remoteGoal => remoteGoal.id === g.sustainableDevelopmentGoalId, SDGs)[0];
            return {
              id: sdg.id,
              name: sdg.name,
              iconName: sdg.iconName,
            };
          }),
          tags: response.data.projectTags,
          country: response.data.countryName,
          city: response.data.city,
          category: response.data.categoryName,
          stage: response.data.developmentStage,
          isFollowing: response.data.isFollowing,
          websiteUrl: response.data.websiteUrl,
          twitterUrl: response.data.twitterUrl,
          facebookUrl: response.data.facebookUrl,
          googleUrl: response.data.googleUrl,
          linkedInUrl: response.data.linkedInUrl,
          flickrUrl: response.data.flickrUrl,
          canEditProjectInfo: response.data.canEditProjectInfo,
          allowLockComments: response.data.allowLockComments,
          categoryId: response.data.categoryId,
          unGoalId: response.data.unGoalId,
          projectModel: response.data.projectModel,
          projectPrivacy: response.data.privacy,
          taggedEntityId: response.data.taggedEntityId,
          countryId: response.data.countryId,
          communityIds: response.data.communityIds,
          form: response.data.form,
          answers: response.data.answers || [],
          mainCommunityId: response.data.mainCommunityId,
          discussionCount: response.data.discussionCount,
          viewCount: response.data.viewCount,
          networkCount: response.data.networkCount,
          businessModel: {
            canEditBusinessModel: false,
            sections: [],
            customPrivacyCircles: [],
          },
          userInvitationPolicy: response.data.userInvitationPolicy || UserInvitationPolicy.AnyoneViaEmailOrSearch,
          isCommunityAdmin: response.data.isCommunityAdmin,
          canEditProjectTeam: response.data.canEditProjectTeam,
          projectCommunityCircleList: response.data.projectCommunityCircleList,
          projectCommunityUserCircleTagList: response.data.projectCommunityUserCircleTagList,
        });
      })
      .catch(err => {
        reject(err);
      });
  });
}

const mapResponseToRequest = (response: AxiosResponse): Request => {
  const request: Request = {
    id: response.data.id,
    name: response.data.name,
    description: mentionParse(response.data.description),
    calendarEventId: response.data.calendarEventId,
    userId: response.data.userId,
    userFirstName: response.data.userFirstName,
    userLastName: response.data.userLastName,
    userMail: response.data.userMail,
    userPhoto: response.data.userPhoto,
    requestStatus: response.data.requestStatus,
    tags: response.data.tags,
    projectId: response.data.projectId,
    projectLogoUrl: response.data.projectLogoUrl,
    projectName: response.data.projectName,
    commentCount: response.data.commentCount,
    matchMakingScore: response.data.matchMakingScore,
    recommended: response.data.recommended,
    requestType: response.data.requestType,
    requestResources: map(res => {
      return {
        id: res.id,
        name: res.name,
        url: res.resourceUrl,
      };
    }, response.data.requestResources || []),
    canInviteAdvisor: response.data.canInviteAdvisor,
    invitedAdvisors: response.data.requestFeedbacks.map(
      (item: {
        advisorId: number;
        advisorStatus: number;
        advisorPhotoUrl: string;
        advisorFirstName: string;
        advisorLastName: string;
        eventDateTimeStart: string;
        eventId: string;
      }) => ({
        id: item.advisorId,
        name: item.advisorFirstName + ' ' + item.advisorLastName,
        photo: item.advisorPhotoUrl,
        status: item.advisorStatus,
        eventDate: moment(item.eventDateTimeStart),
        eventId: item.eventId,
      }),
    ),
    requestIndustryIds: response.data.requestCategories.map((category: any) => category.categoryId),
    details: {
      postDate: response.data.postDate,
      lastUpdated: response.data.lastUpdated,
      privacyLevel: response.data.privacyLevel,
      requestPrivacyCircles: response.data.requestPrivacyCircles,
      requestSkills: response.data.requestSkills,
      requestMarketExpertises: response.data.requestMarketExpertises,
      comments: response.data.comments?.map((c: any) => {
        return {
          id: c.id,
          type: c.itemType,
          date: c.lastUpdated,
          commentCount: c.commentCount,
          likeCount: c.likeCount,
          isLiked: c.userLikesComment,
          content: mentionParse(c.content),
          comments: c.comments.map((c1: any) => ({
            id: c1.id,
            type: c.itemType,
            date: c1.lastUpdated,
            commentCount: c1.commentCount,
            likeCount: c1.likeCount,
            isLiked: c1.userLikesComment,
            content: mentionParse(c1.content),
            comments: c1.comments,
            author: {
              id: c1.authorId,
              name: c1.authorName,
              photo: sanitizeAssetUrl(c1.authorPhoto),
              occupation: c1.authorOccupation || '',
            },
          })),
          author: {
            id: c.authorId,
            name: c.authorName,
            photo: sanitizeAssetUrl(c.authorPhoto),
            occupation: c.authorOccupation || '',
          },
        };
      }),
      awaitingAdvisorFeedback: response.data.awaitingAdvisorFeedback || false,
      awaitingEntrepreneurFeedback: response.data.awaitingEntrepreneurFeedback || false,
      advisorId: response.data.advisorId,
      entrepreneurId: response.data.entrepreneurId,
      attendees: (response.data.userAttendees || []).map((attendee: any) => ({
        name: `${attendee.firstName} ${attendee.lastName}`,
        id: attendee.id,
      })),
      sessionLink: response.data.zoomLink,
      startDate: response.data.startDate,
    },
    notifiedUsers: (response.data?.notifiedUsers || []).map((user: any) => ({
      name: `${user.firstName} ${user.lastName}`,
      id: user.id,
      photo: user.photoUrl,
    })),
  };
  // @ts-ignore
  Object.keys(request).forEach((key: string) => request[key] === undefined && delete request[key]);
  return request;
};

export function fetchRequestById(bearer: string, id: number): Promise<{ request: Request }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: `/api/Request/${id}`,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then(response => {
        const request = mapResponseToRequest(response);
        resolve({
          request,
        });
      })
      .catch(err => reject(err));
  });
}

export function updateRequestTagsById(
  bearer: string,
  id: number,
  tags: Array<string>,
  projectId: number,
): Promise<{ tags: Array<string>; requestId: number; projectId: number }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: `/api/Request/${id}/Tag`,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      data: {
        tags,
      },
    })
      .then(response => {
        resolve({
          tags,
          requestId: id,
          projectId,
        });
      })
      .catch(err => reject(err));
  });
}

export function fetchRequestsTags(
  projectId: number,
  bearer: string,
): Promise<{ requestsTags: string[]; projectId: number }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/Tag/GetProjectRequestTagNameList',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      params: {
        projectId,
      },
    })
      .then(response => {
        const { data } = response;
        const res = {
          requestsTags: data.filter((tag: string, index: number, arr: string[]) => arr.indexOf(tag) === index),
          projectId,
        };
        resolve(res);
      })
      .catch(err => reject(err));
  });
}

export function fetchProjectFilteringSkills(
  bearer: string,
  projectId: number,
  communityId: number,
): Promise<{
  projectId: number;
  skills: RequestFilteringSkill[];
}> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: `/api/SkillCategory/GetByProjectRequests`,
      params: {
        projectId,
      },
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then(response => {
        resolve({
          projectId,
          skills: response.data.sort(
            (skill1: RequestFilteringSkill, skill2: RequestFilteringSkill) => skill1.id - skill2.id,
          ),
        });
      })
      .catch(err => reject(err));
  });
}

export function fetchProjectRequests(
  projectId: number,
  bearer: string,
  sorting: Sorting,
  filtering: Filtering,
  skip: number,
  take: number,
): Promise<{
  list: Request[];
  canCreateRequests: boolean;
  skip: number;
  take: number;
  projectId: number;
  hasPendingRequestFeedback: boolean;
  hasDefaultCircles: boolean;
  count: number;
  totalRequestsCount?: number;
  requestFilter?: CommunityRequestsFilters;
}> {
  return new Promise((resolve, reject) => {
    const data = {
      ...filtering,
      ...sorting,
      take,
      skip,
    };
    if (sorting.orderBy) data.orderBy = sorting.orderBy - 1;

    if (sorting.orderBy) data.orderBy = sorting.orderBy - 1;

    ai({
      method: 'POST',
      url: `/api/Project/${projectId}/requests`,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      data: data,
    })
      .then((response: AxiosResponse) => {
        const { data } = response;
        const filteredList = filter(
          (r: any) => (filtering.requestType !== undefined ? filtering.requestType === r.requestType : true),
          data.list,
        );
        resolve({
          projectId,
          skip,
          take,
          count: filtering.requestType !== undefined ? filteredList.length : data.count,
          totalRequestsCount: isNoFiltering(filtering) ? data.count : undefined,
          canCreateRequests: data.canCreateRequests,
          hasPendingRequestFeedback: data.hasPendingRequestFeedback,
          hasDefaultCircles: data.hasDefaultCircles,
          list: map((r: any): Request => {
            return {
              ...r,
              id: r.id,
              name: r.name,
              description: r.description,
              userId: r.userId,
              userFirstName: r.userFirstName,
              userLastName: r.userLastName,
              userMail: r.userMail,
              userPhoto: r.userPhoto,
              requestStatus: r.requestStatus,
              tags: r.tags,
              projectId: r.projectId,
              projectLogoUrl: r.projectLogoUrl,
              projectName: r.projectName,
              commentCount: r.commentCount,
              matchMakingScore: r.matchMakingScore,
              canInviteAdvisor: r.canInviteAdvisor,
              recommended: r.recommended,
              requestType: r.requestType,
              requestResources: r.resources.map((resource: any) => ({
                id: resource.id,
                name: resource.name,
                url: resource.resourceUrl,
              })),
              invitedAdvisors: [],
              requestIndustryIds: r.industry,
            };
          }, filteredList),
          requestFilter: data.requestFilter,
        });
      })
      .catch(err => {
        if (err?.response?.data?.value?.ClassName === 'System.NullReferenceException') {
          reject(new Error('hideErrorToast'));
        }
        reject(err);
      });
  });
}

export function fetchProjectOverview(bearer: string, id: number): Promise<Project> {
  return new Promise((resolve, reject) => {
    const SDGs = getSDGList();
    ai({
      method: 'GET',
      url: '/api/Project/GetOverview',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      params: { id },
    })
      .then(response => {
        resolve({
          id: response.data.id,
          canEditPrivacy: false,
          name: response.data.name,
          description: response.data.description,
          logo: sanitizeAssetUrl(response.data.logoImage),
          cover: sanitizeAssetUrl(response.data.mainImageOrVideo),
          SDGs: response.data.projectSustainableDevelopmentGoals.map((g: any) => {
            const sdg = filter(remoteGoal => remoteGoal.id === g.sustainableDevelopmentGoalId, SDGs)[0];
            return {
              id: sdg.id,
              name: sdg.name,
              iconName: sdg.iconName,
            };
          }),
          tags: response.data.projectTags,
          country: response.data.countryName,
          city: response.data.city,
          category: response.data.categoryName,
          stage: response.data.developmentStage,
          isFollowing: response.data.isFollowing,
          websiteUrl: response.data.websiteUrl,
          twitterUrl: response.data.twitterUrl,
          facebookUrl: response.data.facebookUrl,
          googleUrl: response.data.googleUrl,
          linkedInUrl: response.data.linkedInUrl,
          flickrUrl: response.data.flickrUrl,
          canEditProjectInfo: response.data.canEditProjectInfo,
          allowLockComments: response.data.allowLockComments,
          categoryId: response.data.categoryId,
          unGoalId: response.data.unGoalId,
          projectModel: response.data.projectModel,
          projectPrivacy: response.data.privacy,
          taggedEntityId: response.data.taggedEntityId,
          countryId: response.data.countryId,
          answers: response.data.answers || [],
          communityIds: response.data.communityIds,
          mainCommunityId: response.data.mainCommunityId,
          isProjectAdmin: response.data.isProjectAdmin,
          isCommunityAdmin: response.data.isCommunityAdmin,
          businessModel: {
            canEditBusinessModel: false,
            sections: [],
            customPrivacyCircles: [],
          },
          kpiDefinitions: response.data.kpiDefinitions,
          form: response.data.form,
          isProjectTeamMember: response.data.isProjectTeamMember ?? undefined,
          userInvitationPolicy: response.data.userInvitationPolicy || UserInvitationPolicy.AnyoneViaEmailOrSearch,
          projectCommunityCircleList: response.data.projectCommunityCircleList,
          projectCommunityUserCircleTagList: response.data.projectCommunityUserCircleTagList,
        });
      })
      .catch(err => {
        reject(err);
      });
  });
}

export const _mapToMember = (item: any) => ({
  id: item.id,
  name: item.firstName + ' ' + item.lastName,
  photo: sanitizeAssetUrl(item.photoUrl),
  email: item.mail,
  occupation: item.occupation,
  country: '-',
  skills: [],
  interests: [],
  circle: { id: item.projectCircleId || item.tabEntityCircleId },
  isFollowed: item.isFollowedByMe,
});

export function fetchProjectMembers(
  bearer: string,
  id: number,
  sorting: Sorting,
  take: number | undefined = undefined,
): Promise<{
  id: number;
  members: Member[];
  pendingApplyToTeam: Member[];
  pendingByUsers: Member[];
  canInviteUser: boolean;
  isProjectAdmin: boolean;
  isMember: boolean;
}> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: '/api/Project/GetUserList',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      data: {
        skip: 0,
        ...sorting,
        projectId: id,
        take: take,
      },
    })
      .then(response => {
        const { list } = response.data.users;
        resolve({
          id,
          isProjectAdmin: response.data.isAdmin,
          isMember: response.data.isMember,
          members: list
            // the members field also contains the users that applied to join the team and dont have an answer yet
            // we will remove this users to not display them as members and as pending in the same time
            // .filter((m: any) => !response.data.pendingApplyToTeam.find((pending: any) => m.id === pending.id))
            .map(_mapToMember),
          pendingApplyToTeam: response.data.pendingApplyToTeam.map(_mapToMember),
          pendingByUsers: response.data.pendingByUsers.map(_mapToMember),
          canInviteUser: response.data.canInviteUser,
        });
      })
      .catch(err => reject(err));
  });
}

export function fetchProjectMembersPendingByUser(
  bearer: string,
  id: number,
): Promise<{ id: number; pendingByUsers: Member[] }> {
  return new Promise((resolve, reject) => {
    fetchProjectMembers(bearer, id, {}, 1)
      .then((response: { id: number; pendingByUsers: Member[] }) => {
        resolve({
          id: response.id,
          pendingByUsers: response.pendingByUsers,
        });
      })
      .catch(err => reject(err));
  });
}

export function respondProjectJoinRequest(
  bearer: string,
  projectId: number,
  userId: number,
  type: Response,
): Promise<{ projectId: number; userId: number; type: Response }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: `/api/Project/${type === Response.Deny ? 'Deny' : 'Approve'}ApplyToTeamRequest`,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      params: {
        userId,
        projectId,
      },
    })
      .then(() => {
        resolve({ projectId, userId, type });
      })
      .catch(err => reject(err));
  });
}

export function removeProjectInvitation(
  bearer: string,
  projectId: number,
  userId: number,
  userEmail: string,
): Promise<{ projectId: number; userId: number; userEmail: string }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/Project/RemoveInvitation',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      params: {
        projectId,
        userId,
        email: userEmail,
      },
    })
      .then(() =>
        resolve({
          projectId,
          userId,
          userEmail,
        }),
      )
      .catch(err => reject(err));
  });
}

export function inviteProjectAndCommunityMembers(
  bearer: string,
  data: EmailInvitationDto[],
): Promise<(EmailInvitationDto | AxiosError)[]> {
  return new Promise((resolve, reject) => {
    const projectMembers = data.filter((user: EmailInvitationDto) => !user.user.inviteToCommunity);
    const communityMembers = data.filter((user: EmailInvitationDto) => user.user.inviteToCommunity);
    const projectData = projectMembers.map((invitee: EmailInvitationDto) => ({
      id: invitee.id,
      user: {
        name: invitee.user.name,
        email: invitee.user.email,
        circleId: invitee.user.circleId,
        communityId: invitee.user.communityId,
      },
    }));
    const communityData = communityMembers.map((invitee: EmailInvitationDto) => ({
      id: invitee.user.communityId || 0,
      user: {
        name: invitee.user.name,
        email: invitee.user.email,
        circleId: invitee.user.communityCircleId || 0,
        inviteAlsoToProjectCircleId: invitee.user.circleId,
      },
    }));
    Promise.all([inviteProjectMembers(bearer, projectData), inviteCommunityMembers(bearer, communityData)])
      .then((responses: (EmailInvitationDto | AxiosError)[][]) => {
        resolve([...responses[0], ...responses[1]]);
      })
      .catch(err => {
        reject(err);
      });
  });
}

export function inviteProjectMember(bearer: string, data: EmailInvitationDto): Promise<EmailInvitationDto> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: '/api/Project/InviteToProjectViaEmail',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      data,
    })
      .then(response => {
        resolve(data);
      })
      .catch(err => reject(err));
  });
}

export function inviteProjectMembers(
  bearer: string,
  data: EmailInvitationDto[],
): Promise<(EmailInvitationDto | AxiosError)[]> {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const responseArray: (EmailInvitationDto | AxiosError)[] = [];
    for (let userIndex = 0; userIndex < data.length; userIndex++) {
      const user = data[userIndex];
      try {
        const response = await inviteProjectMember(bearer, user);
        responseArray.push(response);
      } catch (err) {
        responseArray.push(err);
        // reject(err);
      }
    }
    resolve(responseArray);
  });
}

export function applyToTeamProject(bearer: string, id: number): Promise<null> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/Project/ApplyToTeam',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      params: { id },
    })
      .then(() => resolve(null))
      .catch(err => reject(err));
  });
}

export function fetchProjectResources(
  bearer: string,
  id: number,
  sorting?: Sorting,
): Promise<{ id: number; canEditResources: boolean; resources: Resource[]; canEditPrivacy: boolean }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: '/api/Project/GetResourceList',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      data: {
        skip: 0,
        orderBy: sorting?.orderBy || 1,
        projectId: id,
      },
    })
      .then(response => {
        const { list } = response.data.resources;
        resolve({
          id,
          canEditResources: response.data.canEditResources,
          canEditPrivacy: response.data.canEditPrivacy,
          resources: list.map(
            (item: any): Resource => ({
              id: parseInt(item.id),
              tags: item.resourceTags,
              image: sanitizeAssetUrl(item.image),
              lastUpdated: item.lastUpdated,
              postDate: item.postDate,
              name: item.name,
              description: item.description,
              resourceUrl: sanitizeAssetUrl(item.resourceUrl),
              extension: getResourceType(item.resourceUrl),
              privacyLevel: item.privacyLevel,
              resourceType: item.resourceType,
              paragraphId: item.paragraphId,
              usedByRequest: item.usedByRequest,
              circles: (item.resourcePrivacyCircles || []).map((circle: any) => ({
                id: circle.projectCircleId,
                name: '',
                admin: false,
              })),
              icon: item.icon,
            }),
          ),
        });
      })
      .catch(err => reject(err));
  });
}

export function fetchProjectResourcesTags(bearer: string, id: number): Promise<{ id: number; tags: string[] }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/Tag/GetProjectResourceTagNameList',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      params: {
        skip: 0,
        take: 0,
        projectId: id,
      },
    })
      .then(response => {
        const { data } = response;
        resolve({
          id,
          tags: data,
        });
      })
      .catch(err => reject(err));
  });
}

export function fetchProjectDiscussions(
  bearer: string,
  id: number,
  sorting: Sorting,
): Promise<{ id: number; canEditDiscussions: boolean; discussions: Discussion[]; canEditPrivacy: boolean }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: '/api/BusinessModelContent/GetDiscussionList',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      data: {
        skip: 0,
        orderBy: sorting.orderBy,
        projectId: id,
      },
    })
      .then(response => {
        const { list } = response.data.discussions;
        resolve({
          id,
          canEditDiscussions: response.data.canEditDiscussions,
          canEditPrivacy: response.data.canEditPrivacy,
          discussions: list.map((item: any) => ({
            id: parseInt(item.id),
            name: item.name,
            image: sanitizeAssetUrl(item.image),
            postDate: item.postDate,
            lastUpdated: item.lastUpdated,
            deadlineDate: item.deadlineDate,
            deadline: item.deadline,
            daysLeft: item.daysLeft,
            paragraphId: item.paragraphId,
            isActive: item.isActive,
            userId: item.userId,
            authorName: item.authorName,
            authorPhoto: item.authorPhoto,
            discussionTags: item.discussionTags,
            discussionSkillCategories: item.discussionSkillCategories,
            isRelatedWithMethodology: item.isRelatedWithMethodology,
            readOnly: item.readOnly,
            isArchived: item.isArchived,
            allowLockComments: item.allowLockComments,
            isLockedCommentDiscussions: item.isLockedCommentDiscussions,
            privacyLevel: item.privacyLevel,
            followersCount: item.followersCount,
            discussionUserFollows: item.discussionUserFollows,
            commentCount: item.commentCount,
          })),
        });
      })
      .catch(err => reject(err));
  });
}

export function fetchProjectCircles(bearer: string, id: number): Promise<{ id: number; circles: Circle[] }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/ProjectCircle/GetByProject',
      params: {
        projectId: id,
      },
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        resolve({
          id,
          circles: map(
            c => ({
              id: Number(c.id),
              name: c.name,
              shortName: c.shortName,
              admin: c.isTeamCircle,
              userCount: c.userCount,
            }),
            response.data,
          ),
        });
      })
      .catch(err => reject(err));
  });
}

export function saveProjectCircle(
  bearer: string,
  projectId: number,
  circle: Circle,
): Promise<{ projectId: number; circle: Circle }> {
  return new Promise((resolve, reject) => {
    const newData = {
      projectId,
      project: null,
      editingName: circle.name,
      name: circle.name,
      isEditing: false,
      isSaving: true,
      community: null,
    };
    const updateData = {
      isTeamCircle: false,
      id: circle.id,
    };
    let data: any;
    data = {
      ...newData,
    };
    if (circle.id !== 1) {
      data = {
        ...newData,
        ...updateData,
      };
    }
    ai({
      method: 'POST',
      url: '/api/ProjectCircle/Save',
      data,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        const { data } = response;
        resolve({
          projectId,
          circle: {
            id: data.id,
            name: data.name,
            shortName: data.shortName,
            admin: data.isAdminCircle,
            type: {
              name: PrivacyCircleType.community,
              isProject: false,
              isCommunity: true,
            },
            projectId,
            userCount: circle.userCount,
          },
        });
      })
      .catch(err => reject(err));
  });
}

export function saveProjectCircles(
  bearer: string,
  projectId: number,
  circles: Circle[],
): Promise<{ projectId: number; circles: Circle[] }> {
  return new Promise((resolve, reject) => {
    (Promise as any)
      .allSettled(circles.map((c: Circle) => saveProjectCircle(bearer, projectId, c)))
      .then((values: any) => {
        const retVal: { projectId: number; circles: Circle[] } = {
          projectId,
          circles: values
            .filter((promise: any) => promise.status === 'fulfilled')
            .map((promise: any) => promise.value)
            .map((c: { projectId: number; circle: Circle }) => c.circle),
        };
        if (retVal.circles.length === 0) reject(values[0]?.reason);
        else resolve(retVal);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

export function deleteProjectCircle(
  projectId: number,
  bearer: string,
  circleId: number,
): Promise<{ projectId: number; circleId: number }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/ProjectCircle/Remove',
      params: {
        id: circleId,
      },
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then(() => {
        resolve({
          projectId,
          circleId,
        });
      })
      .catch(err => reject(err));
  });
}

export function editProjectCircle(
  projectId: number,
  bearer: string,
  circleId: string,
  userId: string,
): Promise<{ id: number; circleId: number; userId: number }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/Project/EditProjectCircle',
      params: {
        projectCircleId: circleId,
        projectId,
        userId,
      },
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then(() => {
        resolve({
          id: projectId,
          circleId: Number(circleId),
          userId: Number(userId),
        });
      })
      .catch(err => reject(err));
  });
}

export function createProject(bearer: string, project: NewProject): Promise<Project> {
  const SDGs = getSDGList();
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: '/api/Project/Create',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      data: {
        communityId: project.communityId,
        name: project.name,
        description: project.description,
        categoryId: project.categoryId,
        developmentStage: project.developmentStage,
        methodologyId: project.methodologyId,
        countryId: project.countryId,
        city: project.city,
        websiteUrl: project.websiteUrl,
        facebookUrl: project.facebookUrl,
        linkedInUrl: project.linkedInUrl,
        answers: project.answers || [],
        sustainableDevelopmentGoals: project.SDGs.map(s => ({ id: s })),
        logoImage: '',
        mainImageOrVideo: '',
      },
    })
      .then(response => {
        resolve({
          id: response.data.id,
          canEditPrivacy: false,
          name: response.data.name,
          creationDate: response.data.creationDate,
          communityIds: Array(1).fill(project.communityId),
          mainCommunityId: response.data.mainCommunityId,
          description: response.data.description,
          logo: sanitizeAssetUrl(response.data.logoImage),
          cover: sanitizeAssetUrl(response.data.mainImageOrVideo),
          SDGs: response.data.projectSustainableDevelopmentGoals.map((g: any) => {
            const sdg = filter(remoteGoal => remoteGoal.id === g.sustainableDevelopmentGoalId, SDGs)[0];
            return {
              id: sdg.id,
              name: sdg.name,
              iconName: sdg.iconName,
            };
          }),
          tags: response.data.projectTags || [],
          answers: response.data.answers || [],
          country: response.data.countryName || '-',
          city: response.data.city,
          category: response.data.categoryName || '-',
          stage: response.data.developmentStage,
          isFollowing: response.data.isFollowing,
          businessModel: {
            canEditBusinessModel: false,
            sections: [],
            customPrivacyCircles: [],
          },
          kpiValues: [],
        });
      })
      .catch(err => reject(err));
  });
}

export function updateProject(
  project: Project,
  bearer: string,
  isCover: boolean,
  link: string,
): Promise<{ project: Project }> {
  const SDGs = getSDGList();
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: '/api/Project/SaveEditInfoOverview',
      data: {
        id: project.id,
        name: project.name,
        description: project.description,
        city: project.city,
        logoImage: isCover ? project.logo : link,
        mainImageOrVideo: isCover ? link : project.cover,
        websiteUrl: project.websiteUrl,
        twitterUrlSuffix: project.twitterUrl ? project.twitterUrl : '',
        facebookUrl: project.facebookUrl ? project.facebookUrl : '',
        facebookUrlSuffix: project.facebookUrl
          ? project.facebookUrl.replace(/^http(s)?:\/\/(www\.)?facebook\.com\//gi, '')
          : '',
        googleUrlSuffix: project.googleUrl ? project.googleUrl : '',
        linkedInUrl: project.linkedInUrl ? project.linkedInUrl : '',
        linkedInUrlSuffix: project.linkedInUrl ? project.linkedInUrl.split('.linkedin.com/').pop() : '',
        flickrUrlSuffix: project.flickrUrl ? project.flickrUrl : '',
        projectTags: project.tags,
        projectSustainableDevelopmentGoals: project.SDGs.map(s => s.id),
        categoryId: project.categoryId,
        unGoalId: project.unGoalId,
        projectModel: project.projectModel ? project.projectModel : '',
        projectPrivacy: project.projectPrivacy,
        countryId: project.countryId,
        allowLockComments: project.allowLockComments,
        taggedEntityId: project.taggedEntityId,
        developmentStage: project.stage,
        answers: project.answers || [],
      },
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        resolve({
          project: {
            id: response.data.id,
            canEditPrivacy: project.canEditPrivacy,
            name: response.data.name,
            description: response.data.description,
            logo: sanitizeAssetUrl(response.data.logoImage),
            cover: sanitizeAssetUrl(response.data.mainImageOrVideo),
            SDGs: response.data.projectSustainableDevelopmentGoals.map((g: any) => {
              const sdg = filter(remoteGoal => remoteGoal.id === g.sustainableDevelopmentGoalId, SDGs)[0];
              return {
                id: sdg.id,
                name: sdg.name,
                iconName: sdg.iconName,
              };
            }),
            tags: response.data.projectTags,
            country: response.data.countryName,
            city: response.data.city,
            category: response.data.categoryName,
            stage: response.data.developmentStage,
            isFollowing: response.data.isFollowing,
            websiteUrl: response.data.websiteUrl,
            twitterUrl: response.data.twitterUrl,
            facebookUrl: response.data.facebookUrl,
            googleUrl: response.data.googleUrl,
            linkedInUrl: response.data.linkedInUrl,
            flickrUrl: response.data.flickrUrl,
            canEditProjectInfo: response.data.canEditProjectInfo,
            allowLockComments: response.data.allowLockComments,
            categoryId: response.data.categoryId,
            unGoalId: response.data.unGoalId,
            projectModel: response.data.projectModel,
            projectPrivacy: response.data.privacy,
            taggedEntityId: response.data.taggedEntityId,
            countryId: response.data.countryId,
            communityIds: project.communityIds,
            mainCommunityId: response.data.mainCommunityId,
            businessModel: {
              canEditBusinessModel: false,
              sections: [],
              customPrivacyCircles: [],
            },
            answers: response.data.answers || [],
          },
        });
      })
      .catch(err => reject(err));
  });
}

export function updateProjectLogo(
  project: Project,
  bearer: string,
  logo: File | string,
): Promise<{ projectId: number; logo: string }> {
  return new Promise((resolve, reject) => {
    uploadFile(bearer, { file: logo, folder: 'businessplan_logo' }, ImageType.ProjectLogo)
      .then(({ link }) => {
        updateProject(project, bearer, false, link)
          .then(() => {
            resolve({
              projectId: project.id,
              logo: link,
            });
          })
          .catch(err => reject(err));
      })
      .catch(err => reject(err));
  });
}

export function updateProjectCover(
  project: Project,
  bearer: string,
  cover: File | string,
): Promise<{ projectId: number; cover: string }> {
  return new Promise((resolve, reject) => {
    if (typeof cover === 'string') {
      // youtube video
      updateProject(project, bearer, true, cover)
        .then(() => {
          resolve({
            projectId: project.id,
            cover: cover,
          });
        })
        .catch(err => reject(err));
    } else {
      // file from computer

      uploadFile(bearer, { file: cover, folder: 'businessplan_logo' }, ImageType.ProjectMainImage)
        .then(({ link }) => {
          updateProject(project, bearer, true, link)
            .then(() => {
              resolve({
                projectId: project.id,
                cover: link,
              });
            })
            .catch(err => reject(err));
        })
        .catch(err => reject(err));
    }
  });
}

export function removeUserFromTabEntity(
  bearer: string,
  id: number,
  communityId: number,
  userId: number,
): Promise<{ id: number; communityId: number; userId: number }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: `/api/TabEntity/${id}/AbandonTabEntity`,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      params: {
        id,
        userId,
      },
    })
      .then(() => {
        resolve({
          id,
          communityId,
          userId,
        });
      })
      .catch(err => reject(err));
  });
}

export function removeUserFromProject(
  bearer: string,
  id: number,
  userId: number,
): Promise<{ id: number; userId: number }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/Project/AbandonProject',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      params: {
        projectId: id,
        userId: userId,
      },
    })
      .then(() => {
        resolve({
          id,
          userId: userId,
        });
      })
      .catch(err => reject(err));
  });
}

export function removeProject(bearer: string, projectId: number): Promise<{ projectId: number }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/Project/Disable',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      params: {
        id: projectId,
      },
    })
      .then(() => {
        resolve({
          projectId,
        });
      })
      .catch(err => reject(err));
  });
}

export function followProject(bearer: string, projectId: number): Promise<{ projectId: number }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/Project/Follow',
      params: {
        id: projectId,
      },
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then(() => {
        resolve({ projectId });
      })
      .catch(err => reject(err));
  });
}

export function unfollowProject(bearer: string, projectId: number): Promise<{ projectId: number }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/Project/Unfollow',
      params: {
        id: projectId,
      },
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then(() => {
        resolve({ projectId });
      })
      .catch(err => reject(err));
  });
}

export function exportBusinessModel(bearer: string, projectId: number, methodologyId: number): Promise<string> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/BusinessModelGenerateDocument/GenerateDocument',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      params: {
        businessModelId: projectId,
        methodologyId,
      },
    })
      .then((response: AxiosResponse) => {
        resolve(response.data);
      })
      .catch(err => reject(err));
  });
}

export function saveProjectDiscussionTags(
  bearer: string,
  discussionId: number,
  projectId: number,
  tags: string[],
): Promise<{ projectId: number; discussionId: number; tags: string[] }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: '/api/Discussion/SaveTags',
      data: {
        discussionId,
        discussionTags: tags,
      },
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then(() => {
        resolve({
          projectId,
          discussionId,
          tags,
        });
      })
      .catch(err => reject(err));
  });
}

export function acceptProjectInvitation(
  bearer: string,
  projectId: number,
  userId: number,
): Promise<{ projectId: number }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/Project/AcceptInvitation',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      params: {
        projectId,
        userId,
      },
    })
      .then(() => {
        resolve({
          projectId,
        });
      })
      .catch(err => reject(err));
  });
}

export function declineProjectInvitation(
  bearer: string,
  projectId: number,
  userId: number,
): Promise<{ projectId: number }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/Project/DeclineInvitation',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      params: {
        projectId,
        userId,
      },
    })
      .then(() => {
        resolve({
          projectId,
        });
      })
      .catch(err => reject(err));
  });
}

export function fetchProjectProgressDetails(
  bearer: string,
  projectId: number,
  methodologyId: number,
): Promise<{ progressDetails: ProjectProgressDetails; projectId: number }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/ProjectTracking/Details',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      params: {
        projectId,
        methodologyId,
      },
    })
      .then((response: AxiosResponse) => {
        const currentTimestamp = new Date().getTime();
        let sections = response.data.sections.map((section: any) => {
          return {
            ...section,
            paragraphs: section.paragraphs.map((paragraph: any) => {
              return {
                ...paragraph,
                approvedTime: paragraph.approvedTime || null,
                submitedTime: paragraph.submitedTime || null,
                isApproved: paragraph.isApproved || false,
                taskTimestampStart: Date.parse(paragraph.taskDateStart || ''),
                taskTimestampEnd: Date.parse(paragraph.taskDateEnd || ''),
                isDue: Date.parse(paragraph.taskDateEnd || '') < currentTimestamp && !paragraph.isPublished,
              };
            }),
          };
        });
        let previousParagraph;
        let nextParagraph;
        const getNextParagraph = (sections: any[], iS: number, iP: number) => {
          if (iP < sections[iS].paragraphs.length - 1) {
            return sections[iS].paragraphs[iP + 1];
          }
          iS++;
          while (iS < sections.length) {
            if (sections[iS].paragraphs.length === 0) {
              iS++;
            } else {
              return sections[iS].paragraphs[0];
            }
          }
          return undefined;
        };
        // computing the flags outlineDot and continueLine
        // This flags can be computed here, so the component will not compute them every render
        // outlineDot - if the paragraph does not have a colored line connecting its dot (no adjent
        //              paragraph share the same state: published or due), the dot should have an
        //              outline to improve readability
        // continueLine - if the next paragraph share the same state as the current, their dots are
        //                connected with a line
        let earliestTimestamp = currentTimestamp;
        for (let iS = 0; iS < sections.length; iS++) {
          const section = sections[iS];
          for (let iP = 0; iP < section.paragraphs.length; iP++) {
            const paragraph = section.paragraphs[iP];
            if (paragraph.taskTimestampStart < earliestTimestamp) {
              earliestTimestamp = paragraph.taskTimestampStart;
            }
            nextParagraph = getNextParagraph(sections, iS, iP);
            paragraph.outlineDot = true;
            paragraph.continueLine = false;
            if (paragraph.isPublished) {
              if (nextParagraph && nextParagraph.isPublished) {
                paragraph.outlineDot = false;
                paragraph.continueLine = true;
              }
              if (previousParagraph && previousParagraph.isPublished) paragraph.outlineDot = false;
            } else if (paragraph.isDue) {
              if (nextParagraph && nextParagraph.isDue) {
                paragraph.outlineDot = false;
                paragraph.continueLine = true;
              }
              if (previousParagraph && previousParagraph.isDue) paragraph.outlineDot = false;
            }

            previousParagraph = paragraph;
          }
        }
        sections = sections.map((section: any) => {
          return {
            ...section,
            paragraphs: section.paragraphs.map((paragraph: any) => {
              return {
                ...paragraph,
                week: Math.floor((paragraph.taskTimestampStart - earliestTimestamp) / 1000 / 60 / 60 / 24 / 7) + 1,
              };
            }),
          };
        });
        resolve({
          projectId,
          progressDetails: {
            progress: response.data.progress,
            sections: sections,
          },
        });
      })
      .catch(err => reject(err));
  });
}

export function fetchPendingKPI(bearer: string, projectId: number): Promise<{ projectId: number; kpis: KpiValue[] }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/KpiValue/GetPending',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      params: {
        projectId: projectId,
      },
    })
      .then((response: AxiosResponse) => {
        const { data } = response;
        resolve({
          projectId,
          kpis: data.map((kpi: any) => {
            return {
              id: kpi.id,
              name: kpi.kpiDefinition.name,
              projectId: kpi.projectId,
              kpiDefinitionId: kpi.kpiDefinitionId,
              year: kpi.year,
              month: kpi.month,
              value: kpi.value,
            };
          }),
        });
      })
      .catch(err => reject(err));
  });
}

export function saveKpiValues(bearer: string, kpis: KpiValue[]): Promise<{ kpis: KpiValue[] }> {
  return new Promise((resolve, reject) => {
    (Promise as any)
      .allSettled(
        kpis.map((kpi: KpiValue) => {
          return new Promise((resolve, reject) => {
            ai({
              method: 'POST',
              url: '/api/KpiValue/Save',
              headers: {
                Authorization: `Bearer ${bearer}`,
              },
              data: {
                ...kpi,
              },
            })
              .then(() => {
                resolve(kpi);
              })
              .catch(err => reject(err));
          });
        }),
      )
      .then((values: any) => {
        const retVal = values
          .filter((promise: any) => promise.status === 'fulfilled')
          .map((promise: any) => promise.value);
        if (retVal.length === 0) reject(values[0]?.reason);
        else resolve({ kpis: retVal });
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

export function updateKpiValues(bearer: string, kpis: KpiValue[]) {
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: '/api/KpiValue/SaveList',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      data: kpis,
    })
      .then(() => {
        resolve(kpis);
      })
      .catch(err => reject(err));
  });
}

export function saveRequest(
  bearer: string,
  projectId: number,
  request: SaveRequest,
): Promise<{ projectId: number; request: Request }> {
  return new Promise((resolve, reject) => {
    const requestPrivacyCircles = request.privacyCircleIds.map(
      (circleId: number): Circle => ({ id: circleId, name: '', shortName: '', admin: false }),
    );
    const resources = request.resourcesToUpload.map((r: ResourceMeta) => ({
      ...r.resource,
      privacyLevel: request.privacyLevel,
      circles: requestPrivacyCircles,
    }));
    const files = request.resourcesToUpload.map((r: ResourceMeta) => r.file);

    // delete the resources that were already uploaded and removed from the request in this edit session
    deleteResources(request.resourcesToDelete, projectId, bearer, false).then(deleteResponse => {
      // create the new resources from this edit session
      createResources(resources, bearer, projectId, undefined, files, false, []).then(res => {
        const resources = res.resources.map(r => r.id || 0).filter(r => r !== 0);
        // update the privacy of the already existing resources
        const existingResourcesWithNewPrivacy = request.alreadyUploadedResources.map(
          (r: Resource): Resource => ({ ...r, privacyLevel: request.privacyLevel, circles: requestPrivacyCircles }),
        );
        const existingResourcesFiles = existingResourcesWithNewPrivacy.map((r: Resource) => '');
        request.projectResourceIds = [...resources];
        createResources(
          existingResourcesWithNewPrivacy,
          bearer,
          projectId,
          undefined,
          existingResourcesFiles,
          false,
          [],
        ).then(updateResourcesPrivacyResult => {
          ai({
            method: request.id ? 'PUT' : 'POST',
            url: request.id ? `/api/Request/${request.id}` : '/api/project/request',
            headers: {
              Authorization: `Bearer ${bearer}`,
            },
            data: {
              projectId,
              name: request.name,
              description: request.description,
              privacyLevel: request.privacyLevel,
              privacyCircleIds: request.privacyCircleIds,
              skillIds: request.skillIds,
              marketExpertiseIds: request.marketExpertiseIds,
              projectResourceIds: request.projectResourceIds,
              requestIndustryIds: request.requestIndustryIds,
            },
          })
            .then((response: AxiosResponse) => {
              if (!response.data.success) {
                if (response.data.hasAwaitingReview || response.data.pendingFeedbackRequests.length > 0)
                  return reject(new Error('hasAwaitingReview'));
                else return reject(new Error(''));
              }
              const request = mapResponseToRequest(response);
              resolve({
                projectId,
                request,
              });
            })
            .catch(err => {
              reject(err);
            });
        });
      });
    });
  });
}

export function createRequest(
  bearer: string,
  projectId: number,
  name: string,
  description: string,
  privacyLevel: number,
  privacyCircleIds: number[],
  skillIds: number[],
  marketExpertiseIds: number[],
  resourceIds?: number[],
): Promise<{ success: boolean; hasAwaitingReview: boolean }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: '/api/project/request',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      data: {
        projectId: projectId,
        name: name,
        description: description,
        privacyLevel: privacyLevel,
        privacyCircleIds: privacyCircleIds,
        skillIds: skillIds,
        marketExpertiseIds: marketExpertiseIds,
        projectResourceIds: resourceIds || [],
      },
    })
      .then(response => {
        resolve({
          success: response.data.success,
          hasAwaitingReview: response.data.pendingFeedbackRequests.length > 0,
        });
      })
      .catch(err => reject(err));
  });
}

export function saveRequestComment(
  bearer: string,
  userId: number,
  itemId: number,
  content: string,
  parentId: number,
  requestId: number,
  projectId: number,
): Promise<{ projectId: number; requestId: number; comment: Comment }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: '/api/Comment/SaveRequestComment',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      data: {
        requestId,
        id: itemId,
        parentId,
        content,
        postDate: new Date().toISOString(),
        lastUpdated: new Date().toISOString(),
        // "authorName": "string",
        // "authorPhoto": "string",
        // "commentCount": 0,
        // "likeCount": 0,
        // "dislikeCount": 0,
        // "userLikesComment": true,
        // "userDislikesComment": true,
        // "comments": [
        //   null
        // ],
        // "canUserEdit": true,
        // "canUserRemove": true,
        authorId: userId,
      },
    })
      .then(response => {
        const { data } = response;
        resolve({
          requestId,
          comment: {
            id: data.id,
            author: {
              id: data.authorId,
              name: data.authorName,
              photo: data.authorPhoto,
              occupation: data.authorOccupation || '',
            },
            date: data.lastUpdated,
            content: mentionParse(data.content),
            parentId: data.parentId,
            likeCount: data.likeCount,
            isLiked: data.isLiked,
            comments: data.comments ?? [],
            canUserEdit: data.canUserEdit,
            canUserRemove: data.canUserRemove,
            userLikesComment: data.userLikesComment,
            userDislikesComment: data.userDislikesComment,
          },
          projectId,
        });
      })
      .catch(err => reject(err));
  });
}

export function deleteRequestComment(
  bearer: string,
  projectId: number,
  requestId: number,
  commentId: number,
): Promise<{
  projectId: number;
  requestId: number;
  commentId: number;
}> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/Comment/RemoveRequestComment',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      params: {
        id: commentId,
      },
    })
      .then(response => {
        resolve({
          projectId,
          requestId,
          commentId,
        });
      })
      .catch(err => reject(err));
  });
}

export function getRequestRecommendedAdvisors(bearer: string, requestId: number): Promise<Member[]> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: `/api/request/${requestId}/recommended-advisors`,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then(response => {
        resolve(
          response.data.list.map((item: any) => ({
            id: item.id,
            name: item.firstName + ' ' + item.lastName,
            photo: sanitizeAssetUrl(item.photoUrl),
            email: item.mail,
            occupation: '',
            country: '',
            skills: item.skillsMatch.map((skill: { id: number; skillName: string }) => ({
              id: skill.id,
              name: skill.skillName,
            })),
            interests: [],
            circle: { id: item.projectCircleId },
            isFollowed: item.isFollowedByMe,
          })),
        );
      })
      .catch(err => reject(err));
  });
}

export function searchRequestAdvisors(
  bearer: string,
  requestId: number,
  query: string,
): Promise<(Member & { isCommunityMember: boolean })[]> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: `/api/request/${requestId}/search-advisors?filter=${encodeURIComponent(query)}`,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then(response => {
        resolve(
          response.data.list.map((item: any) => ({
            id: item.id,
            name: item.firstName + ' ' + item.lastName,
            photo: sanitizeAssetUrl(item.photoUrl),
            email: item.mail,
            occupation: item.occupation,
            country: '-',
            skills: [],
            interests: [],
            circle: { id: item.projectCircleId },
            isFollowed: item.isFollowedByMe,
            isCommunityMember: item.isCommunityMember,
          })),
        );
      })
      .catch(err => reject(err));
  });
}

export function inviteCommunityAdvisors(bearer: string, requestId: number, advisorIds: number[]): Promise<void> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'PUT',
      url: `/api/request/${requestId}/invite-advisor`,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      data: advisorIds,
    })
      .then(() => resolve())
      .catch(err => reject(err));
  });
}

export function inviteNonCommunityAdvisor(
  bearer: string,
  requestId: number,
  advisorId: number,
  circleId: number,
): Promise<void> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'PUT',
      url: `/api/request/${requestId}/invite-non-community-advisor`,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      data: {
        userId: advisorId,
        communityCircleId: circleId,
      },
    })
      .then(() => resolve())
      .catch(err => reject(err));
  });
}

export function inviteNonCommunityAdvisors(
  bearer: string,
  requestId: number,
  advisors: {
    advisorId: number;
    circleId: number;
  }[],
): Promise<void> {
  return new Promise((resolve, reject) => {
    Promise.all(
      advisors.map((advisor: { advisorId: number; circleId: number }) =>
        inviteNonCommunityAdvisor(bearer, requestId, advisor.advisorId, advisor.circleId),
      ),
    )
      .then(() => resolve())
      .catch(err => reject(err));
  });
}

export function inviteNonRegisteredAdvisor(
  bearer: string,
  requestId: number,
  advisorEmail: string,
  circleId: number,
): Promise<void> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'PUT',
      url: `/api/request/${requestId}/invite-non-registered-advisor`,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      data: {
        email: advisorEmail,
        communityCircleId: circleId,
      },
    })
      .then(() => resolve())
      .catch(err => reject(err));
  });
}

export function inviteNonRegisteredAdvisors(
  bearer: string,
  requestId: number,
  advisors: {
    advisorEmail: string;
    circleId: number;
  }[],
): Promise<void> {
  return new Promise((resolve, reject) => {
    Promise.all(
      advisors.map((advisor: { advisorEmail: string; circleId: number }) =>
        inviteNonRegisteredAdvisor(bearer, requestId, advisor.advisorEmail, advisor.circleId),
      ),
    )
      .then(() => resolve())
      .catch(err => reject(err));
  });
}

export function inviteAdvisors(
  bearer: string,
  requestId: number,
  advisors: {
    advisorId: number;
    circleId?: number;
    advisorName: string;
  }[],
): Promise<void> {
  const communityAdvisorsIds = advisors
    .filter(
      (advisor: { advisorId: number; circleId?: number }) => advisor.circleId === undefined && advisor.advisorId > 0,
    )
    .map((advisor: { advisorId: number }) => advisor.advisorId);

  const nonCommunityAdvisors: { advisorId: number; circleId: number }[] = advisors.filter(
    (advisor: { advisorId: number; circleId?: number }) => advisor.circleId !== undefined && advisor.advisorId > 0,
  ) as { advisorId: number; circleId: number }[];

  const nonRegisteredAdvisors: { advisorEmail: string; circleId: number }[] = advisors
    .filter((advisor: { advisorId: number }) => advisor.advisorId < 0)
    .map((advisor: { advisorName: string; circleId?: number }) => ({
      advisorEmail: advisor.advisorName,
      circleId: advisor.circleId,
    })) as { advisorEmail: string; circleId: number }[];

  return new Promise((resolve, reject) => {
    Promise.all([
      inviteCommunityAdvisors(bearer, requestId, communityAdvisorsIds),
      inviteNonCommunityAdvisors(bearer, requestId, nonCommunityAdvisors),
      inviteNonRegisteredAdvisors(bearer, requestId, nonRegisteredAdvisors),
    ])
      .then(() => resolve())
      .catch(err => reject(err));
  });
}

export function scheduleRequestSession(
  bearer: string,
  requestId: number,
  projectId: number,
  advisorId: number,
  summary: string,
  description: string,
  start: Moment,
  end: Moment,
): Promise<{
  projectId: number;
  requestId: number;
  requestStatus: RequestStatusEnum;
  advisorId: number;
  start: string;
  location: string;
  attendeeUsers: InvitedAdvisor[];
}> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'PUT',
      url: `/api/request/${requestId}/schedule-meeting`,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      data: {
        attendeeUserIds: [advisorId],
        eventId: '',
        summary: summary,
        description: description,
        createConference: true,
        start: start.toISOString(),
        end: end.toISOString(),
      },
    })
      .then((resposne: AxiosResponse) =>
        resolve({
          projectId: projectId,
          requestId: requestId,
          requestStatus: RequestStatusEnum.Assigned,
          advisorId: advisorId,
          start: start.toISOString(),
          location: resposne.data?.location || '',
          attendeeUsers: resposne.data.attendeeUsers.map(
            (user: { firstName: string; lastName: string; id: number; photoUrl: string }) => ({
              name: `${user.firstName} ${user.lastName}`,
              photo: user.photoUrl,
              id: user.id,
            }),
          ),
        }),
      )
      .catch(err => reject(err));
  });
}

export function updateRequestDefaultPrivacy(
  bearer: string,
  communityId: number,
  defaultCircleIds: number[],
): Promise<null> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: `/api/Request/Community/${communityId}/DefaultCircles`,
      data: defaultCircleIds,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then(() => {
        resolve(null);
      })
      .catch(err => reject(err));
  });
}

export function getRequestDefaultPrivacy(
  bearer: string,
  communityId: number,
): Promise<{ defaultCircles: { id: number; name: string }[] }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: `/api/Request/Community/${communityId}/DefaultCircles`,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        const { data } = response;
        resolve(data);
      })
      .catch(err => reject(err));
  });
}

export function acceptRequestInvitation(bearer: string, communityId: number): Promise<void> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: `/api/Request/accept-invitation/${communityId}`,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then(() => {
        resolve();
      })
      .catch(err => reject(err));
  });
}

export function declineRequestInvitation(bearer: string, communityId: number): Promise<void> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: `/api/Request/decline-invitation/${communityId}`,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then(() => {
        resolve();
      })
      .catch(err => reject(err));
  });
}

export function inviteProjectMemberViaId(
  bearer: string,
  projectId: number,
  data: UserCircleInvitationDto,
): Promise<null> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: `/api/Project/MoveUserToProject/${projectId}`,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      data: data,
    })
      .then(() => resolve(null))
      .catch(err => reject(err));
  });
}

export function fetchProjectMembersByCircle(
  bearer: string,
  projectId: number,
  circleId: number,
  skip: number,
  take: number,
): Promise<{
  projectId: number;
  circleId: number;
  skip: number;
  take: number;
  members: Member[];
}> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: '/api/Project/GetUserList',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      data: {
        skip,
        take,
        orderBy: 1, // order by name
        projectCircleId: circleId,
        projectId,
      },
    }).then((response: AxiosResponse) => {
      resolve({
        projectId,
        circleId,
        skip,
        take,
        members: response.data.users.list.map(_mapToMember),
      });
    });
  });
}

export function fetchProjectKPIStats(bearer: string, projectId: number): Promise<string> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/KPIReport/GetByProject',
      params: {
        projectId,
      },
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        resolve(response.data);
      })
      .catch(err => reject(err));
  });
}

export function fetchProjectKPIJsonStats(bearer: string, projectId: number): Promise<KPIList[]> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/KPIReport/GetByProjectJson',
      params: {
        projectId,
      },
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        const kpis = response.data.filter((kpi: any) => {
          const startDate = new Date(kpi.kpiDefinitionStartYear, kpi.kpiDefinitionStartMonth - 1, 1);
          const currentDate = new Date();
          const startYearMonth = startDate.getFullYear() * 100 + startDate.getMonth();
          const currentYearMonth = currentDate.getFullYear() * 100 + currentDate.getMonth();
          return startYearMonth <= currentYearMonth;
        });
        resolve(kpis);
      })
      .catch(err => reject(err));
  });
}

export function fetchProjectKPIs(
  bearer: string,
  projectId: number,
): Promise<{ projectId: number; kpis: KpiDefinition[] }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/KpiDefinition/GetByProject',
      params: {
        projectId,
      },
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        // resolve(response.data);
        resolve({
          projectId: projectId,
          kpis: response.data,
        });
      })
      .catch(err => {
        if (err?.response?.data?.value?.ClassName === 'System.UnauthorizedAccessException')
          reject(new Error('hideErrorToast'));
        reject(err);
      });
  });
}

export function upsertKPI(
  bearer: string,
  communityId: number,
  kpi: KpiDefinition,
): Promise<{ communityId: number; kpi: KpiDefinition }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: '/api/KpiDefinition/Save',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      data: {
        ...kpi,
      },
    })
      .then((response: AxiosResponse) => {
        const { data } = response;
        resolve({ communityId, kpi: { ...kpi, id: data } });
      })
      .catch(err => reject(err));
  });
}

export function deleteKPI(
  bearer: string,
  communityId: number,
  kpi: KpiDefinition,
): Promise<{ communityId: number; kpi: KpiDefinition }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/KpiDefinition/Remove',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      params: {
        id: kpi.id,
      },
    })
      .then(() => {
        resolve({ communityId, kpi });
      })
      .catch(err => reject(err));
  });
}

export function fetchProjectRequestFeedbackList(bearer: string, communityId: number, projectId: number): Promise<any> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: `/api/Request/${communityId}/feedback/${projectId}`,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        resolve(response.data);
      })
      .catch(err => reject(err));
  });
}

export function editProjectDiscussionTags(
  bearer: string,
  projectId: number,
  tagsList: Tag[],
): Promise<{ projectId: number; tagsList: Tag[] }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: `/api/Discussion/project/${projectId}/bulk/Tag`,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      data: tagsList,
    })
      .then(data => {
        resolve({ projectId, tagsList });
      })
      .catch(err => reject(err));
  });
}

export function deleteProjectDiscussionTags(
  bearer: string,
  projectId: number,
  tags: string[],
): Promise<{ projectId: number; tags: string[] }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'DELETE',
      url: `/api/Discussion/project/${projectId}/bulk/Tag`,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      data: tags,
    })
      .then(data => {
        resolve({ projectId, tags });
      })
      .catch(err => reject(err));
  });
}

export function editProjectResourceTags(
  bearer: string,
  projectId: number,
  tagsList: Tag[],
): Promise<{ projectId: number; tagsList: Tag[] }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: `/api/Resource/project/${projectId}/bulk/Tag`,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      data: tagsList,
    })
      .then(data => {
        resolve({ projectId, tagsList });
      })
      .catch(err => reject(err));
  });
}

export function deleteProjectResourceTags(
  bearer: string,
  projectId: number,
  tags: string[],
): Promise<{ projectId: number; tags: string[] }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'DELETE',
      url: `/api/Resource/project/${projectId}/bulk/Tag`,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      data: tags,
    })
      .then(data => {
        resolve({ projectId, tags });
      })
      .catch(err => reject(err));
  });
}

export function editProjectTags(
  bearer: string,
  projectId: number,
  tagsList: Tag[],
  tagType: TagType,
): Promise<{ projectId: number; tagsList: Tag[]; tagType: TagType }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: `/api/${tagType}/project/${projectId}/bulk/Tag`,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      data: tagsList,
    })
      .then(data => {
        resolve({ projectId, tagsList, tagType });
      })
      .catch(err => reject(err));
  });
}

export function deleteProjectTags(
  bearer: string,
  projectId: number,
  tags: string[],
  tagType: TagType,
): Promise<{ projectId: number; tags: string[]; tagType: TagType }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'DELETE',
      url: `/api/${tagType}/project/${projectId}/bulk/Tag`,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      data: tags,
    })
      .then(data => {
        resolve({ projectId, tags, tagType });
      })
      .catch(err => reject(err));
  });
}

export function saveCoachingSession(
  bearer: string,
  coachingSession: SaveCoachingSession,
): Promise<{ coachingSession: { id: number; projectId: number } }> {
  return new Promise((resolve, reject) => {
    ai({
      method: coachingSession.id ? 'PUT' : 'POST',
      url: coachingSession.id
        ? `/api/Request/${coachingSession.id}/coachingsession`
        : '/api/project/request/coachingsession',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      data: {
        summary: coachingSession.summary,
        description: coachingSession.description, // to make it look good on the request page afterwards
        start: coachingSession.start,
        end: coachingSession.end,
        attendeeUserIds: coachingSession.attendeeUserIds,
        projectId: coachingSession.projectId,
        location: coachingSession.location,
        createConference: coachingSession.createConference,
      },
    })
      .then((response: AxiosResponse) => {
        resolve({ coachingSession: { id: response.data.requestId, projectId: response.data.projectId } });
      })
      .catch(err => reject(err));
  });
}

export const fetchProjectRequestsFilter = (bearer: string, projectId: number): AxiosPromise<CommunityRequestsFilters> =>
  new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: `/api/Request/${projectId}/filters`,
      headers: buildHeaders(bearer),
    })
      .then(response => {
        const filterData = response.data;
        const industries = customSortArrayOfObjects(filterData.industries, 'name', GlobalAllOptions.ALL);
        const countries = customSortArrayOfObjects(filterData.countries, 'name', GlobalAllOptions.GLOBAL);
        resolve({ ...response, data: { ...filterData, industries, countries } });
      })
      .catch(err => reject(err));
  });

export default {
  saveRequestComment,
  deleteRequestComment,
  fetchProjectById,
  fetchProjectRequests,
  fetchProjectOverview,
  fetchProjectMembers,
  respondProjectJoinRequest,
  removeProjectInvitation,
  inviteProjectMembers,
  applyToTeamProject,
  fetchProjectResources,
  fetchProjectResourcesTags,
  fetchProjectDiscussions,
  fetchProjectCircles,
  saveProjectCircles,
  updateProject,
  unfollowProject,
  saveProjectCircle,
  fetchRequestsTags,
  updateRequestTagsById,
  deleteProjectCircle,
  editProjectCircle,
  createProject,
  updateProjectLogo,
  updateProjectCover,
  removeUserFromProject,
  removeProject,
  followProject,
  saveProjectDiscussionTags,
  acceptProjectInvitation,
  fetchProjectProgressDetails,
  fetchPendingKPI,
  saveKpiValues,
  fetchRequestById,
  saveRequest,
  fetchProjectFilteringSkills,
  inviteProjectAndCommunityMembers,
  fetchProjectMembersPendingByUser,
  fetchProjectMembersByCircle,
  fetchProjectKPIStats,
  fetchProjectKPIJsonStats,
  removeUserFromTabEntity,
  updateKpiValues,
  fetchProjectKPIs,
  upsertKPI,
  deleteKPI,
  fetchProjectRequestFeedbackList,
  editProjectDiscussionTags,
  deleteProjectDiscussionTags,
  editProjectResourceTags,
  deleteProjectResourceTags,
  editProjectTags,
  deleteProjectTags,
  saveCoachingSession,
  fetchProjectRequestsFilter,
};
