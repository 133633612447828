import { AxiosResponse } from 'axios';
import { UserProfile } from 'UsersModels';
import { map } from 'ramda';
import { Interest, SDG, SearchUser, Skill } from 'redux/types/account';

import { sanitizeAssetUrl } from '../../util/assets';
import { getAxiosInstance } from './helper';
import { getSDGIconPath } from 'util/utils';

const ai = getAxiosInstance();

export function fetchUserProfile(bearer: string, id: number): Promise<UserProfile> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/User/GetProfile',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      params: { id },
    })
      .then((response: AxiosResponse) => {
        const { data } = response;
        resolve({
          id: data.id,
          name: data.name,
          photo: sanitizeAssetUrl(data.photo),
          occupation: data.occupation,
          biography: data.biography,
          interests: data.interests.map((interest: Interest) => interest.name),
          skills: data.skills.filter((skill: Skill) => skill !== null).map((skill: Skill) => skill.name),
          followedProjects: data.followedProjects,
          projects: data.projects.map((projects: any) => ({ ...projects, owner: true })),
          countryName: data.countryName,
          city: data.city,
          linkedInUrl: data.linkedInUrl || '',
          company: data.company || '',
          isFollowedByMe: data.isFollowedByMe,
          primaryCommunityId: data.primaryCommunityId || null,
          marketExpertises: data.marketExpertises || [],
          sustainableDevelopmentGoals:
            data.sustainableDevelopmentGoals.map((sdg: SDG) => ({ ...sdg, iconName: getSDGIconPath(sdg.iconName) })) ||
            [],
          workingExperience: map(
            e => ({
              id: e.id,
              title: e.title,
              location: `${e.countryName || ''}, ${e.city || ''}`,
              employer: e.employer,
              description: e.description,
              start: new Date(e.startDate),
              end: new Date(e.endDate),
            }),
            data.userWorkingExperience,
          ),
          communities: map(
            c => ({
              ...c,
              id: c.id,
              name: c.name,
              photo: sanitizeAssetUrl(c.logoImage),
              shortDescription: c.shortDescription,
              circleName: c.circleName,
            }),
            data.communities,
          ),
        });
      })
      .catch(err => reject(err));
  });
}

export function followUser(bearer: string, userId: number): Promise<number> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/User/Follow',
      params: {
        id: userId,
      },
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then(() => resolve(userId))
      .catch(err => reject(err));
  });
}

export function unfollowUser(bearer: string, userId: number): Promise<number> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/User/Unfollow',
      params: {
        id: userId,
      },
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then(() => resolve(userId))
      .catch(err => reject(err));
  });
}

export function searchUsers(
  bearer: string,
  searchBy: number,
  elementType: number,
  elementId: number,
  concept: string,
  filterOnlyMembers?: boolean,
): Promise<{ count: number; list: SearchUser[] }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: '/api/User/Search',
      data: {
        searchBy: searchBy,
        elementType: elementType,
        elementId: elementId,
        skip: 0,
        sortBy: 1,
        take: (!filterOnlyMembers && 20) || 0,
        concept: concept,
      },
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then(response => {
        resolve({
          count: response.data.count,
          list: response.data.list
            .filter((user: any) => !filterOnlyMembers || user.isAlreadyMember)
            .map((user: any) => {
              return {
                ...user,
                email: user.mail,
              };
            }),
        });
      })
      .catch(err => reject(err));
  });
}

export function sendMailToUser(
  bearer: string,
  userId: number,
  message: string,
  recaptchaResponse: string,
): Promise<null> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: '/api/Message/SendMessageToUser',
      data: {
        userId,
        message,
        recaptchaResponse,
      },
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then(response => {
        resolve(null);
      })
      .catch(err => reject(err));
  });
}

export function sendGroupMessage(
  bearer: string,
  userList: number[],
  message: string,
  recaptchaResponse: string,
): Promise<null> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: '/api/Message/SendGroupMessageToUser',
      data: {
        message,
        recaptchaResponse,
        userList: userList,
      },
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then(response => {
        resolve(null);
      })
      .catch(err => reject(err));
  });
}

export function sendReportMessage(bearer: string, message: string, recaptchaResponse: string): Promise<null> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: '/api/Message/SendReportMessage',
      data: {
        message,
        recaptchaResponse,
      },
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then(response => {
        resolve(null);
      })
      .catch(err => reject(err));
  });
}
export function reportIssue(bearer: string, message: string, recaptchaResponse: string): Promise<null> {
  // id = 1 admin@babele.co
  // id = 400545 support@babele.co
  return new Promise((resolve, reject) => {
    sendReportMessage(bearer, message, recaptchaResponse)
      .then(() => {
        resolve(null);
      })
      .catch(err => {
        reject(err);
      });
  });
}

export function sendMailToCircleUsers(
  bearer: string,
  entityType: string,
  entityId: number,
  circlesId: number[],
  message: string,
  recaptchaResponse: string,
): Promise<null> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: `/api/Message/SendMessageTo${entityType}Circles`,
      data: {
        circlesId,
        entityId,
        message,
        recaptchaResponse,
      },
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then(response => {
        resolve(null);
      })
      .catch(err => reject(err));
  });
}

export default {
  fetchUserProfile,
  followUser,
  unfollowUser,
  searchUsers,
  sendMailToUser,
  sendMailToCircleUsers,
};
