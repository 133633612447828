import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { RootState } from 'StoreModel';
import {
  SidebarItem,
  SidebarList,
  SidebarContainer,
  SidebarItemText,
  CommunitySidebarTabItem,
  SidebarLogo,
} from '../styled-components/sidebar';
import { Separator } from '../styled-components/common';
import { Logo } from '../common/logo';
import Image from '../common/lazyImage';
import { scrollLayoutToTop } from 'util/utils';
import { ReactComponent as FeedIcon } from 'assets/sidebar-icons/home.svg';
import { ReactComponent as ProjectsIcon } from 'assets/sidebar-icons/bulb.svg';
import { ReactComponent as DiscussionsIcon } from 'assets/sidebar-icons/comment-alt.svg';
import { ReactComponent as MembersIcon } from 'assets/sidebar-icons/users-alt.svg';
import { ReactComponent as ResourcesIcon } from 'assets/sidebar-icons/folders.svg';
import { ReactComponent as EventsIcon } from 'assets/sidebar-icons/calendar-clock.svg';
import { ReactComponent as RequestsIcon } from 'assets/sidebar-icons/comments-question-check.svg';
import { ReactComponent as ApplicationsIcon } from 'assets/sidebar-icons/filter.svg';
import { ReactComponent as SettingsIcon } from 'assets/sidebar-icons/settings.svg';
import { ReactComponent as AboutIcon } from 'assets/sidebar-icons/info.svg';
import { ReactComponent as TabIcon } from 'assets/sidebar-icons/rocket-launch.svg';
import styled from 'styled-components';
import { fetchCommunityTabsAsync } from 'redux/actions/tab';
import usePathParams from 'hooks/usePathParams';
import { Community, Literals } from 'redux/types/account';
import { Flag, Languages } from 'redux/types/enums';
import { getLiterals } from '../../services/literals';

export const StyledLogo = styled(Logo)<{ $shrinked?: boolean }>`
  ${({ $shrinked }) =>
    $shrinked &&
    `
        width: 1.5em;
        height: 1.5em;
        border-radius: 0.5em;
    `}
`;
export const StyledHeader = styled('div')``;
export const StyledMain = styled('div')``;

const mapDispatchToProps = {
  fetchTabs: fetchCommunityTabsAsync.request,
};

const mapStateToProps = (state: RootState) => ({
  selectedCommunity: state.account.selectedCommunity,
  notifications: state.account.details.notifications,
  isLiteralsLoaded: state.loading.getLiteralsFlag,
  literals: state.literals,
  bearer: state.account.session.session.bearer,
  userLanguage: state.account.details.user.userLanguage as Languages,
});

type dispatchType = typeof mapDispatchToProps;

interface IProps extends ReturnType<typeof mapStateToProps>, dispatchType {
  /** Styles passed to the outer container */
  style?: React.CSSProperties;
  /**
   * Controls wether the displayed sidebar will be in shrinked mode
   * Shrink mode displays smaller version of logo and icons only tabs
   * */
  shrinked?: boolean;
  /** User token */
  bearer: string;
}

const CommunitySidebar: React.FC<IProps> = props => {
  const { selectedCommunity, literals, style, shrinked, fetchTabs, bearer, isLiteralsLoaded, userLanguage } = props;
  const [loadingLiterals, setLoadingLiterals] = useState<boolean>(true);
  const [customLiterals, setCustomLiterals] = useState<Literals>({});
  const { pathname } = useLocation();
  const { entityId } = usePathParams(pathname);
  const { communityId } = useParams<{ communityId: string }>();
  const community: Community | null = useMemo(() => selectedCommunity, [communityId, selectedCommunity]);
  const tabs: CommunitySidebarTabItem[] = useMemo(
    () => community?.tabs?.map(tab => ({ ...tab, url: `/community/${communityId}/tab/${tab.id}` })) ?? [],
    [community?.tabs],
  );
  const ICON_PROPS = {
    width: '1.3em',
    height: '1.3em',
    fill: 'rgb(55, 96, 146)',
  };

  useEffect(() => {
    if (community) {
      communityId && !tabs.length && fetchTabs({ bearer, communityId: +communityId });
    }
  }, [community?.id]);

  useEffect(() => {
    if (isLiteralsLoaded === Flag.Request) {
      console.log('UE1');
      setLoadingLiterals(true);
    }
  }, [isLiteralsLoaded]);

  useEffect(() => {
    if (loadingLiterals) {
      const communityIdNumber = parseInt(communityId);
      getLiterals(userLanguage || 'en-EN', communityIdNumber, bearer).then(response => {
        console.log('ST1');
        setCustomLiterals(response.literals);
        setLoadingLiterals(false);
      });
    }
  }, [communityId, loadingLiterals, userLanguage, bearer]);

  const showTabEntitySidebar = useMemo(() => !!entityId, [entityId]);

  if (!community) {
    return null;
  }

  return (
    <SidebarContainer style={{ ...style, transition: 'width 0.4s linear' }}>
      {showTabEntitySidebar && <StyledHeader id="community-sidebar-header-portal" />}
      {showTabEntitySidebar && <StyledMain id="community-sidebar-main-portal" />}
      {!showTabEntitySidebar && !loadingLiterals && isLiteralsLoaded === Flag.Success && (
        <SidebarList
          data-cy="community-sidebar-list"
          style={{
            ...(shrinked ? { alignItems: 'center' } : {}),
          }}
          className="sidebar"
        >
          <SidebarLogo shrinked={!!shrinked}>
            <StyledLogo data-cy="community-sidebar-logo" $shrinked={shrinked} loading="eager" src={community.logo} />
          </SidebarLogo>
          <div style={{ marginTop: '1em' }} />
          {community.isHomeTabVisible && (
            <SidebarItem onClickCapture={scrollLayoutToTop} to={`/community/${communityId}`}>
              <FeedIcon id={literals.menu_home} style={{ ...ICON_PROPS, height: '1.4em', width: '1.4em' }} />
              {(!shrinked && literals.menu_home) || ''}
            </SidebarItem>
          )}
          {community.isProjectsTabVisible && (
            <SidebarItem onClickCapture={scrollLayoutToTop} to={`/community/${communityId}/projects`}>
              <ProjectsIcon id={customLiterals.primitive_startups} {...ICON_PROPS} />
              {(!shrinked &&
                customLiterals.primitive_startups[0].toUpperCase() + customLiterals.primitive_startups.slice(1)) ||
                ''}
            </SidebarItem>
          )}
          {community.isForumTabVisible && (
            <SidebarItem onClickCapture={scrollLayoutToTop} to={`/community/${communityId}/discussions`}>
              <DiscussionsIcon id={literals.community_menu_forum_option} {...ICON_PROPS} />
              {(!shrinked && (literals.community_menu_forum_option || 'Forum')) || ''}
            </SidebarItem>
          )}
          {community.isMembersTabVisible && (
            <SidebarItem onClickCapture={scrollLayoutToTop} to={`/community/${communityId}/members`}>
              <MembersIcon id={literals.community_menu_members_option} {...ICON_PROPS} />
              {(!shrinked && literals.community_menu_members_option) || ''}
            </SidebarItem>
          )}
          {community.isResourcesTabVisible && (
            <SidebarItem onClickCapture={scrollLayoutToTop} to={`/community/${communityId}/resources`}>
              <ResourcesIcon id={literals.community_menu_resources_option} {...ICON_PROPS} />
              {(!shrinked && literals.community_menu_resources_option) || ''}
            </SidebarItem>
          )}
          {community.isMember && community.isEventsTabVisible && (
            <SidebarItem onClickCapture={scrollLayoutToTop} to={`/community/${communityId}/events`}>
              <EventsIcon id={literals.events_title} {...ICON_PROPS} />
              {(!shrinked && literals.events_title) || ''}
            </SidebarItem>
          )}
          {community.isRequestsTabVisible && (
            <SidebarItem onClickCapture={scrollLayoutToTop} to={`/community/${communityId}/requests`}>
              <RequestsIcon id={literals.sidebar_option_requests} {...ICON_PROPS} />
              {(!shrinked && literals.sidebar_option_requests) || ''}
            </SidebarItem>
          )}
          {community.isApplicationsTabVisible && community.canViewApplicationsTab && (
            <SidebarItem
              onClickCapture={scrollLayoutToTop}
              to={`/community/${communityId}/application-definitions`}
              alternativeActivePaths={[`/community/${communityId}/application-definition`]}
            >
              <ApplicationsIcon id={literals.sidebar_applications} {...ICON_PROPS} />
              {(!shrinked && literals.sidebar_applications) || ''}
            </SidebarItem>
          )}
          {!!tabs?.length &&
            tabs.map((tab, index) => (
              <SidebarItem key={tab.id} onClickCapture={scrollLayoutToTop} to={tab.url}>
                {tab.icon ? (
                  <Image
                    customSize={'32x32'}
                    alt={`logo-${tab.id}`}
                    src={tab.icon}
                    onError={(event: any) => (event.target.src = TabIcon)}
                  />
                ) : (
                  <TabIcon id={`tab-icon_${tab.id}`} {...ICON_PROPS} />
                )}
                {!shrinked && <SidebarItemText>{tab.name}</SidebarItemText>}
              </SidebarItem>
            ))}

          {community.isAboutTabVisible && (
            <SidebarItem onClickCapture={scrollLayoutToTop} to={`/community/${communityId}/about`}>
              <AboutIcon id={literals.community_menu_about_option} {...ICON_PROPS} />
              {(!shrinked && literals.community_menu_about_option) || ''}
            </SidebarItem>
          )}
          <Separator />
          {community.canEditCommunityInfo && (
            <SidebarItem
              testId="community-settings"
              onClickCapture={scrollLayoutToTop}
              to={`/community/${communityId}/admin-panel/community-settings`}
            >
              <SettingsIcon id={literals.community_menu_admin_panel} {...ICON_PROPS} />
              {literals.community_menu_admin_panel || ''}
            </SidebarItem>
          )}
          {community?.canSeeProjectOverview && !community?.canEditCommunityInfo && (
            <SidebarItem
              onClickCapture={scrollLayoutToTop}
              to={`/community/${communityId}/overview-projects`}
              hasNotifications={false}
            >
              <SettingsIcon id={literals.community_menu_admin_panel} {...ICON_PROPS} />
              {(!shrinked && literals.primitive_startups) || ''}
            </SidebarItem>
          )}
        </SidebarList>
      )}
      <div id="community-sidebar-footer-portal" />
    </SidebarContainer>
  );
};

CommunitySidebar.displayName = 'CommunitySidebar';
export default connect(mapStateToProps, mapDispatchToProps)(CommunitySidebar);
