import React, { useEffect, useState, useRef, useMemo } from 'react';
import styled from 'styled-components';
import { MAXIMUM_PARTICIPANTS } from './add-coaching-modal';
import { toast } from 'components/common/toast';
import { Community, Member } from 'redux/types/account';

const Container = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  display: flex;
  background: white;
  max-height: 200px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  padding-bottom: 1em;
  z-index: 1;
`;

const Section = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding-top: 10px;
  &:first-child {
    border-right: 2px solid ${({ theme }) => theme.colors.grey.dark};
  }
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  align-items: center;

  $:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.grey.light};
  }
`;

const MemberName = styled.span<{ $selected?: boolean }>`
  color: ${({ theme }) => theme.colors.grey.dark};
  flex: 1;
  margin-left: 1em;
  padding: 5px;
  background-color: ${({ $selected }) => ($selected ? ({ theme }) => theme.colors.blue.veryLight : 'transparent')};
  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.blue.veryLight};
  }
`;

const CircleName = styled.span<{ $selected?: boolean }>`
  color: ${({ theme }) => theme.colors.grey.dark};
  flex: 1;
  margin-left: 14px;
  padding: 5px;
  background-color: ${({ $selected }) => ($selected ? ({ theme }) => theme.colors.blue.veryLight : 'transparent')};
  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.blue.veryLight};
  }
`;

const UserImage = styled.img`
  width: 2em;
  height: 2em;
  border-radius: 50%;
  cursor: pointer;
`;

type ProjectMember = { id: number; name: string; photo: string; circle: { id: number } };

interface Props {
  circles: { id: number; name: string; userCount?: number }[];
  teamCircles: { id: number; name: string; userCount?: number }[];
  fetchMembers: (circleId: number, skip: number) => Promise<{ id: number; name: string; photo: string }[]>;
  fetchCircles: () => void;
  getInviteesCallback: (invitees: { id: number; name: string; photo: string }[]) => void;
  children: React.ReactElement;
  parentMembers: { id: number; name: string; photo: string }[];
  currentUserId: number;
  searchResults: Member[];
  fetchTeam: () => Promise<{ id: number; name: string; photo: string; circle: { id: number } }[]>;
  community: Community;
}
const projectCircles = ['Team', 'Mentors'];

const ToggleMembers: React.FC<Props> = props => {
  const { circles, fetchMembers, currentUserId, searchResults, community } = props;
  const [showList, setShowList] = useState<boolean>(false);
  const [currentCircleId, setCurrentCircleId] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const rightSectionRef = useRef(null);
  const [members, setMembers] = useState<{ [circleId: number]: { id: number; name: string; photo: string }[] }>({});
  const [team, setTeam] = useState<{ [circleId: number]: { id: number; name: string; photo: string }[] }>({});
  const [invitees, setInvitees] = useState<{
    [circleId: number]: { [memberId: number]: { id: number; name: string; photo: string } };
  }>({});
  const [currentUserProjectCircleName, setCurrentUserProjectCircleName] = useState<string>('');
  // These are users in the same project circle as the current user, so they shouldn't appear
  // in the dropdowns or the search results. Team members can't invite other team members, mentors
  // cannot coach other mentors
  const [usersNotAllowed, setUsersNotAllowed] = useState<number[]>([]);

  const allowedProjectCircles = useMemo(
    () =>
      props.teamCircles.filter((c: { name: string }) => {
        return projectCircles.includes(c.name) && c.name !== currentUserProjectCircleName;
      }),
    [props.teamCircles, currentUserProjectCircleName],
  );

  // We shouldn't allow inviting the same circle. A team member can invite other circles with permissions
  // and other circles that can coach can only invite team members
  const allowedCircleToBeInvited = useMemo(() => {
    // If it's a team member, invite either the community circles that have permissions
    // or the remaining allowed project circles, which is just the mentor one
    if (currentUserProjectCircleName === 'Team') {
      return [
        ...props.circles.map(circle => ({
          ...circle,
          name: community ? `${community.name} ${circle.name}` : circle.name,
        })),
        ...allowedProjectCircles,
      ];
    }

    // If it's not a team member scheduling, then this is someone with coaching permissions that's
    // creating the session, so it can only invite team members to be coached
    return allowedProjectCircles.filter(({ name }) => name === 'Team');
  }, [props.circles, allowedProjectCircles, currentUserProjectCircleName]);

  useEffect(() => {
    if (allowedCircleToBeInvited.length) {
      setCurrentCircleId(allowedCircleToBeInvited[0].id);
    }
  }, [allowedCircleToBeInvited]);

  useEffect(() => {
    if (!circles || !circles.length) props.fetchCircles();
    const handleCloseModalOnClick = (event: any) => {
      let currentNode = event.target;
      while (currentNode) {
        if ((currentNode as any).id === 'toggle-members-modal' || (currentNode as any).id === 'toggle-dropdown') {
          return;
        }
        currentNode = (currentNode as any).parentNode;
      }
      setShowList(false);
    };
    document.body.addEventListener('click', handleCloseModalOnClick);
    return () => {
      document.body.removeEventListener('click', handleCloseModalOnClick);
    };
  }, []);

  const isTeamMember = (member: Member) =>
    Object.keys(team).some((circleId: string) =>
      team[Number(circleId)].some((tm: { id: number }) => tm.id === member.id),
    );

  const filteredSearchResults = useMemo(
    () =>
      searchResults.filter((result: Member) => {
        const isInAllowedCircles = allowedCircleToBeInvited.find(
          (circle: { id: number }) => circle.id === result.circle?.id,
        );

        return (isInAllowedCircles || isTeamMember(result)) && !usersNotAllowed.find(id => result.id === id);
      }),
    [searchResults, allowedCircleToBeInvited],
  );

  useEffect(() => {
    const parentMembersObject: { [id: number]: boolean } = props.parentMembers.reduce(
      (acc, val) => ({
        ...acc,
        [val.id]: true,
      }),
      {},
    );

    setInvitees(invitees =>
      Object.keys(invitees).reduce(
        (acc: object, val) => ({
          ...acc,
          [Number(val)]: Object.keys(invitees[Number(val)]).reduce(
            (acc2, val2) =>
              !parentMembersObject[invitees[Number(val)][Number(val2)].id]
                ? acc2
                : { ...acc2, [Number(val2)]: invitees[Number(val)][Number(val2)] },
            {},
          ),
        }),
        {},
      ),
    );
  }, [props.parentMembers]);

  useEffect(() => {
    const blankInviteesObject = (allowedCircleToBeInvited || []).reduce(
      (acc, circle) => ({ ...acc, [circle.id]: {} }),
      {},
    );
    setInvitees({ ...blankInviteesObject, ...invitees });
  }, [circles, allowedProjectCircles]);
  useEffect(() => {
    props.fetchTeam().then((members: ProjectMember[]) => {
      const currentUser = members.find(({ id }) => id === currentUserId);
      const currentUserProjCircle = currentUser
        ? props.teamCircles.find(({ id }) => id === currentUser.circle.id)
        : null;

      if (currentUserProjCircle) {
        setCurrentUserProjectCircleName(currentUserProjCircle.name);
      }

      const allowedProjectCirclesId = allowedProjectCircles.map((c: { id: number }) => c.id);
      const structuredMembers: { [circleId: number]: { id: number; name: string; photo: string }[] } = {};
      const projectMembersNotAllowedToBeInvited: number[] = [];

      members.forEach((member: { circle: { id: number }; id: number; name: string; photo: string }) => {
        const userNotAllowed = currentUserProjCircle && member.circle.id === currentUserProjCircle.id;

        if (allowedProjectCirclesId.includes(member.circle.id) && !userNotAllowed) {
          if (structuredMembers[member.circle.id]) {
            structuredMembers[member.circle.id].push(member);
          } else {
            structuredMembers[member.circle.id] = [member];
          }
        }

        if (userNotAllowed) {
          projectMembersNotAllowedToBeInvited.push(member.id);
        }
      });
      setTeam(structuredMembers);
      setUsersNotAllowed(projectMembersNotAllowedToBeInvited);
    });
  }, [allowedProjectCircles]);

  useEffect(() => {
    // no more than 5 participants, so this operation shouldnt be expensive
    let inviteeList: { id: number; name: string; photo: string }[] = [];
    Object.keys(invitees).forEach(circleInvitees => {
      Object.keys(invitees[Number(circleInvitees)]).forEach(invitee => {
        inviteeList.push(invitees[Number(circleInvitees)][Number(invitee)]);
      });
    });
    inviteeList = inviteeList.sort((a: { id: number }, b: { id: number }) => a.id - b.id);
    let skipInvite = false;
    if (inviteeList.length === props.parentMembers.length) {
      skipInvite = true;
      for (let i = 0; i < inviteeList.length; i++) {
        if (inviteeList[i].id !== props.parentMembers[i].id) {
          skipInvite = false;
        }
      }
    }
    if (skipInvite) return;
    props.getInviteesCallback(inviteeList);
  }, [invitees]);

  useEffect(() => {
    if (rightSectionRef.current) {
      (rightSectionRef.current as any).scrollTo(0, 0);
    }
    if (!members[currentCircleId] && !team[currentCircleId]) {
      fetchMembers(currentCircleId, 0).then(response => {
        setMembers({ ...members, [currentCircleId]: response.filter(({ id }) => !usersNotAllowed.includes(id)) });
      });
    }
  }, [currentCircleId, usersNotAllowed]);

  const handleSectionScroll = (event: any) => {
    if (isLoading) return;
    if (!members[currentCircleId]) return;

    const scrollTop = (rightSectionRef.current as any).scrollTop;
    const clientHeight = (rightSectionRef.current as any).clientHeight;
    const scrollHeight = (rightSectionRef.current as any).scrollHeight;
    if (clientHeight + scrollTop + 10 >= scrollHeight) {
      const currentCircle = circles.find((circle: { id: number }) => circle.id === currentCircleId);
      if (currentCircle && currentCircle.userCount === (members[currentCircleId] || []).length) return;

      setIsLoading(true);
      fetchMembers(currentCircleId, (members[currentCircleId] || []).length).then(response => {
        setMembers(members => ({
          ...members,
          [currentCircleId]: [
            ...(members[currentCircleId] || []),
            ...response.filter(({ id }) => !usersNotAllowed.includes(id)),
          ],
        }));
        setIsLoading(false);
      });
    }
  };

  const handleSelectCircle = (circleId: number) => {
    if (rightSectionRef.current) {
      (rightSectionRef.current as any).scrollTo(0, 0);
    }
    setCurrentCircleId(circleId);
  };

  const isMemberSelected = (id: number): boolean => {
    if (invitees && invitees[currentCircleId] && invitees[currentCircleId][id]) {
      return true;
    }
    return false;
  };

  const canInviteMore =
    Object.keys(invitees).reduce((acc, val) => acc + Object.keys(invitees[Number(val)]).length, 0) <
    MAXIMUM_PARTICIPANTS - 1;
  return (
    <>
      {React.cloneElement(props.children, {
        onClick: () => {
          setShowList(!showList);
        },
        id: 'toggle-dropdown',
      })}
      <Container id="toggle-members-modal" style={showList || filteredSearchResults.length ? {} : { display: 'none' }}>
        {filteredSearchResults.length ? (
          <Section>
            {filteredSearchResults
              .filter((member: Member) => !invitees[member.circle.id]?.[member.id])
              .map((member: Member) => (
                <Row
                  style={{ marginBottom: '0.2em' }}
                  tabIndex={0}
                  id={`coaching-search-result-${member.id}`}
                  onClick={() => {
                    if (!canInviteMore) {
                      toast(`Coaching sessions can have maximum ${MAXIMUM_PARTICIPANTS} participants`, {
                        type: 'warning',
                      });
                      return;
                    }

                    setInvitees({
                      ...invitees,
                      [currentCircleId]: {
                        ...invitees[currentCircleId],
                        [member.id]: member,
                      },
                    });
                  }}
                  key={member.id}
                >
                  <UserImage src={member.photo} />
                  <MemberName>{member.name}</MemberName>
                </Row>
              ))}
          </Section>
        ) : (
          <>
            <Section>
              {allowedCircleToBeInvited.map((circle: { id: number; name: string; userCount?: number }) => {
                if (circle.userCount) {
                  return (
                    <Row key={circle.id}>
                      <CircleName
                        $selected={currentCircleId === circle.id}
                        onClick={() => handleSelectCircle(circle.id)}
                      >
                        {circle.name}
                      </CircleName>
                    </Row>
                  );
                }
                return null;
              })}
            </Section>
            <Section onScroll={handleSectionScroll} ref={rightSectionRef}>
              {(members[currentCircleId] || team[currentCircleId] || []).map(
                (member: { id: number; name: string; photo: string }) => (
                  <Row key={member.id}>
                    <MemberName
                      $selected={isMemberSelected(member.id)}
                      onClick={() => {
                        if (member.id === currentUserId) return;

                        setInvitees({
                          [currentCircleId]: {
                            [member.id]: member,
                          },
                        });

                        setShowList(false);
                      }}
                    >
                      {member.name}
                    </MemberName>
                  </Row>
                ),
              )}
            </Section>
          </>
        )}
      </Container>
    </>
  );
};

export default ToggleMembers;
