import { Session } from 'redux/types/account';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { getAxiosInstance } from './helper';

const ai = getAxiosInstance();

export function login(user: { email: string; password: string; recaptchaResponse: string }): Promise<Session> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: '/token',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
      },
      data: `password=${encodeURIComponent(user.password)}&username=${encodeURIComponent(
        user.email,
      )}&grant_type=password&recaptchaResponse=${encodeURIComponent(user.recaptchaResponse)}`,
    })
      .then((response: AxiosResponse) => {
        const { data } = response;
        resolve({
          bearer: data.access_token,
          refresh: data.refresh_token,
        });
      })
      .catch(err => {
        reject(err);
      });
  });
}

export function saveTokenToState(token: string): Promise<Session> {
  return new Promise(resolve => {
    resolve({
      bearer: token,
      refresh: '',
    });
  });
}

type NewUser = {
  firstName: string;
  lastName: string;
  mail: string;
  password: string;
  primaryCommunityId?: number;
  recaptchaResponse: string;
  appId: string | null;
  redirectAfterFirstLogin: string | null;
  language: string;
};

export function signupApi(user: NewUser): Promise<any> {
  if (user.primaryCommunityId === 0) {
    delete user.primaryCommunityId;
  }
  const payload: AxiosRequestConfig = {
    method: 'POST',
    url: '/api/User/SignUp',
    data: {
      ...user,
      newPlatform: true,
    },
  };
  if (user.appId) {
    payload.params = { appId: user.appId };
    payload.url = '/api/User/SignUpViaApplication';
  }
  return new Promise((resolve, reject) => {
    ai(payload)
      .then(response => resolve(response.data))
      .catch(err => reject(err));
  });
}

export function googleSignInFetch({ credential, clientId, targetCommunityId }: any): Promise<{ token: string }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'POST',
      url: '/api/Google/SignIn',
      data: { credential, clientId, targetCommunityId },
    })
      .then(response => {
        resolve(response.data?.token);
      })
      .catch(err => reject(err));
  });
}

export function activate(code: string): Promise<null> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: `/api/User/Activate?code=${code}`,
    })
      .then(() => resolve(null))
      .catch(err => reject(err));
  });
}

export function forgotPassword(email: string): Promise<null> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/User/SendResetPasswordMail',
      params: {
        mail: email,
      },
    })
      .then(() => resolve(null))
      .catch(err => reject(err));
  });
}

export function resetPassword(activationCode: string, newPassword: string): Promise<null> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: '/api/User/ResetPassword',
      params: {
        activationCode,
        password: newPassword,
      },
    })
      .then(() => resolve(null))
      .catch(err => reject(err));
  });
}

export default {
  googleSignInFetch,
  login,
  signupApi,
  activate,
  forgotPassword,
  resetPassword,
};
