import React from 'react';
import { ShowDock, MainContainer, CoverComponent, DockContainer } from './common';
import { SidebarContainer } from '../layout/common';
import Sidebar from '../components/common/sidebar';
import Panels from '../components/common/panels';
import styled from 'styled-components';
import useWindowDimensions from '../util/windowSize';
import { LayoutType } from 'redux/types/account';
import { useParams } from 'react-router-dom';
import Dock from '../components/common/dock';

const StyledSidebar = styled(Sidebar)<{ $isCoverLayout?: boolean }>`
  &&& {
    top: 0em;
    position: sticky;
    height: calc(100vh - ${({ $isCoverLayout }) => ($isCoverLayout ? '10em' : '0em')});
  }
`;

const StyledSidebarContainer = styled(SidebarContainer)<{ $isCoverLayout?: boolean; $shrinked?: boolean }>`
  top: 0em;
  z-index: 1;
  ${({ $shrinked }) =>
    $shrinked &&
    `
      width: 5em;
      margin-left: 0;
      `}
  ${({ $isCoverLayout }) =>
    $isCoverLayout &&
    `
  &&& {
    top: -10em;
    background-color: transparent;
    z-index: 1;
  }`}
  ${({ theme }) => `
  @media (max-width: ${theme.sizes.mobileWidth}) {
    &&& {
      background-color: #fff;
      top: 0;
      z-index: 10;
      left: calc(100% - 8em);
    }
  }
  `}
`;

const Container1 = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${({ theme }) => `
  @media (max-width: ${theme.sizes.mobileWidth}) {
    &&& {
      overflow-x: hidden;
    }
  }
  `}
`;

interface Props {
  type: LayoutType;
  showPreviewComponent?: boolean;
  previewComponent?: any;
}

const Layout: React.FC<Props> = props => {
  const { type, previewComponent, showPreviewComponent } = props;
  const windowSize = useWindowDimensions();
  const { communityId } = useParams<{ communityId?: string }>();
  const isOfType = (...types: string[]) => {
    return types.includes(type);
  };

  const _getMainContainerStyles = () => {
    if (windowSize.isMobile) {
      return {};
    }
    if (isOfType('2-columns', 'settings', 'super-admin')) {
      if (showPreviewComponent) {
        return {
          margin: '0 0 0 10em',
          width: 'calc(100% - calc(10em + min(calc(100% - 52em), 40em)))',
          maxWidth: '100%',
        };
      }
      return {
        margin: '0 0 0 20em',
        width: 'calc(100% - 20em)',
        overflow: 'auto',
      };
    } else if (isOfType('full-screen')) {
      if (showPreviewComponent) {
        return {
          width: '100%',
          maxWidth: '45em',
        };
      }
      return {
        width: '100%',
      };
    } else if (isOfType('1-column')) {
      if (showPreviewComponent) {
        return {
          margin: '0 0 0 5em',
          width: 'calc(100% - 10em)',
          maxWidth: '40em',
        };
      }
      return {
        margin: '0 0 0 5em',
        width: 'calc(100% - 5em)',
      };
    }
    // default case: 3 columns
    if (showPreviewComponent) {
      return {
        margin: '0 0 0 10em',
        width: 'calc(100% - 10em)',
        maxWidth: '40em',
      };
    }
    return {
      margin: '0 20em',
      width: 'calc(100% - 40em)',
    };
  };

  return (
    <React.Fragment>
      {!isOfType('full-screen') && <ShowDock />}
      <DockContainer id="dock-container">
        <Dock />
      </DockContainer>
      <Container1 className="babele-layout-outer-container">
        {isOfType('3-columns-cover') && <CoverComponent communityId={Number(communityId) || 0} />}
        <div style={windowSize.isMobile ? {} : { position: 'relative', width: '100%' }}>
          {isOfType('2-columns', '3-columns', '3-columns-cover', 'settings', 'super-admin') && (
            <StyledSidebarContainer $shrinked={showPreviewComponent} $isCoverLayout={isOfType('3-columns-cover')}>
              <StyledSidebar shrinked={showPreviewComponent} $isCoverLayout={isOfType('3-columns-cover')} type={type} />
            </StyledSidebarContainer>
          )}
          <MainContainer className="babele-layout-main-container" style={_getMainContainerStyles()}>
            {props.children}
          </MainContainer>
          {isOfType('3-columns', '3-columns-cover') && (
            <SidebarContainer
              style={
                windowSize.isMobile || showPreviewComponent
                  ? { display: 'none' }
                  : {
                      right: '0',
                      top: '0',
                      left: 'unset',
                      width: 'min(calc(100% - 51em),19em)',
                      background: 'transparent',
                    }
              }
            >
              <Panels style={{ top: '0', overflow: 'unset' }} isCommunityLayout={true} />
            </SidebarContainer>
          )}
          {previewComponent}
        </div>
      </Container1>
    </React.Fragment>
  );
};

export default Layout;
