import {
  ChartTypesEnum,
  FormQuestionAnswerType,
  ImageType,
  Languages,
  MeetingProvider,
  RequestStatusEnum,
  StatsType,
} from '../redux/types/enums';
import { genericUploadFile, uploadFile } from '../services/api/file-upload';
import {
  Author,
  Comment,
  Country,
  Filtering,
  Form,
  FormQuestion,
  FormQuestionAnswer,
  KPIList,
  KPIValue,
  Literals,
  Report,
} from 'redux/types/account';
import { fillEmptyKpis, getDevelopmentStateList, getMonthName, getQuarterName } from '../services/api/helper';
import { toast } from 'components/common/toast';
import { SelectedFile } from 'redux/types/file-upload';
import { COVER_LOGO_MAX_FILE_SIZE, MAX_FILE_SIZE } from 'redux/types/default-types-values';
import { useState } from 'react';
const dateFormatOptions: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: '2-digit' };

export const InvalidInputs = [
  '#',
  '$',
  '%',
  '[',
  ']',
  "'",
  '`',
  '+',
  '-',
  '>',
  '<',
  '(',
  ')',
  '~',
  '@',
  '+',
  '{',
  '}',
  '?',
  '!',
  '/',
];

export function preventInvalidInputs(e: KeyboardEvent) {
  if (InvalidInputs.includes(e.key)) {
    e.preventDefault();
  }
}

const ImageExtensions: string[] = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg', 'webp', 'tiff', 'ico'];

const ImageMimeTypes: string[] = [
  'image/apng',
  'image/bmp',
  'image/gif',
  'image/jpeg',
  'image/png',
  'image/svg+xml',
  'image/tiff',
  'image/webp',
];

export const getResourceLink = (url: string) => {
  if (url.includes('resource_files')) return url.split('/').pop();
  return url;
};

export const isCustomImageResource = (url: string) =>
  isImageUrl(url) && /^(\/|\\)Images(\/|\\)Uploads(\/|\\)resource_files(\/|\\)/gi.test(url);

export const getAllowedFileTypes = () => {
  return 'application/json,application/ld+json,application/msword,application/ogg,application/pdf,application/rtf,application/vnd.mozilla.xul+xml,application/vnd.ms-excel,application/vnd.ms-powerpoint,application/vnd.oasis.opendocument.presentation,application/vnd.oasis.opendocument.spreadsheet,application/vnd.oasis.opendocument.text,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/xml,application/x-yaml,audio/3gpp,audio/3gpp2,audio/aac,audio/midi audio/x-midi,audio/mpeg,audio/ogg,audio/opus,audio/wav,audio/webm,font/otf,font/ttf,font/woff,font/woff2,image/bmp,image/gif,image/jpeg,image/png,image/svg+xml,image/tiff,image/vnd.microsoft.icon,image/webp,text/csv,text/plain,text/xml,text/yaml,video/3gpp,video/3gpp2,video/mp2t,video/mp4,video/mpeg,video/ogg,video/webm';
};

export const isImage = (fileType: string) => ImageMimeTypes.includes(fileType);

export const isImageUrl = (url: string) => ImageExtensions.includes(url?.split('.').pop() || '');

export const handleSetForm = (form: Form, setForm: any) => {
  setForm({
    ...form,
    questions: form.questions.map((formQuestion: FormQuestion) => ({
      ...formQuestion,
      title: formQuestion.title.trim(),
      question: formQuestion?.question ? formQuestion.question.trim() : '',
    })),
    name: form.name.trim(),
  });
};

export const isFormInvalid = (form: Form) => {
  return form && (form.questions.some((question: FormQuestion) => !question.title) || !form.name);
};

export const truncateString = (str: string, size: number): string => {
  if (str.length < size) {
    return str;
  } else {
    const words = str.split(' ');
    let output = '';
    let limitReached = false;
    if (words[0].length > size) {
      return output;
    } else {
      words.forEach((word: string) => {
        if (output.length + word.length <= size && !limitReached) {
          output += word;
          output += ' ';
        } else {
          limitReached = true;
        }
      });
      return `${output.trim()} ...`;
    }
  }
};

export const getSDGIconPath = (iconName: string) =>
  iconName ? `/Images/branding.babele/SDGs/Icons_Square/${iconName}.png` : '';

export const convertArrayToQueryParam = (array: string[] = [], key: string): string => {
  let queryParams = '';
  if (array) {
    queryParams = array.reduce((prev, curr = '') => prev + `&${key}=${curr}`, '');
  }

  return queryParams.replace('&', '?');
};

export const isStagingOrLocal = (): boolean => {
  return window.location.hostname === 'app-stag.babele.co' || window.location.hostname === 'localhost';
};

export const makeId = (length: number) => {
  let result = '';
  const characters = '123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return Number(result) * -1;
};

export const isLink = (link: string) => {
  return /^(ftp|http|https|www):\/\/[^ "]+$/.test(link);
};

const appendFileAnchorToEditor = (bearer: string, editorContentSetter: any, toastMessage: string = '') => {
  const fileInput = document.createElement('input');
  fileInput.type = 'file';
  fileInput.style.display = 'none';
  fileInput.addEventListener('change', event => {
    const file = (event.target as any).files[0];
    if (file) {
      genericUploadFile(bearer, file, true)
        .then(response => {
          // When an editor is focused, the toolbar appears. Since there can not be multiple
          // focused editors, the last one that entered on focus should override window.lastFocusedEditorSetter
          // with its own content setter. This way there will be a connection between the editor
          // that triggered the toolbar and the attach button. When the upload is ready, the anchor
          // will be attached to the last focused editor (the one that triggered the toolbar)
          if (editorContentSetter)
            editorContentSetter(
              (content: any) => content + `<a href='${window.origin}/${response.url}'>📄${response.fileName}</a>`,
            );
        })
        .catch(() => {
          toastMessage && toast(toastMessage, { type: 'error' });
        });
    }
  });
  document.body.appendChild(fileInput);
  fileInput.click();
};

const createAttachButton = (bearer: string, toastMessage: string) => {
  const wrapper = document.createElement('div');
  wrapper.style.position = 'relative';
  wrapper.addEventListener('mousedown', () => {
    // capture the setter on attach button click to avoid race conditions
    appendFileAnchorToEditor(bearer, (window as any).lastFocusedEditorSetter, toastMessage);
  });
  const iconWrapper = document.createElement('div');
  iconWrapper.className = 'ck-custom-toolbar-item ck-custom-toolbar-attach';
  const icon = document.createElement('i');
  icon.className = 'icon attach';
  iconWrapper.appendChild(icon);
  wrapper.appendChild(iconWrapper);

  const tooltipWrapper = document.createElement('span');
  tooltipWrapper.className = 'ck-custom-toolbar-tooltip';
  tooltipWrapper.innerHTML = 'Insert file';
  const tooltipArrow = document.createElement('span');
  tooltipArrow.className = 'tooltip-arrow';
  tooltipWrapper.appendChild(tooltipArrow);
  wrapper.appendChild(tooltipWrapper);
  return wrapper;
};

export const injectAttachButtonInToolbars = (bearer: string, toastMessage: string) => {
  const ckToolbarItems = document.getElementsByClassName('ck-toolbar__items');
  for (let i = 0; i < ckToolbarItems.length; i++) {
    const toolbar = ckToolbarItems[i];
    // if there hasn't been an attach button injected in this toolbar
    if (toolbar.getElementsByClassName('ck-custom-toolbar-attach').length === 0) {
      toolbar.appendChild(createAttachButton(bearer, toastMessage));
    }
  }
};

export const removeMultipleNewLines = (src: string, removeAllEmptyLines: boolean = false) => {
  // Replace <\n {0 or more white spaces} \n> with a single \n
  // \s matches all whitespaces
  // the editors output HTML so we need to remove those. This whitespaces are wrapped inside a p tag.
  const resultNoConsecutiveEmptyLines = src
    // replace new line full of empty spaces
    .replace(/\n\s*\n/g, '\n')
    .replace(/<br>&nbsp;/g, '<br>')
    .replace(/(<br>)+/g, '<br>')
    // .replace(/<br><\/p><p>&nbsp;<\/p>/g, '<br></p>')
    .replace(/<br><\/p>/g, '</p>')
    .replace(/<p><br>/g, '<p>')
    // replace all groups of 2+ consecutive empty paragraphs with a single empty paragraph
    .replace(/(<p>(\s|&nbsp;|<br>)*<\/p>)+/g, '<p>&nbsp;</p>');

  if (removeAllEmptyLines) {
    const resultNoEmptyLines = resultNoConsecutiveEmptyLines
      .replace(/<p>&nbsp;<\/p>/g, '')
      .replace(/<p>(\s|&nbsp;|<br>)*<\/p>/g, '');
    return resultNoEmptyLines;
  }

  return resultNoConsecutiveEmptyLines;
};

export const appendHrefTarget = (text?: string): string => {
  if (!text) return '';
  return text.replace(/<a/gm, '<a target="_blank"');
};

export const isEmail = (text: string): boolean => {
  if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(text)) {
    return true;
  }
  return false;
};

export const scrollIdIntoView = () => {
  const urlIdSplit = window.location.href.split('#');
  if (urlIdSplit.length === 2) {
    const element = document.getElementById(urlIdSplit[1]);
    if (element) {
      element.scrollIntoView();
    }
  }
};

export const scrollLayoutToTop = () => {
  const outerContainer = document.getElementsByClassName('babele-layout-outer-container')[0];
  if (outerContainer) {
    outerContainer.scrollIntoView(true);
    outerContainer.scrollTo(0, 0);
  }
};

export const scrollIntoViewIfNeeded = (target: Element) => {
  if (target.getBoundingClientRect().bottom > window.innerHeight) {
    target.scrollIntoView(false);
  }

  if (target.getBoundingClientRect().top < 0) {
    target.scrollIntoView();
  }
};

export const getInterestLinkFromNotification = (content: string): string => {
  /*
   * Extract the most important url from the notification
   */
  // Notification structure
  // <div class='notification-title'></div>
  // <div class='notification-content'></div> -- might be missing and is hidden in the notifications UI
  // <div class='notification-datetime'></div>
  // The interest link will be in the div.notification-title
  // div.notification-content might also contain links (i.e. the content of a post) that are not related to the notification
  const inf = 10000000000;
  const titleStart = content.indexOf('notification-title');
  let contentStart = content.indexOf('notification-content');
  if (contentStart === -1) contentStart = inf;
  const dateStart = content.indexOf('notification-datetime');
  if (dateStart === -1) contentStart = inf;
  const titleEnd = Math.min(contentStart, dateStart);
  // only keep div.notification-title and discard others
  content = content.substr(titleStart, titleEnd - titleStart);

  // math urls href="https://.......
  const urlRegex = /href="https:\/\/[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/gi;

  // urls will contain all urls from the notification
  const urls = [];
  let url;
  while ((url = urlRegex.exec(content))) {
    // substr(6) to remove href=" matched by regex
    url = url[0].substr(6);
    urls.push(url);
  }

  if (content.includes('Your Project')) {
    return urls[0];
  } else if (content.includes('has edited') && content.includes('of Project')) {
    return urls[1];
  } else if (content.includes('has left a comment in')) {
    return urls[1];
  } else if (content.includes('You have been invited to become a member of')) {
    return urls[0];
  } else if (content.includes('has modified')) {
    return urls[1];
  } else if (content.includes('has uploaded a new document in the')) {
    // urls[1] for community/resources
    // urls[2] for the resource
    return urls[1];
  } else if (content.includes('has joined')) {
    return urls[1];
  } else if (content.includes('has liked your comment in')) {
    return urls[1];
  } else if (content.includes('has moved')) {
    return urls[2];
  } else if (content.includes('started following')) {
    return urls[1];
  } else if (content.includes('has posted a')) {
    // new request
    return urls[1];
  } else if (content.includes('has accepted to help you on your')) {
    return urls[1];
  } else if (content.includes('you were moved to')) {
    return urls[1];
  } else if (content.includes('has invited') && content.includes('to join')) {
    return urls[2];
  } else if (content.includes('has edited the circle')) {
    return urls[1];
  } else if (content.includes('has created')) {
    return urls[1];
  } else if (content.includes('You should have completed your task')) {
    return urls[0];
  } else if (content.includes('Today is the last day to complete your task')) {
    return urls[0];
  }
  // for finding all the types of notifications you can uncomment the next line
  // and look wait for it to print the content and identify the keywords for
  // matching that notification type
  // console.log('New notification type', notification.content, urls)

  return '';
};

// @e - the onChange event generated by the image upload
export const coverImageReader = (e: any): Promise<File> => {
  return new Promise((resolve, reject) => {
    if (e) {
      e.preventDefault();
      if (e && e.target && e.target.files.length > 0) {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);

        if (isCoverLimitExceeded(file.size)) {
          // this will help the onChange event to be triggered even if the same file is selected
          if (e.target?.value) e.target.value = null;

          reject(new Error('The image is too big!'));
        }

        reader.onload = (onLoadEvent: any) => {
          if (onLoadEvent && onLoadEvent.target && onLoadEvent.target.result) {
            const img = new Image();
            img.src = window.URL.createObjectURL(file);
            img.onload = () => {
              if (img.naturalHeight < img.naturalWidth) {
                resolve(file);
              } else {
                reject(new Error('Please choose a landscape image!'));
              }
            };
          }
        };
        reader.onerror = onErrorEvent => {
          if (onErrorEvent && onErrorEvent.target && onErrorEvent.target.error) {
            if (onErrorEvent.target.error.name === 'NotReadableError') {
              reject(new Error('Something went wrong! NotReadableError'));
            }
          }
        };
      } else {
        reject(new Error('Something went wrong! No files'));
      }
    } else {
      reject(new Error('Something went wrong! No event'));
    }
  });
};

export const ckEditorImageDefaultConfig = {
  styles: ['alignLeft', 'alignCenter', 'alignRight'],

  resizeOptions: [
    {
      name: 'resizeImage:original',
      label: 'Original',
      value: null,
      icon: 'original',
    },
    {
      name: 'resizeImage:50',
      label: '50%',
      value: '50',
      icon: 'small',
    },
    {
      name: 'resizeImage:75',
      label: '75%',
      value: '75',
      icon: 'medium',
    },
    {
      name: 'resizeImage:100',
      label: '100%',
      value: '100',
      icon: 'large',
    },
  ],
  toolbar: [
    'imageStyle:alignLeft',
    'imageStyle:alignCenter',
    'imageStyle:alignRight',
    '|',
    'resizeImage:50',
    'resizeImage:75',
    'resizeImage:100',
    'resizeImage:original',
    '|',
    'toggleImageCaption',
    'imageTextAlternative',
  ],
  upload: {
    types: ['png', 'jpeg', 'gif'],
  },
};

export const getRequestStatusLabel = (status: number): string => {
  switch (status) {
    case RequestStatusEnum.Open:
      return 'Open';

    case RequestStatusEnum.Assigned:
      return 'Assigned';

    case RequestStatusEnum.Closed:
      return 'Closed';

    default:
      return '';
  }
};

export const getSubmittedWithinOptions = () => {
  const options = [
    {
      days: 1,
      label: 'Last Day',
    },
    {
      days: 7,
      label: 'Last Week',
    },
    {
      days: 14,
      label: 'Last 2 Weeks',
    },
  ];

  return options.map(op => ({ key: op.days.toString(), value: op.days.toString(), text: op.label }));
};

export const isNoFiltering = (filtering: Filtering): boolean => {
  if (filtering.concept) return false;
  if (filtering.name) return false;
  if (filtering.tags && filtering.tags.length > 0) return false;
  if (filtering.countryId) return false;
  if (filtering.categoryId) return false;
  if (filtering.stage) return false;
  if (filtering.sustainableDevelopmentGoalsIds) return false;
  if (filtering.communityCircleId) return false;
  if (filtering.skillId) return false;
  if (filtering.interestId) return false;
  if (filtering.NumberOfDaysOld) return false;
  if (filtering.skillCategoryId) return false;
  return true;
};

export const getCookie = (cname: string): string => {
  const name = cname + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

export const gdprConsent = (category: string): boolean => {
  if (window.origin.includes('localhost')) {
    return true;
  }
  if (category === 'functional') {
    if (getCookie('cookieyes-functional') === 'yes') return true;
    else return false;
  } else if (category === 'analytics') {
    if (getCookie('cookieyes-analytics') === 'yes') return true;
    else return false;
  } else if (category === 'performance') {
    if (getCookie('cookieyes-performance') === 'yes') return true;
    else return false;
  } else if (category === 'advertisment') {
    if (getCookie('cookieyes-analytics') === 'yes') return true;
    else return false;
  } else if (category === 'other') {
    if (getCookie('cookieyes-other') === 'yes') return true;
    else return false;
  }
  return false;
};

export const featuresCookieCategories = {
  bugsnag: 'performance',
  googleAnalytics: 'analytics',
  assignmentCache: 'functional',
  literals: 'functional',
  tips: 'functional',
  account: 'functional',
  users: 'functional',
};

export const parseCsvString = (csvString: string): string[][] => {
  const csvTable = [];
  let currentRow = [];
  let currentValue = '';
  let isDoubleQuoteValue = false;
  for (let i = 0; i < csvString.length; i++) {
    const currentChar = csvString.charAt(i);
    const nextChar = csvString.charAt(i + 1);
    if (currentChar === '"' && currentValue.trim() === '' && isDoubleQuoteValue === false) {
      // beginning of a double-quote enclosed value
      currentValue = '';
      isDoubleQuoteValue = true;
    } else if (currentChar === '"' && nextChar === '"' && isDoubleQuoteValue === true) {
      // double-quote character inside a double-quote enclosed value
      // ,"b""bb", has the value ,b"bb,
      currentValue = `${currentValue}"`;
      i++; // jump over the second double-quote
    } else if (
      currentChar === '"' &&
      (nextChar === ',' ||
        nextChar === '\n' ||
        nextChar === '\r' ||
        nextChar === undefined ||
        i === csvString.length - 1) &&
      isDoubleQuoteValue === true
    ) {
      // the current double-quote enclosed value is over, we will treat the end of word on other separators
      isDoubleQuoteValue = false;
    } else if (currentChar === ',' && isDoubleQuoteValue === true) {
      // comma in double-quote enclosed value
      currentValue = `${currentValue},`;
    } else if (currentChar === '\n' && isDoubleQuoteValue === true) {
      // new line in double-quote enclosed value
      currentValue = `${currentValue}\n`;
    } else if (currentChar === ',' && isDoubleQuoteValue === false) {
      // push the current value in the row and reset the current value
      currentRow.push(currentValue);
      currentValue = '';
    } else if (currentChar === '\n' && isDoubleQuoteValue === false) {
      // end of row
      // rows end with \r\n, so we need to remove the \r from the last value in the row
      if (currentValue.endsWith('\r')) currentValue = currentValue.slice(0, currentValue.length - 1);
      currentRow.push(currentValue);
      currentValue = '';
      csvTable.push(currentRow);
      currentRow = [];
      if (nextChar === '\r') i++; // by standard, the new line is \n\r (CRLF) we want to skip the \r value
    } else {
      currentValue = `${currentValue}${currentChar}`;
    }
  }
  if (currentValue.length > 0) {
    currentRow.push(currentValue);
  }
  if (currentRow.length > 0) {
    csvTable.push(currentRow);
  }

  const noEmptyLinesTable = csvTable.filter(row => row.some((value: string) => value.length > 0));

  return noEmptyLinesTable;
};

export const getDevelopmentStateByName = (stateName: string): string | undefined => {
  const states = getDevelopmentStateList();
  for (let i = 0; i < states.length; i++) {
    for (let j = 0; j < states[i].length; j++) {
      if (states[i][j].value === stateName) return states[i][j].key;
    }
  }
  return undefined;
};

export const renameDevelopmentStageInCsv = (csvText: string, literals: Literals): string => {
  const STAGES = [
    'default_development_stage_idea',
    'default_development_stage_prototype',
    'default_development_stage_start_up',
    'default_development_stage_scaling',
    'default_development_stage_maturity',
    'default_development_stage_dry_run_only',
    'default_development_stage_start_up_venture',
    'default_development_stage_growth_phase',
  ];
  STAGES.forEach((stage: string) => {
    if (literals[stage]) {
      csvText = csvText.replaceAll(`;${stage};`, `;${literals[stage]};`);
    }
  });
  return csvText;
};

export const getCommunityMentorsPrivacyCircleName = (communityName?: string): string => {
  if (!communityName) return '_';
  return `${communityName}: mentors`;
};

export const _createReportFromJson: (kpis: KPIList[] | null | undefined) => Report[] | null = (
  kpis: KPIList[] | null | undefined,
) => {
  if (!kpis) return null;

  const filledKpis = kpis.map((kpi: KPIList, index: number) => {
    const today = new Date();
    const metricName = kpi.kpiDefinitionAndProjectName.split('-')[0].trim();
    kpi.kpiValues = fillEmptyKpis(
      kpi.kpiDefinitionStartYear,
      kpi.kpiDefinitionStartMonth,
      today.getFullYear(),
      today.getMonth(),
      kpi.frequency,
      kpi.kpiValues,
    );
    const kpiArray = kpi.kpiValues.sort((a: KPIValue, b: KPIValue) => {
      if (a.year === b.year) return a.month - b.month;
      return a.year - b.year;
    });
    const reportData = kpiArray.map((kpiValue: any) => {
      if (kpi.frequency === 3)
        return {
          name: `${getQuarterName(Number(kpiValue.month))} ${kpiValue.year}`,
          [metricName]: kpiValue.value,
        };
      else if (kpi.frequency === 12)
        return {
          name: kpiValue.year,
          [metricName]: kpiValue.value,
        };
      return {
        name: `${getMonthName(Number(kpiValue.month))}'${kpiValue.year.toString().substr(2)}`,
        [metricName]: kpiValue.value,
      };
    });

    return {
      ...kpi,
      id: index + 1,
      title: kpi.kpiDefinitionAndProjectName,
      type: StatsType.projects, // not used
      chartType: ChartTypesEnum.BAR,
      tables: [],
      data: reportData,
      disableFillMinimumData: true,
    };
  });

  return filledKpis;
};

export const handleLineHeight = (className?: string) => {
  const paragraphs = document.querySelectorAll(className || 'ck-content');
  paragraphs.forEach(paragraph => {
    (paragraph as HTMLParagraphElement).style.lineHeight = '1.5em';
  });
};

export const trimCodeFromStringGoogle = (urlWithCode: string) => {
  const startIndex = urlWithCode.indexOf('&code=') + 6;
  const endIndex = urlWithCode.indexOf('&scope=');
  if (startIndex !== -1 && endIndex !== -1) {
    return urlWithCode.substring(startIndex, endIndex);
  } else {
    return null;
  }
};

export const trimCodeFromStringOutlook = (urlWithCode: string) => {
  const startIndex = urlWithCode.indexOf('?code=') + 6;
  const endIndex = urlWithCode.indexOf('&state=');
  if (startIndex !== -1 && endIndex !== -1) {
    return urlWithCode.substring(startIndex, endIndex);
  } else {
    return null;
  }
};

export function extractMeetingInfoFromUrl(
  urlPartWithCode: string,
  urlPartProviderType: string,
): { meetingProvider: 0 | 1; code: string } | null {
  if (!urlPartProviderType.includes('/provider')) return null;
  let meetingProvider: 0 | 1 = MeetingProvider.Zoom;

  if (urlPartProviderType.includes('/provider-msteams')) {
    meetingProvider = MeetingProvider.MSTeams;
  } else if (urlPartProviderType.includes('/provider-zoom')) {
    meetingProvider = MeetingProvider.Zoom;
  }

  const match = urlPartWithCode.match(/[?&]code=([^&]+)/);
  if (!match) return null;
  const code = match[1];

  return { meetingProvider, code };
}

const fileHandler = (file: File, question: FormQuestion, bearer: string): Promise<string> => {
  const reader = new FileReader();
  reader.readAsDataURL(file);

  return new Promise((resolve, reject) => {
    reader.onload = (onLoadEvent: any) => {
      if (onLoadEvent && onLoadEvent.target && onLoadEvent.target.result) {
        const imageType =
          (question.answerType === FormQuestionAnswerType.ProjectCoverAnswer && ImageType.ProjectMainImage) ||
          (question.answerType === FormQuestionAnswerType.ProjectLogoAnswer && ImageType.ProjectLogo) ||
          undefined;
        uploadFile(bearer, { file, folder: 'resource_files' }, imageType, true)
          .then(fileUrl => {
            resolve(fileUrl.link);
          })
          .catch(err => {
            reject(err);
          });
      }
    };
  });
};

export const handleResourceUpload = (file: File, question: FormQuestion, bearer: string): Promise<string> => {
  return fileHandler(file, question, bearer);
};

export const uploadSelectedFiles = async (selectedFiles: SelectedFile[], bearer: string) => {
  try {
    const responses = await Promise.all(
      selectedFiles.map((selectedFile: SelectedFile) =>
        handleResourceUpload(selectedFile.file, selectedFile.question, bearer),
      ),
    );
    return responses;
  } catch {
    return null;
  }
};

const TEXT_ANSWER_TYPES_SET = new Set([
  FormQuestionAnswerType.CalendarAnswer,
  FormQuestionAnswerType.FileAnswer,
  FormQuestionAnswerType.LongTextAnswer,
  FormQuestionAnswerType.ShortTextAnswer,
  FormQuestionAnswerType.TableAnswer,
]);

const isSimpleAnswer = (answerType: FormQuestionAnswerType) => {
  return TEXT_ANSWER_TYPES_SET.has(answerType);
};

export const isMissingAnswers = (questions: FormQuestion[], answers: FormQuestionAnswer[]) => {
  const mandatoryQuestionIds = new Set(
    questions.filter((question: FormQuestion) => question.mandatoryAnswer).map((question: FormQuestion) => question.id),
  );
  return Array.from(mandatoryQuestionIds).some((questionId: number) => {
    const question = questions.find(q => q.id === questionId);
    const answerIds = new Set(answers.map((answer: FormQuestionAnswer) => answer.formQuestionId));
    return (
      !answerIds.has(questionId) ||
      !!answers.find(answer => {
        const isSimpleAnswerMissing = !answer.answer && question && isSimpleAnswer(question.answerType);
        const isOtherAnswerMissing = Array.isArray(answer.answer) && answer.answer.length === 0;
        return answer.formQuestionId === questionId && (isSimpleAnswerMissing || isOtherAnswerMissing);
      })
    );
  });
};

export const getFirstMissingAnswer = (questions: FormQuestion[], answers: FormQuestionAnswer[]) => {
  const mandatoryQuestionIds = new Set(
    questions
      .filter((question: FormQuestion) => question.mandatoryAnswer)
      .sort((a: FormQuestion, b: FormQuestion) => a.questionOrder - b.questionOrder)
      .map((question: FormQuestion) => question.id),
  );
  return Array.from(mandatoryQuestionIds).find((questionId: number) => {
    const question = questions.find(q => q.id === questionId);
    const answerIds = new Set(answers.map((answer: FormQuestionAnswer) => answer.formQuestionId));
    return (
      !answerIds.has(questionId) ||
      !!answers.find(answer => {
        const isSimpleAnswerMissing = !answer.answer && question && isSimpleAnswer(question.answerType);
        const isOtherAnswerMissing = Array.isArray(answer.answer) && answer.answer.length === 0;
        return answer.formQuestionId === questionId && (isSimpleAnswerMissing || isOtherAnswerMissing);
      })
    );
  });
};

export const isMissingTableAnswer = (questionType: FormQuestionAnswerType, answers: FormQuestionAnswer[]) => {
  return questionType === FormQuestionAnswerType.TableAnswer && answers.some(ans => !ans.answer);
};

export const isMissingAnswer = (
  question: FormQuestion,
  answers: { [formQuestionId: number]: FormQuestionAnswer[] },
) => {
  const answerList = answers[question.id] || [];
  const firstAnswer = answerList[0] || {};
  return (
    question.mandatoryAnswer &&
    (answerList.length === 0 ||
      isMissingTableAnswer(question.answerType, answerList) ||
      (isSimpleAnswer(question.answerType) && !firstAnswer.answer))
  );
};

export const getLanguageOptions = (literals: Literals) => {
  return Object.keys(literals)
    .filter((literal: string) => /[a-z]{2}_[A-Z]{2}_language/.test(literal))
    .map((literal: string) => ({
      key: literal.substr(0, 5).replace('_', '-'),
      value: literal.substr(0, 5).replace('_', '-'),
      text: literals[literal],
    }));
};
export function customSortArrayOfObjects(arr: any[], sortingKey: string, specificPropertyValue?: string | number) {
  // Sort the array of objects based on a specific property
  arr.sort(function (a, b) {
    const categoryA = a[sortingKey];
    const categoryB = b[sortingKey];

    // Move the specific property value to the front
    if (specificPropertyValue) {
      if (categoryA === specificPropertyValue) {
        return -1;
      } else if (categoryB === specificPropertyValue) {
        return 1;
      }
    }

    // Default sorting order (ascending)
    return categoryA.localeCompare(categoryB);
  });

  return arr;
}

export const isCoverLimitExceeded = (size: number) => {
  return size > COVER_LOGO_MAX_FILE_SIZE;
};

export const isFileSizeLimitExceeded = (size: number) => {
  return size > MAX_FILE_SIZE;
};

export const formatDate = (date: string, userLanguage: Languages) => {
  return new Date(date).toLocaleDateString(userLanguage, dateFormatOptions);
};

export function replaceLastLink(str: string) {
  // Regular expression to match URLs
  const urlRegex = /(https?:\/\/[^\s<]+)/g;

  // Replace URLs with anchor tags
  return str.replace(urlRegex, function (url) {
    url = url.trim();
    if (url.endsWith('.') || url.endsWith(',') || url.endsWith(';')) {
      url = url.slice(0, -1);
    }
    url = url.replace(/<br>/g, '');
    return '<a href="' + url + '">' + url + '</a>';
  });
}

// function to check if any key is null inside the object
export const hasNullKey = (obj: any) => {
  return Object.keys(obj).some(key => obj[key] === null);
};

export const sortCountriesFilter = (filter: any) => {
  const filterCopy = { ...filter, countries: filter?.countries ? [...filter.countries] : [] };
  const filterObj = filterCopy.countries;
  if (filterObj) filterCopy.countries = filterObj.sort((a: Country, b: Country) => a.name.localeCompare(b.name));
  return filterCopy;
};

export const getAllUniqueUsers = (comments: Comment[], uniqueUsers: Author[] = []) => {
  for (const comment of comments) {
    if (!uniqueUsers.some(user => user.id === comment.author.id)) uniqueUsers.push(comment.author);
    if (comment.comments && comment.comments.length > 0) {
      getAllUniqueUsers(comment.comments, uniqueUsers);
    }
  }
  return uniqueUsers;
};

export const useModal = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState<any>(null);

  const openModal = (content: any) => {
    setModalContent(content);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalContent(null);
  };

  return {
    isModalOpen,
    openModal,
    closeModal,
    modalContent,
  };
};
