import React, { useState, useMemo, useEffect } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'StoreModel';
import styled from 'styled-components';
import { Form, FormQuestion, FormQuestionAnswer } from 'redux/types/account';
import QuestionView from './view-question';
import { StyledButton } from 'primitives/Button/style';
import { Link, useHistory, useParams } from 'react-router-dom';
import { SecondaryButton } from 'components/styled-components/common';
import { isMissingAnswer } from 'util/utils';
import InnerHtmlWrapper from 'components/editor/InnerHtmlWrapper';

const ApplicationContainer = styled.div`
  flex: 1;
  width: 100%;
  @media (max-width: ${({ theme }) => theme.sizes.mobileWidth}) {
    align-self: center;
    padding: 0em 0.5em;
  }
`;

const Container = styled.form`
  display: flex;
  flex-direction: row;
  width: 100%;
  @media (max-width: ${({ theme }) => theme.sizes.mobileWidth}) {
    flex-direction: column;
    gap: 0em;
  }
`;

const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 15em;
  height: 100%;
  position: sticky;
  top: 0;

  @media (max-width: ${({ theme }) => theme.sizes.mobileWidth}) {
    max-width: 45em;
    align-self: center;
    padding: 0.5em;
    padding-bottom: 2em;
  }
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 0.9em;
  text-align: left;
  gap: 2rem;
`;

const Row = styled.div`
  display: flex;
  width: 100%;
`;

const Title = styled.span`
  font-size: 1.3em;
  font-weight: bold;
`;

const Description = styled.span`
  font-size: 1em;
`;

const ActionRow = styled.div`
  display: flex;
  align-items: flex-start;
  margin: 0 auto 2em;
  position: sticky;
  height: 100%;
  gap: 1em;
`;

const mapStateToProps = (state: RootState) => ({
  literals: state.literals,
});

interface Props extends ReturnType<typeof mapStateToProps> {
  form: Form;
  setAnswers?: (answers: FormQuestionAnswer[]) => void;
  defaultAnswers?: FormQuestionAnswer[];
  hideDetails?: boolean;
  viewMode?: boolean;
  style?: React.CSSProperties;
  handleSelectedFile?: (
    question: FormQuestion,
    answer: FormQuestionAnswer[],
    file: File,
    cb: (file: File) => Promise<string>,
  ) => void;
  titleStyles?: React.CSSProperties;
  isFilesUploading?: boolean;
  isFormSubmitting?: boolean;
  isPreview?: boolean;
}

const FormView: React.FC<Props> = props => {
  const {
    form,
    hideDetails,
    viewMode,
    handleSelectedFile,
    literals,
    titleStyles,
    setAnswers: onSetAnswer = () => {},
    isFilesUploading,
    isFormSubmitting,
    isPreview,
    ...rest
  } = props;
  const { communityId } = useParams<{ communityId: string }>();
  const history = useHistory();
  const [answers, setAnswers] = useState<{ [formQuestionId: number]: FormQuestionAnswer[] }>(
    (props.defaultAnswers || []).reduce(
      (acc: { [formQuestionId: number]: FormQuestionAnswer[] }, val: FormQuestionAnswer) => ({
        ...acc,
        [val.formQuestionId]: [...(acc[val.formQuestionId] || []), val],
      }),
      {},
    ),
  );
  const orderedQuestions = useMemo(() => {
    // Use the sort method to order the questions by the "questionOrder" property
    return [...(form?.questions || [])].sort((a, b) => a.questionOrder - b.questionOrder);
  }, [form?.questions]);
  useEffect(() => {
    const answersArray = Object.keys(answers).reduce(
      (acc: FormQuestionAnswer[], val: string) => [...acc, ...answers[Number(val)]],
      [],
    );
    onSetAnswer(answersArray);
  }, [answers]);

  useEffect(() => {
    if (props?.defaultAnswers && props?.defaultAnswers?.length > 0)
      setAnswers(
        (props.defaultAnswers || []).reduce(
          (acc: { [formQuestionId: number]: FormQuestionAnswer[] }, val: FormQuestionAnswer) => ({
            ...acc,
            [val.formQuestionId]: [...(acc[val.formQuestionId] || []), val],
          }),
          {},
        ),
      );
  }, [props.defaultAnswers?.length]);

  return (
    <Container>
      <ApplicationContainer>
        <FormContainer className="ck-content" style={{ ...props.style }}>
          {!hideDetails && (
            <React.Fragment>
              <Row>
                <Title>{form.name}</Title>
              </Row>
              <Row>
                <InnerHtmlWrapper style={{ textAlign: 'left' }} html={form.description} />
              </Row>
            </React.Fragment>
          )}
          {orderedQuestions.map((question: FormQuestion, index: number) => (
            <QuestionView
              titleStyles={titleStyles}
              data-cy={`answer-question-${index}`}
              answer={answers[question.id] || []}
              setAnswer={(answer: FormQuestionAnswer[]) => {
                setAnswers({
                  ...answers,
                  [question.id]: answer,
                });
              }}
              question={question}
              key={question.id ?? index}
              viewMode={viewMode}
              handleSelectedFile={handleSelectedFile}
              isMissing={!!isFormSubmitting && isMissingAnswer(question, answers)}
              isFilesUploading={!!isFilesUploading}
              {...rest}
            />
          ))}
        </FormContainer>
      </ApplicationContainer>
      {window.location.pathname.includes('/admin-panel/form/') && !isPreview && (
        <Sidebar>
          <ActionRow>
            <StyledButton
              style={{ flex: 1 }}
              as={Link}
              to={`/community/${communityId}/admin-panel/form/${form.id}/edit`}
            >
              {literals.community_about_edit_button}
            </StyledButton>

            <SecondaryButton type="button" onClick={() => history.goBack()} style={{ flex: '1' }}>
              {literals.global_exit}
            </SecondaryButton>
          </ActionRow>
        </Sidebar>
      )}
    </Container>
  );
};

export default connect(mapStateToProps, {})(FormView);
