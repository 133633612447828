import { LayoutType } from 'redux/types/account';

export default [
  {
    path: '/community/:communityId',
    exact: true,
    strict: false,
    layout: '3-columns-cover',
  },
  {
    path: '/community/:communityId/about',
    exact: true,
    strict: false,
    layout: '3-columns',
  },
  {
    path: '/community/:communityId/tab/:tabId',
    exact: true,
    strict: false,
    layout: '2-columns',
  },
  {
    path: '/community/:communityId/tab/:tabId/entity/new',
    exact: true,
    strict: false,
    layout: '1-column',
  },
  {
    path: '/community/:communityId/discussion/:discussionId',
    exact: true,
    strict: false,
    layout: '2-columns',
  },
  {
    path: '/community/:communityId/admin-panel/methodology/editor',
    exact: false,
    strict: false,
    layout: 'full-screen',
  },
  {
    path: '/community/:communityId/admin-panel/methodology/new',
    exact: false,
    strict: false,
    layout: 'full-screen',
  },
  {
    path: '/community/:communityId/admin-panel/application-definitions/new',
    exact: false,
    strict: false,
    layout: 'full-screen',
  },
  {
    path: '/community/:communityId/application-definition/:applicationDefinitionId/evaluate',
    exact: false,
    strict: false,
    layout: 'full-screen',
  },
  {
    path: '/community/:communityId/admin-panel/application-definition/:applicationDefinitionId/',
    exact: false,
    strict: false,
    layout: 'settings',
  },
  {
    path: '/community/:communityId/admin-panel/forms/new',
    exact: false,
    strict: false,
    layout: 'full-screen',
  },
  {
    path: '/community/:communityId/admin-panel/methodology/planner',
    exact: false,
    strict: false,
    layout: 'full-screen',
  },
  {
    path: '/community/:communityId/admin-panel',
    exact: false,
    strict: false,
    layout: 'settings',
  },
  {
    path: '/community/:communityId/project/new',
    exact: true,
    strict: false,
    layout: '1-column',
  },
  {
    path: '/community/:communityId',
    exact: false,
    strict: false,
    layout: '2-columns',
  },
  {
    path: '/application-definition/:applicationDefinitionId/new-application',
    exact: false,
    strict: false,
    layout: '1-column',
  },
  {
    path: '/project/:projectId',
    exact: true,
    strict: false,
    layout: '3-columns',
  },
  {
    path: '/project/:projectId/discussion/:discussionId',
    exact: true,
    strict: false,
    layout: '2-columns',
  },
  {
    path: '/project/:projectId/request/:requestId',
    exact: true,
    strict: false,
    layout: '2-columns',
  },
  {
    path: '/project/:projectId',
    exact: false,
    strict: false,
    layout: '2-columns',
  },
  {
    path: '/user/:userId',
    exact: false,
    strict: false,
    layout: '1-column',
  },
  {
    path: '/super-admin/',
    exact: false,
    strict: false,
    layout: 'super-admin',
  },
  {
    path: '/account',
    exact: false,
    strict: false,
    layout: '1-column',
  },
  {
    path: '/edit-profile',
    exact: false,
    strict: false,
    layout: '1-column',
  },
  {
    path: '/',
    exact: false,
    strict: false,
    layout: 'full-screen',
  },
] as { path: string; exact: boolean; strict: boolean; layout: LayoutType }[];
