import { Form, FormQuestion } from 'redux/types/account';
import { getAxiosInstance } from './helper';
import { AxiosResponse } from 'axios';

const ai = getAxiosInstance();

export const _mapResponseToForm = (form: any): Form => {
  return {
    id: form.id,
    name: form.name || '',
    description: form.description || '',
    communityId: form.communityId,
    creationDate: form.creationDate || '',
    lastEditionDate: form.lastEditionDate || '',
    userCreatorId: form.userCreatorId,
    questions: form.questions
      .sort((a: any, b: any) => a.questionOrder - b.questionOrder)
      .map(
        (question: any): FormQuestion => {
          return {
            id: question.id,
            title: question.title || '',
            question: question.question || question.title || '',
            description: question.description || '',
            questionOrder: question.questionOrder || 0,
            mandatoryAnswer: question.mandatoryAnswer,
            privacyLevel: question.privacyLevel,
            privacyCirclesId: question.privacyCirclesId || [],
            isFilterable: question.isFilterable || false,
            answerType: question.answerType,
            answerSubtype: '',
            allowMultipleAnswers: question.allowMultipleAnswers,
            questionOptions: question.questionOptions.sort(
              (a: any, b: any) => a.questionOptionOrder - b.questionOptionOrder,
            ),
          };
        },
      ),
  };
};

export function saveForm(
  bearer: string,
  communityId: number,
  form: Form,
): Promise<{ communityId: number; form: Form }> {
  const formCopy = { ...form };
  // this is a fix for existing data in the db
  // where the last 2 question options were having same questionOptionOrder
  formCopy.questions.forEach(question => {
    if (question.questionOptions && question.questionOptions.length > 1) {
      const lastOption = question.questionOptions[question.questionOptions.length - 1];
      const secondLastOption = question.questionOptions[question.questionOptions.length - 2];

      if (lastOption.questionOptionOrder === secondLastOption.questionOptionOrder) {
        lastOption.questionOptionOrder++;
      }
    }
  });
  return new Promise((resolve, reject) => {
    ai({
      method: form.id ? 'PUT' : 'POST',
      url: '/api/ApplicationForm' + (form.id ? `/${form.id}` : ''),
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      data: {
        ...formCopy,
      },
    })
      .then((response: AxiosResponse) => {
        resolve({
          communityId,
          form: _mapResponseToForm(response.data),
        });
      })
      .catch(err => reject(err));
  });
}

export function fetchCommunityForms(
  bearer: string,
  communityId: number,
): Promise<{ communityId: number; forms: Form[] }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: `/api/Community/${communityId}/Forms`,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        resolve({
          communityId,
          forms: response.data.list
            //             .filter((form: Form) => form.communityId === communityId)
            .map(_mapResponseToForm),
        });
      })
      .catch(err => reject(err));
  });
}

export function deleteForm(
  bearer: string,
  communityId: number,
  formId: number,
): Promise<{ communityId: number; formId: number }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'DELETE',
      url: `/api/ApplicationForm/${formId}`,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then(() => {
        resolve({
          communityId,
          formId,
        });
      })
      .catch(err => reject(err));
  });
}

export function fetchFormById(
  bearer: string,
  communityId: number,
  formId: number,
): Promise<{ communityId: number; form: Form }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: `/api/ApplicationForm/${formId}`,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        resolve({
          communityId,
          form: _mapResponseToForm(response.data),
        });
      })
      .catch(err => reject(err));
  });
}

export default {
  saveForm,
  fetchCommunityForms,
  deleteForm,
  fetchFormById,
};
