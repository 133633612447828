import React, { ReactNode, UIEvent, useEffect, useRef } from 'react';
import { matchPath, Redirect, Route, Switch, useHistory, useLocation, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { RootState } from 'StoreModel';
import { Dimmer, Loader } from 'semantic-ui-react';
import { isEmpty } from 'ramda';
import { LayoutType, MyStuffItemRequests, User } from 'redux/types/account';
import UserProfile from './user-profile';
import Account from './account';
import InvitationResponse from '../components/notifications/invitation-response';
import RequestInvitationResponse from '../components/notifications/request-invitation-response';
import { fetchCommunitiesAsync } from '../redux/actions/community';
import 'rsuite/dist/styles/rsuite-default.css';
import {
  getLiteralsAsync,
  clearHasPendingRequestsAsync,
  fetchCurrentUserAsync,
  fetchNotificationsAsync,
} from '../redux/actions/account';
import Modal from '../components/common/modal-component';
import { Flag } from '../redux/types/enums';
import { saveToken } from '../redux/actions/session';
import { Separator, SuspenseFallback } from '../components/styled-components/common';
import CompleteProfileModal from '../components/account/complete-profile-modal';
import { AppContainer, PreviewContainer, PreviewNavigation } from '../layout/common';
import IndexPage from './index';
import Layout from '../layout';
import LAYOUTS from './layout-definitions';
import { XButton } from 'components/common/modal-navigation';
import useWindowDimensions from 'util/windowSize';
import { scrollLayoutToTop } from 'util/utils';
import RequestsPendingFeedbackPopup from 'components/requests/RequestsPendingFeedbackPopup';
import HackHistory from './history';

const Login = React.lazy(() => import('./auth/login'));
const Signup = React.lazy(() => import('./auth/signup'));
const Activation = React.lazy(() => import('./auth/activation'));
const ForgotPassword = React.lazy(() => import('./auth/forgot-password'));
const ResetPassword = React.lazy(() => import('./auth/reset-password'));
const ReacceptTerms = React.lazy(() => import('./auth/reaccept-terms-and-conditions'));
const NotFound = React.lazy(() => import('./404/NotFound'));
const EditProfile = React.lazy(() => import('./account/edit-profile'));
const CommunityComponent = React.lazy(() => import('./community'));
const Project = React.lazy(() => import('./project'));
const SuperAdminPanel = React.lazy(() => import('./super-admin'));
const ApplicationRouter = React.lazy(() => import('./application'));
const CalendarRedirect = React.lazy(() => import('./redirects/calendar-redirect'));

const mapPreviewToProps = (state: RootState) => ({
  account: state.account.details.user,
  bearer: state.account.session.session.bearer,
});

interface PreviewProps extends ReturnType<typeof mapPreviewToProps> {}

const _PreviewRoute: React.FC<PreviewProps> = props => {
  const history = useHistory();
  const location = useLocation();
  const previewUrl = (location.state as any)?.pagePreview || '';
  const windowSize = useWindowDimensions();
  if (previewUrl === '') return null;
  if (windowSize.isMobile) {
    scrollLayoutToTop();
    history.replace({
      ...location,
      state: {},
    });
    history.push(previewUrl);
    return null;
  }
  return (
    <PreviewContainer key={previewUrl}>
      <PreviewNavigation>
        <XButton
          style={{ zIndex: 10, padding: '0' }}
          onClick={() =>
            history.replace({
              ...location,
              state: {},
            })
          }
        />
        <Separator
          style={{ position: 'absolute', top: '4rem', left: '-1em', width: 'calc(100% + 1em)', marginTop: '0em' }}
        />
      </PreviewNavigation>
      <div style={{ width: '100%', position: 'relative' }}>
        <Switch location={{ ...location, pathname: previewUrl }}>
          <ProtectedRoute account={props.account} bearer={props.bearer} path="/project/:projectId">
            <Project />
          </ProtectedRoute>
          <ProtectedRoute account={props.account} bearer={props.bearer} path="/community/:communityId">
            <CommunityComponent />
          </ProtectedRoute>
        </Switch>
      </div>
    </PreviewContainer>
  );
};
const PreviewRoute = connect(mapPreviewToProps, {})(_PreviewRoute);

const mapDispatchToProps = {
  fetchCommunities: fetchCommunitiesAsync.request,
  fetchCurrentUserData: fetchCurrentUserAsync.request,
  resetFetchingUserFlag: fetchCurrentUserAsync.cancel,
  fetchNotifications: fetchNotificationsAsync.request,
  clearHasPendingRequests: clearHasPendingRequestsAsync.request,
  getLiterals: getLiteralsAsync.request,
  saveToken: saveToken,
};

const mapStateToProps = (state: RootState) => ({
  bearer: state.account.session.session.bearer,
  isFetchingCommunities: state.loading.fetchCommunitiesFlag,
  isFetchingUser: state.loading.fetchCurrentUserFlag,
  account: state.account.details.user,
  communities: state.account.communities.list,
  literals: state.literals,
});

type dispatchType = typeof mapDispatchToProps;
interface Props extends ReturnType<typeof mapStateToProps>, dispatchType {}

const Navigator: React.FC<Props> = props => {
  const {
    fetchCommunities,
    fetchCurrentUserData,
    fetchNotifications,
    bearer,
    account,
    isFetchingCommunities,
    isFetchingUser,
    communities,
    saveToken,
    literals,
    resetFetchingUserFlag,
    clearHasPendingRequests,
    getLiterals,
  } = props;
  const isFetching = isFetchingCommunities === Flag.Request || isFetchingUser === Flag.Request;
  const location = useLocation();
  const history = useHistory();
  // const windowSize = useWindowDimensions();
  const appContainerRef = useRef<HTMLDivElement>(null);
  let interval: any = null;
  const backgroundModal = location.state && (location.state as any).backgroundModal;
  const backgroundModalStyle = (location.state && (location.state as any).backgroundModalStyle) || {};
  const backgroundModalContentStyle = (location.state && (location.state as any).backgroundModalContentStyle) || {};

  useEffect(() => {
    if (bearer) {
      fetchCurrentUserData(bearer);
      fetchCommunities(bearer);
      scheduleNotificationsFetch();
      return function cleanUp() {
        clearInterval(interval);
      };
    }
  }, [bearer]);

  // !HACK make histoy globally available to used in EpicFuncitons
  useEffect(() => {
    HackHistory().set(history);
  }, [history]);

  useEffect(() => {
    (window as any).navigateToReacceptTerms = () => {
      history.push('/reaccept-terms-and-conditions', { afterAuth: location });
    };
    if (isFetchingUser === Flag.Failure) {
      resetFetchingUserFlag(null);
    }
  }, [isFetchingUser]);

  useEffect(() => {
    const useQuery = (): URLSearchParams => new URLSearchParams(location.search);
    const query = useQuery();
    const token = query.get('token');
    if (token) {
      saveToken({
        token,
      });
    }
  }, []);

  // linked to getIndexRedirect
  const getRedirectPathname = () => {
    const newApplicationRedirect = JSON.parse(
      localStorage.getItem('new-application-redirect') ||
        JSON.stringify({ afterRegister: '', expirationTime: new Date().getTime() - 10 }),
    );
    if (isFetching || !account.primaryCommunityId || !communities || !account?.ownedProjects) return '/account';
    else if (newApplicationRedirect.expirationTime > new Date().getTime()) {
      localStorage.removeItem('new-application-redirect');
      return newApplicationRedirect.afterRegister;
    } else if (communities.find((c: any) => c.id === account?.primaryCommunityId))
      return `/community/${account?.primaryCommunityId}`;
    else if (communities.length > 0) return `/community/${communities[0]}`;
    else if (account?.ownedProjects.length > 0) return `/project/${account?.ownedProjects[0].id}`;
    else return `/account`;
  };

  useEffect(() => {
    if (isEmpty(account)) {
      return;
    }
    if (account && account.id) {
      if (!account.countryId || account.countryId === 0) {
        return;
      }
    }
    if (account?.pendingRequestIds && account?.pendingRequestIds.length > 0) {
      const requestId = account.pendingRequestIds[0];
      const request = account.requests.find((r: MyStuffItemRequests) => r.id === requestId);
      clearHasPendingRequests({ bearer });
      history.push(`/project/${request.projectId}/request/${request.id}`);
    }
  }, [account, account?.countryId]);

  useEffect(() => {
    if (!account?.language) return;
    getLiterals({
      bearer,
      language: account.language,
    });
  }, [account?.language]);

  const scheduleNotificationsFetch = () => {
    interval = setInterval(() => {
      fetchNotifications({ bearer, take: 10, skip: 0 });
    }, 60 * 1000);
  };

  // complete profile wizard modal check
  if (
    history.location.pathname !== '/edit-profile' &&
    !history.location.pathname.includes('/application-definition') &&
    !history.location.pathname.includes('/account/mystuff') &&
    account &&
    account.id &&
    !account.countryId &&
    isFetchingUser === Flag.Success &&
    bearer
  ) {
    history.push('/edit-profile', {
      backgroundModal: {
        ...location,
        pathname: getRedirectPathname(),
      },
      backgroundModalStyle: { maxWidth: '22em', padding: '0em' },
      backgroundModalContentStyle: { padding: '0.5em 1em 2em 1em' },
    });
  }

  if (isFetching && !bearer) {
    return (
      <Dimmer active={true} inverted={true}>
        <Loader inverted={true}>{literals.project_list_loading_tag_message}</Loader>
      </Dimmer>
    );
  }

  const _getLayoutType = (): LayoutType => {
    const pathname = backgroundModal?.pathname || location.pathname;
    const route = LAYOUTS.find(route => {
      const match = matchPath(pathname, route.path);
      return match && (!route.exact || match.isExact);
    });
    return route?.layout ?? 'full-screen';
  };

  const handleAppContainerScroll = (event?: React.UIEvent) => {
    const scrollElement = event?.target as HTMLElement;
    if (!scrollElement) return;

    if (!(window as any).onAppContainerScroll) return;
    const scrollTop = scrollElement.scrollTop;
    const clientHeight = scrollElement.clientHeight;
    const scrollHeight = scrollElement.scrollHeight;
    const unscrolled = scrollHeight - clientHeight - scrollTop;

    (window as any).onAppContainerScroll({
      px: unscrolled,
      relativePercent: (unscrolled / clientHeight) * 100,
    });
  };

  (window as any).handleAppContainerScroll = handleAppContainerScroll;

  useEffect(() => {
    handleAppContainerScroll();
  }, [appContainerRef.current]);

  return (
    <AppContainer ref={appContainerRef} onScroll={handleAppContainerScroll}>
      <React.Suspense fallback={SuspenseFallback}>
        <Route path={LAYOUTS.map(layout => layout.path)}>
          <Layout
            type={_getLayoutType()}
            showPreviewComponent={(location.state as any)?.pagePreview}
            previewComponent={<PreviewRoute />}
          >
            <React.Fragment>
              <Switch location={backgroundModal || location}>
                {!backgroundModal && (
                  <ProtectedRoute account={account} bearer={props.bearer} exact path="/">
                    <IndexPage />
                  </ProtectedRoute>
                )}
                {!backgroundModal && (
                  <ProtectedRoute account={account} bearer={props.bearer} exact path="/index-redirect">
                    <IndexPage />
                  </ProtectedRoute>
                )}

                <ProtectedRoute account={account} bearer={props.bearer} path="/project/:entityId/accept-invitation">
                  <InvitationResponse bearer={props.bearer} entityType={'project'} responseType={'accept'} fullScreen />
                </ProtectedRoute>
                <ProtectedRoute account={account} bearer={props.bearer} path="/project/:entityId/decline-invitation">
                  <InvitationResponse
                    bearer={props.bearer}
                    entityType={'project'}
                    responseType={'decline'}
                    fullScreen
                  />
                </ProtectedRoute>
                <ProtectedRoute account={account} bearer={props.bearer} path="/community/:entityId/accept-invitation">
                  <InvitationResponse
                    bearer={props.bearer}
                    entityType={'community'}
                    responseType={'accept'}
                    fullScreen
                  />
                </ProtectedRoute>
                <ProtectedRoute account={account} bearer={props.bearer} path="/community/:entityId/decline-invitation">
                  <InvitationResponse
                    bearer={props.bearer}
                    entityType={'community'}
                    responseType={'decline'}
                    fullScreen
                  />
                </ProtectedRoute>
                <Route path="/application-definition/:applicationDefinitionId">
                  <ApplicationRouter />
                </Route>
                <ProtectedRoute account={account} bearer={props.bearer} path="/request/accept-invitation">
                  <RequestInvitationResponse bearer={props.bearer} responseType={'accept'} fullScreen />
                </ProtectedRoute>
                <ProtectedRoute account={account} bearer={props.bearer} path="/request/decline-invitation">
                  <RequestInvitationResponse bearer={props.bearer} responseType={'decline'} fullScreen />
                </ProtectedRoute>
                <ProtectedRoute account={account} bearer={props.bearer} path="/account/:tab?/:options?">
                  <Account />
                </ProtectedRoute>
                <Route path="/calendar-redirect">
                  <CalendarRedirect />
                </Route>
                <ProtectedRoute account={account} bearer={props.bearer} path="/edit-profile">
                  <EditProfile key={history.location.key} />
                </ProtectedRoute>
                <AuthRoute bearer={props.bearer} path="/login">
                  <Login />
                </AuthRoute>
                <AuthRoute bearer={props.bearer} path="/forgot-password">
                  <ForgotPassword />
                </AuthRoute>
                <Route path="/reset-password/:code">
                  <ResetPassword />
                </Route>
                <Route path="/activate/:activateAccountCode/:code">
                  <ResetPassword />
                </Route>
                <AuthRoute bearer={props.bearer} path="/signup">
                  <Signup />
                </AuthRoute>
                <Route path="/activate/:code">
                  <Activation />
                </Route>
                <Route path="/reaccept-terms-and-conditions">
                  <ReacceptTerms />
                </Route>
                <ProtectedRoute account={account} bearer={props.bearer} path="/community/:communityId">
                  <CommunityComponent />
                </ProtectedRoute>
                <ProtectedRoute account={account} bearer={props.bearer} path="/super-admin">
                  <SuperAdminPanel />
                </ProtectedRoute>
                <ProtectedRoute account={account} bearer={props.bearer} path="/user/:userId">
                  <UserProfile bearer={props.bearer} />
                </ProtectedRoute>
                <ProtectedRoute account={account} bearer={props.bearer} path="/project/:projectId">
                  <Project />
                </ProtectedRoute>
                <Route path="*">
                  <NotFound />
                </Route>
              </Switch>
              {backgroundModal && (
                <Modal
                  title=""
                  noTitle
                  open
                  style={{ maxWidth: '40em', ...backgroundModalStyle }}
                  contentStyle={{ ...backgroundModalContentStyle }}
                >
                  <Switch>
                    <Route path="/project/:entityId/accept-invitation">
                      <InvitationResponse bearer={props.bearer} entityType={'project'} responseType={'accept'} />
                    </Route>
                    <Route path="/project/:entityId/decline-invitation">
                      <InvitationResponse bearer={props.bearer} entityType={'project'} responseType={'decline'} />
                    </Route>
                    <Route path="/community/:entityId/accept-invitation">
                      <InvitationResponse bearer={props.bearer} entityType={'community'} responseType={'accept'} />
                    </Route>
                    <Route path="/community/:entityId/decline-invitation">
                      <InvitationResponse bearer={props.bearer} entityType={'community'} responseType={'decline'} />
                    </Route>
                    <ProtectedRoute
                      account={account}
                      bearer={props.bearer}
                      path="/tab-entity/:entityId/accept-invitation"
                    >
                      <InvitationResponse bearer={props.bearer} entityType={'tabEntity'} responseType={'accept'} />
                    </ProtectedRoute>
                    <ProtectedRoute
                      account={account}
                      bearer={props.bearer}
                      path="/tab-entity/:entityId/decline-invitation"
                    >
                      <InvitationResponse bearer={props.bearer} entityType={'tabEntity'} responseType={'decline'} />
                    </ProtectedRoute>
                    <Route path="/request/accept-invitation">
                      <RequestInvitationResponse bearer={props.bearer} responseType={'accept'} />
                    </Route>
                    <Route path="/request/decline-invitation">
                      <RequestInvitationResponse bearer={props.bearer} responseType={'decline'} />
                    </Route>
                    <ProtectedRoute account={account} exact={true} bearer={props.bearer} path="/edit-profile">
                      <CompleteProfileModal />
                    </ProtectedRoute>
                  </Switch>
                </Modal>
              )}
            </React.Fragment>
          </Layout>
        </Route>
      </React.Suspense>
      {/** GLOBAL:: MODALS GO HERE */}
      <RequestsPendingFeedbackPopup />
    </AppContainer>
  );
};

interface IRoute {
  exact?: boolean;
  path: string;
  bearer: string;
  account: User;
  children: ReactNode;
}

const ProtectedRoute = ({ bearer, children, account, ...rest }: IRoute) => {
  const location = useLocation();
  const history = useHistory();
  const { applicationDefinitionId } = useParams<{ applicationDefinitionId: string }>();

  useEffect(() => {
    if (!bearer) {
      if (location.pathname.startsWith('/application-definition/') && location.pathname.endsWith('/new-application')) {
        // expiration time is 1 hour
        const expirationTime = new Date().getTime() + 1000 * 60 * 60 * 1;
        localStorage.setItem(
          'new-application-redirect',
          JSON.stringify({
            afterRegister: location.pathname,
            expirationTime: expirationTime,
          }),
        );
        history.push('/login', {
          afterAuth: location,
          appId: applicationDefinitionId,
        });
      } else {
        history.push('/login', { afterAuth: location });
      }
    } else if (!account.admin && location.pathname.startsWith('/super-admin')) {
      history.goBack();
    }
  }, [bearer]);

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (bearer) return children;
        else
          return (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: location },
              }}
            />
          );
      }}
    />
  );
};

type IAuthRoute = {
  exact?: boolean;
  path: string;
  bearer: string | undefined;
  children: ReactNode;
};
const AuthRoute = ({ children, bearer, ...rest }: IAuthRoute) => {
  const location = useLocation();
  const { afterAuth } = (location.state ?? {}) as any;

  return <Route {...rest} render={() => (bearer ? <Redirect to={afterAuth || { pathname: '/' }} /> : children)} />;
};

export default connect(mapStateToProps, mapDispatchToProps)(Navigator);
export { ProtectedRoute };
